import { Image, Center, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useColorMode } from '@chakra-ui/color-mode';
import { i_h2, i_text_copy } from '../../../style';
import placeholder from '../../../assets/placeholder.png';

const NotConnectModal: React.FC<unknown> = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    return (
        <Center w="100%" h="100%" alignItems="center">
            <Stack alignItems="center" mt="-100px">
                <Image
                    w="250px"
                    h="210px"
                    src={process.env.PUBLIC_URL + colorTheme('/assets/trade/lightNotConnect.png', '/assets/trade/darkNotConnect.png')}
                    fallbackSrc={placeholder}
                />
                <Text className={i_h2} color={colorTheme('tertiary.700', 'tertiary.50')}>
                    Not Connect to Wallet
                </Text>

                <Text className={i_text_copy} color={colorTheme('tertiary.300', 'tertiary.300')}>
                    Connect to wallet to get start.
                </Text>
            </Stack>
        </Center>
    );
};

export default NotConnectModal;

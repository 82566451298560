import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Switch,
    useColorMode,
} from '@chakra-ui/react';
import { i_text_copy, i_text_copy_bold } from '../../../../../style';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { useSelector } from 'react-redux';
import { RootState, RootDispatch } from '../../../../../state/store';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { useCallback } from 'react';
//import { getColorThemeSelector } from '../../../../../utils/funcs';
//import { useColorMode } from '@chakra-ui/color-mode';
import { ListSortByEnum } from '../../../../../types/mod';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { getColorThemeSelector } from '../../../../../iZUMi-UI-toolkit/src/utils/funcs';
import { Filter } from '../../../../Farm/Gallery/components/Filter';

const MyLimitOrderControls: React.FC = () => {
    //const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const { tradeLimitOrder } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const isMobile = useIsMobile();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const onSortByChange = useCallback(
        (sortBy: ListSortByEnum) => {
            dispatch.tradeLimitOrder.setControl({
                ...tradeLimitOrder.control,
                sortBy,
            });
        },
        [tradeLimitOrder, dispatch]
    );

    const onSearchKeyChange = useCallback(
        (e: any) => {
            console.log(e.target.value);
            const control = { ...tradeLimitOrder.control };
            control.searchKey = e.target.value;
            dispatch.tradeLimitOrder.setControl(control);
        },
        [tradeLimitOrder, dispatch]
    );

    const onTypeChange = useCallback(
        (e: any) => {
            console.log(e);
            const control = { ...tradeLimitOrder.control };
            control.type = e;
            dispatch.tradeLimitOrder.setControl(control);
        },
        [tradeLimitOrder, dispatch]
    );

    const onShowByPair = useCallback(
        (e: any) => {
            console.log(e);
            const control = { ...tradeLimitOrder.control };
            control.showByPair = e.target.checked;
            console.log(control);
            dispatch.tradeLimitOrder.setControl(control);
        },
        [tradeLimitOrder, dispatch]
    );
    const TypeBlock = () => (
        <HStack direction="row" ml={{ base: 'auto', sm: '0px' }}>
            <CustomButton
                variant="none"
                text="Active"
                pt="6px"
                pl="40px"
                className={i_text_copy_bold}
                fontSize="16px"
                selected={tradeLimitOrder.control.type === 'active'}
                onClick={() => {
                    onTypeChange('active');
                }}
            />

            <CustomButton
                variant="none"
                pt="6px"
                pl="10px"
                text="History"
                className={i_text_copy_bold}
                fontSize="16px"
                selected={tradeLimitOrder.control.type === 'history'}
                onClick={() => {
                    onTypeChange('history');
                }}
            />
        </HStack>
    );

    const ZipBlock = () => (
        <FormControl
            display="flex"
            alignItems="center"
            w="unset"
            mr={{ base: '0px !important', sm: '40px !important' }}
            ml={{ base: 'auto !important', sm: '0px !important' }}
        >
            <FormLabel className={i_text_copy} fontSize="12px" fontWeight="500" ml={{ base: 'unset', lg: '20px' }} pt="6px">
                Zip
            </FormLabel>
            <Switch isChecked={tradeLimitOrder.control.showByPair} onChange={(e) => onShowByPair(e)} size="lg"></Switch>
        </FormControl>
    );
    const SortByBlock = () => (
        <FormControl display="flex" alignItems="center" w="unset">
            <FormLabel className={i_text_copy} fontSize="12px" fontWeight="500" pt="6px">
                Sort by
            </FormLabel>
            <Menu>
                {() => (
                    <>
                        <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            className={i_text_copy_bold}
                            fontSize="14px"
                            w={{ base: '155px', sm: '178px' }}
                        >
                            {tradeLimitOrder.control.sortBy || ListSortByEnum.Default}
                        </MenuButton>
                        <MenuList className={i_text_copy}>
                            <MenuItem onClick={() => onSortByChange(ListSortByEnum.Default)}> {ListSortByEnum.Default}</MenuItem>
                            <MenuItem onClick={() => onSortByChange(ListSortByEnum.TimeDesc)}> {ListSortByEnum.TimeDesc}</MenuItem>
                            <MenuItem onClick={() => onSortByChange(ListSortByEnum.TimeAsc)}> {ListSortByEnum.TimeAsc}</MenuItem>
                        </MenuList>
                    </>
                )}
            </Menu>
        </FormControl>
    );

    return isMobile ? (
        <HStack w="100%" alignItems="start" pt="16px" pb="12px" justifyContent={{ base: 'space-between' }}>
            <HStack w="100%">
                <InputGroup w="274px" h="35px">
                    <InputLeftElement boxSize="20px" mt="5px" ml="10px">
                        <SearchIcon boxSize={5} />
                    </InputLeftElement>
                    <Input
                        variant="filled"
                        h="30px"
                        placeholder={t('Search Tokens')}
                        size="lg"
                        className={i_text_copy_bold}
                        fontSize="12px"
                        onChange={(e) => onSearchKeyChange(e)}
                        value={tradeLimitOrder.control.searchKey || ''}
                        bg={colorTheme('#FFFFFF', 'rgba(37, 32, 59, 0.5)')}
                        color="tertiary.300"
                        letterSpacing="0.02em"
                        fontFamily="Montserrat"
                    />
                </InputGroup>
                <HStack spacing="13px" ml="auto !important">
                    <Filter
                        filterTitle={t('Order Filter')}
                        filterElement={[<TypeBlock key={0} />, <ZipBlock key={1} />, <SortByBlock key={2} />]}
                    ></Filter>
                </HStack>
            </HStack>
            <Stack direction={{ base: 'column-reverse', xl: 'row' }} flexGrow={{ base: 0, xl: 1 }} justifyContent="space-between"></Stack>
        </HStack>
    ) : (
        <HStack w="100%" alignItems="start" py="34px" justifyContent={{ base: 'space-between' }}>
            <InputGroup w="310px" h="41px" mt="-4px">
                <Input
                    variant="filled"
                    placeholder={t('Search Tokens')}
                    size="lg"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e) => onSearchKeyChange(e)}
                    value={tradeLimitOrder.control.searchKey || ''}
                />
                <InputRightElement h="100%" mt="4px">
                    <SearchIcon boxSize={5} />
                </InputRightElement>
            </InputGroup>

            <Stack direction={{ base: 'column-reverse', xl: 'row' }} flexGrow={{ base: 0, xl: 1 }} justifyContent="space-between">
                <TypeBlock />

                <ZipBlock />
                <SortByBlock />
            </Stack>
        </HStack>
    );
};

export default MyLimitOrderControls;

import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import chains from '../config/chains';
import Web3 from 'web3';

export enum ConnectorNames {
    Injected = 'injected',
    WalletConnect = 'walletconnect',
    Particle = 'particle',
    //BitKeep = 'BitKeep',
}

export enum ParticleConnectorNames {
    Email = 'email',
    FaceBook = 'facebook',
    Google = 'google',
    Twitter = 'twitter',
    Github = 'github',
    Apple = 'apple',
}

const supportedChainIds = chains.all.map((chain) => chain.id);

const injected = new InjectedConnector({
    supportedChainIds: supportedChainIds,
});

const particle = new InjectedConnector({
    supportedChainIds: supportedChainIds,
});

const walletconnect = new WalletConnectConnector({
    rpc: chains.all.reduce((acc, chain) => Object.assign(acc, { [chain.id]: chain.rpcUrl }), {}),
    qrcode: true,
    //pollingInterval: 12000,
});

const connectors = {
    [ConnectorNames.Injected]: injected,
    [ConnectorNames.WalletConnect]: walletconnect,
    [ConnectorNames.Particle]: particle,
};

export const connectorsArray = [
    {
        name: ConnectorNames.Injected,
        displayName: 'Metamask',
        connector: injected,
        url: 'metamask.png',
    },
    {
        name: ConnectorNames.Particle,
        displayName: 'Particle',
        connector: particle,
        url: 'particle.png',
    },
    {
        name: ConnectorNames.WalletConnect,
        displayName: 'Wallet Connect',
        connector: walletconnect,
        url: 'walletConnectIcon.svg',
    },
    // currently bitkeep use window.ethereum, which conflicts with Metamask
    {
        name: ConnectorNames.Injected,
        displayName: 'BitKeep',
        connector: injected,
        url: 'bitkeep.png',
    },
    {
        name: ConnectorNames.Injected,
        displayName: 'TokenPocket',
        connector: injected,
        url: 'tp.png',
    },
    {
        name: ConnectorNames.Injected,
        displayName: 'Nabox',
        connector: injected,
        url: 'nabox.png',
    },
    {
        name: ConnectorNames.Injected,
        displayName: 'Math',
        connector: injected,
        url: 'MathWallet.png',
    },
];

export const particleArray = [
    {
        name: ParticleConnectorNames.Email,
        displayName: 'Email',
        connector: particle,
        url: 'email_icon.png',
    },
    {
        name: ParticleConnectorNames.Google,
        displayName: 'Google',
        connector: particle,
        url: 'google_icon.png',
    },
    {
        name: ParticleConnectorNames.Apple,
        displayName: 'Apple',
        connector: particle,
        url: 'apple_icon.png',
    },
    {
        name: ParticleConnectorNames.FaceBook,
        displayName: 'FaceBook',
        connector: particle,
        url: 'facebook_icon.png',
    },
    {
        name: ParticleConnectorNames.Github,
        displayName: 'Github',
        connector: particle,
        url: 'github_icon.png',
    },
    {
        name: ParticleConnectorNames.Twitter,
        displayName: 'Twitter',
        connector: particle,
        url: 'twitter_icon.png',
    },
];

export const getLibrary = (provider: any): Web3 => {
    return provider;
};

export default connectors;

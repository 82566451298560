import { Box, Flex } from '@chakra-ui/react';
import { useSetState } from 'ahooks';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PageLayout from '../../components/layout/PageLayout';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useWeb3WithDefault } from '../../hooks/useWeb3WithDefault';
import useIsMobile from '../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { getIziSwapTransRecord, ResponseIziTransRecord, TransRecordTypeEnum } from '../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { RootState } from '../../state/store';
import ChainNotValidBlockWrapper from '../components/ChainNotValidBlock';
import NotConnectModal from '../Trade/components/NotConnectModal';
import Tabs from '../Trade/components/Tabs';
import HistoryForm from './components/HistoryForm';

export interface TransListSetting {
    page: number;
    pageSize: number;
}

const OrderHistory: React.FC = () => {
    const { t } = useTranslation();
    const { account, chainId } = useWeb3WithDefault();
    const isMobile = useIsMobile();

    const [loading, setLoading] = useState(true);

    const [setting, setSetting] = useSetState<TransListSetting>({
        page: 1,
        pageSize: 10,
    });
    const [totalPage, setTotalPage] = useState(1);
    const [swapData, setSwapData] = useState<ResponseIziTransRecord[]>();

    const [tabValue, setTabValue] = useState('Swap');
    const {
        historyOrder: { control },
    } = useSelector((state: RootState) => state);

    const handleChangeTab = (value: string) => {
        if (value === 'Swap') {
            setTabValue('Swap');
        }
        if (value === 'Liquidity') {
            setTabValue('Liquidity');
        }
        if (value === 'Limit Order') {
            setTabValue('Limit Order');
        }
    };

    useEffect(() => {
        if (account) {
            setLoading(true);
            const swapType = [TransRecordTypeEnum.IZI_SWAP_TOKEN].join();
            const liquidityType = [TransRecordTypeEnum.IZI_SWAP_INC_LIQ, TransRecordTypeEnum.IZI_SWAP_DEC_LIQ].join();
            const limitType = [TransRecordTypeEnum.IZI_SWAP_INC_LIMIT_ORDER, TransRecordTypeEnum.IZI_SWAP_DEC_LIMIT_ORDER].join();

            getIziSwapTransRecord({
                chain_id: chainId,
                type_list: tabValue === 'Swap' ? swapType : tabValue === 'Liquidity' ? liquidityType : limitType,
                account_addr: account || '',
                page: setting.page,
                page_size: setting.pageSize,
                order_by: control.sortBy,
                time_start: moment().add(-control.timeRemain, 'days').format('YYYY-MM-DD HH:mm:ss'),
                time_end: moment().format('YYYY-MM-DD HH:mm:ss'),
            }).then((r) => {
                setLoading(false);
                // deep copy
                setSwapData(
                    Array.isArray(r.data.data)
                        ? r.data.data.map((a) => {
                              return { ...a };
                          })
                        : []
                );
                setTotalPage(r.data.is_success ? Math.ceil(r.data.total / setting.pageSize) : 1);
            });
        }
    }, [account, chainId, setting.page, setting.pageSize, control.timeRemain, control.sortBy, tabValue]);

    return isMobile ? (
        <PageLayout
            body={
                account ? (
                    <ChainNotValidBlockWrapper
                        content={
                            <Flex w="100%">
                                <Box w="100%">
                                    <Tabs
                                        notShowDivider={true}
                                        list={[
                                            {
                                                label: t('Swap'),
                                                value: 'Swap',
                                                component: (
                                                    <HistoryForm
                                                        tab="Swap"
                                                        data={swapData}
                                                        loading={loading}
                                                        totalPage={totalPage}
                                                        setting={setting}
                                                        setCurrentPage={(page: number) => setSetting({ page })}
                                                        setPageSize={(pageSize: number) =>
                                                            setSetting({
                                                                pageSize,
                                                            })
                                                        }
                                                    />
                                                ),
                                            },
                                            {
                                                label: t('Liquidity'),
                                                value: 'Liquidity',
                                                component: (
                                                    <HistoryForm
                                                        tab={'Liquidity'}
                                                        data={swapData}
                                                        loading={loading}
                                                        totalPage={totalPage}
                                                        setting={setting}
                                                        setCurrentPage={(page: number) => setSetting({ page })}
                                                        setPageSize={(pageSize: number) =>
                                                            setSetting({
                                                                pageSize,
                                                            })
                                                        }
                                                    />
                                                ),
                                            },
                                            {
                                                label: t('Limit Order'),
                                                value: 'Limit Order',
                                                component: (
                                                    <HistoryForm
                                                        tab={'Limit Order'}
                                                        data={swapData}
                                                        loading={loading}
                                                        totalPage={totalPage}
                                                        setting={setting}
                                                        setCurrentPage={(page: number) => setSetting({ page })}
                                                        setPageSize={(pageSize: number) =>
                                                            setSetting({
                                                                pageSize,
                                                            })
                                                        }
                                                    />
                                                ),
                                            },
                                        ]}
                                        value={tabValue}
                                        handleClick={handleChangeTab}
                                        dividerStyle={{
                                            w: '1050px',
                                            top: '142px',
                                        }}
                                    ></Tabs>
                                </Box>
                            </Flex>
                        }
                        app="trade"
                    />
                ) : (
                    <NotConnectModal />
                )
            }
            type="mobile"
            isShowScrollTab={false}
        />
    ) : (
        <PageLayout
            header={<PageHeader alignItems="center" variant="type2" headerTitle={t('Order History')} subtitle={''} />}
            body={
                account ? (
                    <ChainNotValidBlockWrapper
                        content={
                            <Flex w="100%">
                                <Box w="100%">
                                    <Tabs
                                        variant={'simple'}
                                        notShowDivider={true}
                                        list={[
                                            {
                                                label: t('Swap'),
                                                value: 'Swap',
                                                component: (
                                                    <HistoryForm
                                                        tab="Swap"
                                                        data={swapData}
                                                        loading={loading}
                                                        totalPage={totalPage}
                                                        setting={setting}
                                                        setCurrentPage={(page: number) => setSetting({ page })}
                                                        setPageSize={(pageSize: number) =>
                                                            setSetting({
                                                                pageSize,
                                                            })
                                                        }
                                                    />
                                                ),
                                            },
                                            {
                                                label: t('Liquidity'),
                                                value: 'Liquidity',
                                                component: (
                                                    <HistoryForm
                                                        tab={'Liquidity'}
                                                        data={swapData}
                                                        loading={loading}
                                                        totalPage={totalPage}
                                                        setting={setting}
                                                        setCurrentPage={(page: number) => setSetting({ page })}
                                                        setPageSize={(pageSize: number) =>
                                                            setSetting({
                                                                pageSize,
                                                            })
                                                        }
                                                    />
                                                ),
                                            },
                                            {
                                                label: t('Limit Order'),
                                                value: 'Limit Order',
                                                component: (
                                                    <HistoryForm
                                                        tab={'Limit Order'}
                                                        data={swapData}
                                                        loading={loading}
                                                        totalPage={totalPage}
                                                        setting={setting}
                                                        setCurrentPage={(page: number) => setSetting({ page })}
                                                        setPageSize={(pageSize: number) =>
                                                            setSetting({
                                                                pageSize,
                                                            })
                                                        }
                                                    />
                                                ),
                                            },
                                        ]}
                                        value={tabValue}
                                        handleClick={handleChangeTab}
                                        dividerStyle={{
                                            w: '1050px',
                                            top: '142px',
                                        }}
                                    ></Tabs>
                                </Box>
                            </Flex>
                        }
                        app="trade"
                    />
                ) : (
                    <NotConnectModal />
                )
            }
            internalWidth={{ base: '700px', xl: '840px', xxl: '1100px', xlp1: '1150px' }}
        />
    );
};

export default OrderHistory;

import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { getLibrary } from '../utils/connectors';
import { QueryClient, QueryClientProvider } from 'react-query';
import LanguageContext, { languageStore } from './LanguageProvider';
import ToastProvider from './ToastProvider';
import theme from '../theme/theme';
import ConnectProvider from './ConnectProvider';
import { store as modelStore } from '../state/store';
import { Provider } from 'react-redux';

const queryClient = new QueryClient();

const Providers: React.FC = ({ children }) => {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Provider store={modelStore}>
                <QueryClientProvider client={queryClient}>
                    <LanguageContext.Provider value={languageStore}>
                        <ChakraProvider theme={theme}>
                            <ToastProvider>
                                <ConnectProvider>
                                    <Router>{children}</Router>
                                </ConnectProvider>
                            </ToastProvider>
                        </ChakraProvider>
                    </LanguageContext.Provider>
                </QueryClientProvider>
            </Provider>
        </Web3ReactProvider>
    );
};

export default Providers;

import { Slider, SliderFilledTrack, SliderProps, SliderThumb, SliderTrack, useColorMode } from '@chakra-ui/react';
import { default as React } from 'react';
import { i_text_copy_bold } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';

type Props = {
    value: number;
    handleChange: (value: number) => void;
} & SliderProps;

export const CustomSlider: React.FC<Props> = ({ value, handleChange, ...rest }) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    return (
        <Slider
            boxShadow="0px 0px 27px rgba(196, 194, 198, 0.25)"
            borderRadius="6px"
            aria-label="slider-ex-1"
            value={value}
            onChange={handleChange}
            colorScheme={colorTheme('linerPurple', 'linerPurple')}
            bg="transparent"
            h="31px"
            py="0"
            {...rest}
        >
            <SliderTrack h="31px" bg={colorTheme('white', '#9388AD')}>
                <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb h="41px" w="41px" bg="#3D4375" marginLeft="-5px" color="#fff" className={i_text_copy_bold}>
                {value}%
            </SliderThumb>
        </Slider>
    );
};

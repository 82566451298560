import { Stack, HStack, Text, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../../../../hooks/useWindowDimension';
import { RootState } from '../../../../../state/store';
import { noto_t3 } from '../../../../../style';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { formatNumber } from '../../../../../utils/tokenMath';
import { trades } from '../OrderBook';
import CurrentPriceBlock from './CurrentPriceBlock';

type OrderBuyBlockProps = {
    currentType: string;
    trades: trades[];
    realPrice: number | false | undefined;
};

const OrderBuyBlock: React.FC<OrderBuyBlockProps> = (props) => {
    const { currentType, trades, realPrice } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const { proOrderBook } = useSelector((state: RootState) => state);
    const { height } = useWindowDimensions();
    const chartHeight =
        currentType === 'all'
            ? height > 920
                ? 199
                : height > 865
                ? (height - 520) / 2
                : 178
            : // current type = buy
            height > 920
            ? 401
            : height > 865
            ? height - 520
            : 358;
    const data = proOrderBook.lowQuantity;
    const maxQuantity = Math.max(...data);
    const minQuantity = Math.min(...data);

    const summary = [] as number[];

    proOrderBook.lowQuantity.map((quantity, index) => {
        if (summary.length === 0) {
            summary.push(quantity);
        } else {
            summary.push(quantity + summary[index - 1]);
        }
    });

    return (
        <Stack w="100%">
            {currentType === 'buy' && (
                <CurrentPriceBlock position="sticky" top="0px" trades={trades} realPrice={realPrice}></CurrentPriceBlock>
            )}
            <Stack h={chartHeight} overflow={currentType === 'all' ? 'hidden' : 'auto'} spacing="2px">
                {proOrderBook.lowQuantity.map((quantity, index) => {
                    const buyPriceDecimal = proOrderBook.lowPriceDecimal[index];
                    let bgWidth = 0;
                    if (quantity === maxQuantity) {
                        bgWidth = 1;
                    } else if (quantity === minQuantity) {
                        bgWidth = 0.2;
                    } else {
                        bgWidth = 0.2 + ((quantity - minQuantity) / (maxQuantity - minQuantity)) * 0.8;
                    }
                    return (
                        <Stack w="100%" key={index} alignItems="end">
                            <Stack w={bgWidth * 100 + '%'} h="20px" bg={colorTheme('#E6F2E8', '#1B2723')} zIndex="1"></Stack>

                            <HStack
                                w="100%"
                                h="20px"
                                className={noto_t3}
                                key={index}
                                borderRadius="2px"
                                cursor="pointer"
                                mt="-20px !important"
                                px="8px"
                                zIndex="2"
                                spacing="0px"
                                _hover={{ bg: colorTheme('rgba(192, 245, 201, 0.3)', '#31514B') }}
                            >
                                <Text w="33%" color="#12931F">
                                    {formatNumber(buyPriceDecimal, 2, 2, true)}
                                </Text>
                                <Text w="33%" textAlign="end" color={colorTheme('#767676', '#C9C3D7')}>
                                    {formatNumber(quantity, 2, 2, true)}
                                </Text>
                                <Text w="33%" textAlign="end" color={colorTheme('#767676', '#C9C3D7')}>
                                    {formatNumber(summary[index], 2, 2, true)}
                                </Text>
                            </HStack>
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
};

export default OrderBuyBlock;

import Web3 from "web3"
import { ChainId } from "../../../../../types/mod"
import { getBoxContract, getQuoterContract, getSwapContract } from "../../../../../utils/contractFactory"
import { BasePathQueryPlugin, TokenSpenderInfo } from "../BaseDexPlugin"
import { iZiPreQueryResult } from './types'
import { BOX_ADDRESS, QUOTER_ADDRESS, QUOTER_ADDRESS_LIMIT, QUOTER_TYPE, SWAP_ADDRESS } from "../../../../../config/trade/tradeContracts"
import { decodeMethodResult } from "../../../../../utils/contractHelpers"
import { Contract } from 'web3-eth-contract'
import BigNumber from "bignumber.js"
import { QuoterContract, SwapAmountResponse, SwapDesireResponse } from "../../../../../types/abis/iZiSwap/Quoter"
import { TokenInfoFormatted } from "../../../../../hooks/useTokenListFormatted"
import { CallingProperty, Path, PathQuery, PathQueryCalling, PathQueryResult, SwapDirection } from "../utils"
import { amount2Decimal } from "../../../../../utils/tokenMath"
import { point2PriceDecimal } from "../../swap/priceFuncs"
import { getChain } from "../../../../../config/chains"
import { SwapContract } from "../../../../../types/abis/iZiSwap/Swap"
import { BoxContract } from "../../../../../types/abis/iZiSwap/Box"
import { IZUMI_SWAP_CONFIG } from "../../../../../config/bizConfig"
import { isGasToken } from "../../../../../config/tokens"
import { getSwapTokenAddress } from "../../../common/positionPoolHelper"

export class iZiSwapPathQueryPlugin extends BasePathQueryPlugin {

    private chainId: ChainId
    private quoterContract: QuoterContract
    private quoterContractAddress: string

    private swapContract: SwapContract
    private boxContract: BoxContract

    private amount: string = undefined as unknown as string
    private direction: SwapDirection = undefined as unknown as SwapDirection
    private tokenIn: TokenInfoFormatted = undefined as unknown as TokenInfoFormatted
    private tokenOut: TokenInfoFormatted = undefined as unknown as TokenInfoFormatted

    private getLimitQuoterAddress(chainId: ChainId): string {
        return (QUOTER_ADDRESS_LIMIT[chainId] ?? QUOTER_ADDRESS[chainId]) as string
    }

    public constructor(preQueryResult: iZiPreQueryResult, config: any, chainId: ChainId, web3: Web3) {
        super(preQueryResult)
        this.chainId = chainId
        config = (config ?? QUOTER_TYPE.full) as QUOTER_TYPE
        this.quoterContractAddress = (config === QUOTER_TYPE.full) ? QUOTER_ADDRESS[chainId] : this.getLimitQuoterAddress(chainId)
        this.quoterContract = getQuoterContract(chainId, web3, false) as QuoterContract
        this.swapContract = getSwapContract(chainId, web3) as SwapContract
        this.boxContract = getBoxContract(chainId, web3) as BoxContract
    }
    
    private num2Hex(n: number): string {
        if (n < 10) {
            return String(n);
        }
        const str = 'ABCDEF';
        return str[n - 10];
    }

    private fee2Hex(fee: number): string {
        const n0 = fee % 16;
        const n1 = Math.floor(fee / 16) % 16;
        const n2 = Math.floor(fee / 256) % 16;
        const n3 = Math.floor(fee / 4096) % 16;
        const n4 = 0;
        const n5 = 0;
        return '0x' + this.num2Hex(n5) + this.num2Hex(n4) + this.num2Hex(n3) + this.num2Hex(n2) + this.num2Hex(n1) + this.num2Hex(n0);
    }

    private appendHex(hexString: string, newHexString: string): string {
        return hexString + newHexString.slice(2);
    }

    private getTokenChainPath(tokenChain: TokenInfoFormatted[], feeChain: number[]): string {
        let hexString = tokenChain[0].wrapTokenAddress ?? tokenChain[0].address
        for (let i = 0; i < feeChain.length; i++) {
            hexString = this.appendHex(hexString, this.fee2Hex(feeChain[i]))
            hexString = this.appendHex(hexString, tokenChain[i + 1].wrapTokenAddress ?? tokenChain[i + 1].address)
        }
        return hexString
    }
    private getTokenChainPathReverse(tokenChain: TokenInfoFormatted[], feeChain: number[]): string {
        let hexString = tokenChain[tokenChain.length - 1].wrapTokenAddress ?? tokenChain[tokenChain.length - 1].address
        for (let i = feeChain.length - 1; i >= 0; i--) {
            hexString = this.appendHex(hexString, this.fee2Hex(feeChain[i]))
            hexString = this.appendHex(hexString, tokenChain[i].wrapTokenAddress ?? tokenChain[i].address)
        }
        return hexString
    }

    private reverse(path: Path): Path {
        return {
            ...path,
            tokenChain: path.tokenChain.slice().reverse(),
            feeRate: path.feeRate?.slice().reverse() ?? undefined,
            feeContractNumber: path.feeContractNumber.slice().reverse()
        }
    }

    override getPathQuery(tokenIn: TokenInfoFormatted, tokenOut: TokenInfoFormatted, direction: SwapDirection, amount: string): PathQuery[] {

        this.tokenIn = tokenIn
        this.tokenOut = tokenOut
        this.direction = direction
        this.amount = amount

        const preQueryResult = this.preQueryResult as iZiPreQueryResult
        
        const pathQuery = [] as PathQuery[]
        for (const path of preQueryResult.pathWithOutFee100) {
            const p = (path.tokenChain[0].symbol === tokenIn.symbol) ? path : this.reverse(path)
            if (!p.feeRate) {
                p.feeRate = p.feeContractNumber.map((e)=>e/1e6)
            }
            const pathChain = (direction === SwapDirection.ExactIn) ? this.getTokenChainPath(p.tokenChain, p.feeContractNumber) : this.getTokenChainPathReverse(p.tokenChain, p.feeContractNumber)
            const calling = (direction === SwapDirection.ExactIn) ? this.quoterContract.methods.swapAmount(amount, pathChain) : this.quoterContract.methods.swapDesire(amount, pathChain)
            pathQuery.push({
                path: p,
                pathQueryCalling: {
                    calling: calling.encodeABI(),
                    targetAddress: this.quoterContractAddress,
                    callingProperty: CallingProperty.Short
                } as PathQueryCalling
            } as PathQuery)
        }

        for (const path of preQueryResult.pathWithFee100) {
            const p = (path.tokenChain[0].symbol === tokenIn.symbol) ? path : this.reverse(path)
            if (!p.feeRate) {
                p.feeRate = p.feeContractNumber.map((e)=>e/1e6)
            }
            const pathChain = (direction === SwapDirection.ExactIn) ? this.getTokenChainPath(p.tokenChain, p.feeContractNumber) : this.getTokenChainPathReverse(p.tokenChain, p.feeContractNumber)
            const calling = (direction === SwapDirection.ExactIn) ? this.quoterContract.methods.swapAmount(amount, pathChain) : this.quoterContract.methods.swapDesire(amount, pathChain)
            pathQuery.push({
                path: p,
                pathQueryCalling: {
                    calling: calling.encodeABI(),
                    targetAddress: this.quoterContractAddress,
                    callingProperty: CallingProperty.Long
                } as PathQueryCalling
            } as PathQuery)
        }
        
        return pathQuery
    }

    private noSufficientLiquidity(path: Path, pointAfterList: number[]): boolean {
        for (let i = 0; i < path.feeContractNumber.length; i++) {
            const tokenA = path.tokenChain[i]
            const tokenB = path.tokenChain[i + 1]
            const tokenAAddress = getSwapTokenAddress(tokenA)
            const tokenBAddress = getSwapTokenAddress(tokenB)
            if (tokenAAddress.toLowerCase() < tokenBAddress.toLowerCase()) {
                // x2y mode
                if (pointAfterList[i] <= -799999) {
                    return true
                }
            } else {
                // y2x mode
                if (pointAfterList[i] >= 799999) {
                    return true
                }
            }
        }
        return false
    }

    private estimateFee(path: Path, inputAmount: number): number {
        let remainAmount = inputAmount
        for (const fee of path.feeContractNumber) {
            remainAmount = remainAmount - remainAmount * fee / 1e6
        }
        return inputAmount - remainAmount
    }
    private getSwapPoolKey(tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted, feeContractNumber: number) : string {
        const tokenASymbol = tokenA.symbol.toUpperCase()
        const tokenBSymbol = tokenB.symbol.toUpperCase()
        if (tokenASymbol < tokenBSymbol) {
            return tokenASymbol + '-' + tokenBSymbol + '-' + String(feeContractNumber)
        } else {
            return tokenBSymbol + '-' + tokenASymbol + '-' + String(feeContractNumber)
        }
    }
    private getOriginPointList(path: Path): number[] {
        const ret = [] as number[]
        const preQueryResult = this.preQueryResult as iZiPreQueryResult
        for (let i = 0; i < path.feeContractNumber.length; i ++) {
            const tokenA = path.tokenChain[i]
            const tokenB = path.tokenChain[i + 1]
            const fee = path.feeContractNumber[i]
            const swapPoolKey = this.getSwapPoolKey(tokenA, tokenB, fee)
            const point = preQueryResult.poolPoint.get(swapPoolKey) as number
            ret.push(point)
        }
        return ret
    }
    
    private getPriceDecimalEndByStart(path: Path, pointList: number[]): number {
        let decimalPriceEndByStart = 1
        for (let i = 0; i < path.feeContractNumber.length; i++) {
            if (!pointList[i] && pointList[i] !== 0) {
                return undefined as unknown as number
            }
            const decimalPriceBackByFront = point2PriceDecimal(path.tokenChain[i + 1], path.tokenChain[i], pointList[i])
            decimalPriceEndByStart *= decimalPriceBackByFront
        }
        return decimalPriceEndByStart
    }

    override parseCallingResponse(path: Path, direction: SwapDirection, amount: string, result: string): PathQueryResult {
        let responseAmount = '0'
        let pointAfterList = [] as number[]
        if (direction === SwapDirection.ExactIn) {
            const swapAmountRes: SwapAmountResponse = decodeMethodResult(this.quoterContract as unknown as Contract, 'swapAmount', result)
            responseAmount = swapAmountRes.acquire
            pointAfterList = swapAmountRes.pointAfterList.map((e: string)=>Number(e))
        } else {
            const swapDesireRes: SwapDesireResponse = decodeMethodResult(this.quoterContract as unknown as Contract, 'swapDesire', result)
            responseAmount = swapDesireRes.cost
            pointAfterList = swapDesireRes.pointAfterList.slice().reverse().map((e: string) => Number(e))
        }
        const noSufficientLiquidity = this.noSufficientLiquidity(path, pointAfterList)
        const inputAmount = (this.direction === SwapDirection.ExactIn) ? amount : responseAmount
        const inputAmountDecimal = Number(amount2Decimal(new BigNumber(inputAmount), this.tokenIn))
        const feesDecimal = this.estimateFee(path, inputAmountDecimal)
        const pointBeforeList = this.getOriginPointList(path)

        const initDecimalPriceEndByStart = this.getPriceDecimalEndByStart(path, pointBeforeList)

        const afterDecimalPriceEndByStart = this.getPriceDecimalEndByStart(path, pointAfterList)
        const impact = Math.abs((afterDecimalPriceEndByStart - initDecimalPriceEndByStart) / initDecimalPriceEndByStart)
        const priceImpact = impact
        return {
            amount: responseAmount,
            path,
            noSufficientLiquidity,
            initDecimalPriceEndByStart,
            priceImpact,
            feesDecimal
        } as PathQueryResult
    }

    override getTokenSpenderInfo(path: Path, direction: SwapDirection): TokenSpenderInfo {
        const chainToken = getChain(this.chainId)?.token
        const tokenToPay = path.tokenChain[0]
        if (chainToken?.symbol === tokenToPay.symbol) {
            // etc, eth of ethereum or bnb of bsc...
            return {tokenToPay}
        }
        const tokenToGet = path.tokenChain[path.tokenChain.length - 1]
        if (!tokenToPay.wrapTokenAddress && !tokenToGet.wrapTokenAddress) {
            // donot use box
            const spenderAddress = SWAP_ADDRESS[this.chainId]
            return {tokenToPay, spenderAddress}
        }
        if (tokenToPay.wrapTokenAddress) {
            // use box but pay wrap token, need to deposit to wrap token
            return {
                tokenToPay,
                spenderAddress: tokenToPay.wrapTokenAddress,
                depositSpenderAddress: BOX_ADDRESS[this.chainId]
            }
        }
        // use box but pay normal erc20 token
        return {
            tokenToPay,
            spenderAddress: BOX_ADDRESS[this.chainId]
        }
    }

    private swapAmount(
        path: Path, 
        tokenIn: TokenInfoFormatted,
        tokenOut: TokenInfoFormatted,
        amountIn: string, 
        amountOut: string, 
        account: string, 
        maxDelay: number,
        slippagePercent: number
    ): {calling: any, options: any} {

        const swapPathHexString = this.getTokenChainPath(path.tokenChain, path.feeContractNumber)

        const minAcquired = (new BigNumber(amountOut)).times(100 - slippagePercent).div(100).toFixed(0)

        const swapParams = {
            recipient: account,
            amount: amountIn,
            path: swapPathHexString,
            minAcquired,
            deadline: String(Math.floor((new Date()).valueOf() / 1000) + maxDelay * 60)
        };

        const contract = this.swapContract as SwapContract;

        const ifBuyETH = isGasToken(tokenOut, this.chainId)
        const costETH = isGasToken(tokenIn, this.chainId) ? swapParams.amount : '0'

        if (ifBuyETH) {
            swapParams.recipient = SWAP_ADDRESS[this.chainId]
            const multicall: string[] = []
            multicall.push(contract.methods.swapAmount(swapParams).encodeABI())
            multicall.push(contract.methods.unwrapWETH9('0', account).encodeABI())
            const calling = contract.methods.multicall(multicall)
            const options = { from: account, value: costETH }
            return {calling, options}
        } else {
            if (costETH !== '0') {
                const multicall: string[] = [];
                multicall.push(contract.methods.swapAmount(swapParams).encodeABI());
                multicall.push(contract.methods.refundETH().encodeABI());
                const calling = contract.methods.multicall(multicall)
                const options = { from: account, value: costETH }
                return {calling, options}
            } else {
                const calling = contract.methods.swapAmount(swapParams)
                const options = { from: account }
                return {calling, options}
            }
        }
    }

    private swapDesire(
        path: Path, 
        tokenIn: TokenInfoFormatted,
        tokenOut: TokenInfoFormatted,
        amountIn: string, 
        amountOut: string, 
        account: string, 
        maxDelay: number,
        slippagePercent: number
    ): {calling: any, options: any} {
        const swapPathHexString = this.getTokenChainPathReverse(path.tokenChain, path.feeContractNumber);
        const maxPayed = (new BigNumber(amountIn)).times(100 + slippagePercent).div(100).toFixed(0);

        const swapParams = {
            recipient: account,
            desire: amountOut,
            path: swapPathHexString,
            maxPayed,
            deadline: String(Math.floor((new Date()).valueOf() / 1000) + maxDelay * 60)
        };

        const contract = this.swapContract as SwapContract;
        const ifBuyETH = isGasToken(tokenOut, this.chainId);
        if (ifBuyETH) {
            swapParams.recipient = SWAP_ADDRESS[this.chainId];
        }
        const costETH = isGasToken(tokenIn, this.chainId) ? swapParams.maxPayed : '0';
        const multicall: any[] = [];
        multicall.push(contract.methods.swapDesire(swapParams));
        if (ifBuyETH) {
            multicall.push(contract.methods.unwrapWETH9('0', account));
        }
        if (new BigNumber(costETH).gt('0')) {
            multicall.push(contract.methods.refundETH());
        }
        const calling = (multicall.length > 1) ? contract.methods.multicall(multicall.map((c)=>c.encodeABI())) : multicall[0]
        const options = { from: account, value: costETH }
        return {calling, options}
    }

    override getSwapTransaction(
        path: Path, 
        direction: SwapDirection, 
        amountIn: string, 
        amountOut: string, 
        account: string, 
        maxDelay: number,
        slippagePercent: number
    ): {calling: any, options: any} {

        const tokenIn = path.tokenChain[0]
        const tokenOut = path.tokenChain[path.tokenChain.length - 1]
        const noWrapToken = !tokenIn.wrapTokenAddress && !tokenOut.wrapTokenAddress

        const swapPathHexString = this.getTokenChainPath(path.tokenChain, path.feeContractNumber)
        if (noWrapToken) {
            if (direction === SwapDirection.ExactIn) {
                return this.swapAmount(path, tokenIn, tokenOut, amountIn, amountOut, account, maxDelay, slippagePercent)
            } else {
                return this.swapDesire(path, tokenIn, tokenOut, amountIn, amountOut, account, maxDelay, slippagePercent)
            }
        } else {
            // box
            // direction must be SwapDirection.ExactIn
            const acquire = amountOut
            const minAcquired = (new BigNumber(acquire)).times(100 - slippagePercent).div(100).times(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FEE_TOKEN_FACTOR).toFixed(0)
            const swapParams = {
                recipient: account,
                amount: amountIn,
                path: swapPathHexString,
                minAcquired,
                deadline: String(Math.floor((new Date()).valueOf() / 1000) + maxDelay * 60)
            }
            const contract = this.boxContract as BoxContract;
            const firstIsWrap = !tokenIn.wrapTokenAddress ? false : true
            const lastIsWrap = !tokenOut.wrapTokenAddress ? false : true
            const value = isGasToken(tokenIn, this.chainId) ? swapParams.amount : '0'
            const calling = contract.methods.swapAmount(swapParams, firstIsWrap, lastIsWrap)
            const options = { from: account, value }
            return {calling, options}
        }
    }

}
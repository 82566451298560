import { ChainId } from '../types/mod';
import { getScanUrl } from './chains';

export const links = {
    docs: 'https://www.ic-plaza.org/ICPLAZA_WhitePaper.pdf',
    developerDocs: 'https://www.ic-plaza.org/ICPLAZA_WhitePaper.pdf',
    github: 'https://github.com/ICPLAZA-org',
    telegram: 'https://t.me/ICPlaza_gl',
    telegramCN: 'https://t.me/ICPlaza_gl',
    telegramEN: 'https://t.me/ICPlaza_gl',
    telegramChannel: 'https://t.me/ICPlaza_gl',
    twitter: 'https://mobile.twitter.com/ICPLAZA',
    medium: 'https://mobile.twitter.com/ICPLAZA',
    discord: 'https://discord.com/invite/US8JKVftYp',
    reddit: 'https://www.reddit.com/r/ICPLAZA_Official?utm_medium=android_app&utm_source=share',
    defipulse: 'https://www.defipulse.com',

    analyticLink: process.env.REACT_APP_ENV === 'development' ? 'https://analytics.ic-plaza.org' : 'https://analytics.ic-plaza.org',
};

export const getNftUrl = (chainId: ChainId, contractAddress: string, nftId: string): string => {
    return getScanUrl(chainId) + 'token/' + contractAddress + '?a=' + nftId;
};

import { BigNumber } from 'bignumber.js'
import Web3 from 'web3'
import { Contract } from 'web3-eth-contract'
import { getChain } from '../../../../config/chains'
import { UNIV3_SWAP_ROUTER } from '../../../../config/miscellaneous/uniswapContracts'
import { QUOTER_ADDRESS_LIMIT, SWAP_ADDRESS } from '../../../../config/trade/tradeContracts'
import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted'
import { AddLiquidityRequest, MintRequest } from '../../../../types/abis/iZiSwap/LiquidityManager'
import { QuoterContract, SwapAmountResponse } from '../../../../types/abis/iZiSwap/Quoter'
import { ExactInputRequest } from '../../../../types/abis/UniswapV3/UniswapV3SwapRouter'
import { ChainId } from '../../../../types/mod'
import { getContract, getQuoterContract, getSwapContract } from '../../../../utils/contractFactory'
import { decodeMethodResult, getMulticallContract } from '../../../../utils/contractHelpers'
import { getSwapTokenAddress } from '../../../../utils/tokenMath'
import { BasePathQueryPlugin } from '../aggregator/BaseDexPlugin'
import { getPathQueryPlugins, SwapTag } from '../aggregator/config'
import { PANCAKE_SWAP_ROUTER_ADDRESS } from '../aggregator/pancake/config'
import { CallingProperty, Path, PathQueryCalling, PathQueryResult, SwapDirection } from '../aggregator/utils'
import { getTokenChainPath } from '../funcs'
import { TapResult } from './types'
import { getAmountX, getAmountXNoRound, getAmountY, getAmountYNoRound, getLiquidityXRange, getLiquidityYRange } from '../liquidity/funcs'
import { TAP_PROXY_ADDRESS } from './config'

import pancakeSwapRouterABI from '../../../../config/abi/pancake/swapRouter.json'
import uniswapV3SwapRouterABI from '../../../../config/abi/UniswapV3/UniswapV3SwapRouter.json'
import tapProxyABI from '../../../../config/abi/iZiSwap/tapProxy.json'
import { QueryConfig } from './types'
import { MULTICALL_ADDRESS } from '../../../../config/multicall/multicallContracts'
import { getLiquidityAmountForDeposit } from '../liquidity/maths/liquidityMath'

async function multiQuery(multicall: Contract, calling: string[], targetAddress: string[]): Promise<{successes: boolean[], results: string[]}> {
    const result = await multicall.methods.multicall(targetAddress, calling).call()
    return {
        successes: result.successes,
        results: result.results
    }
}


function ABetterThanB(resultA: PathQueryResult, resultB: PathQueryResult): boolean {
    if (!resultA) {
        return false
    }
    if (!resultB) {
        return true
    }
    return new BigNumber(resultA.amount).gt(resultB.amount)
}


async function _doPathQueryWithSpecific(
    multicall: Contract, 
    callings: PathQueryCalling[],
    callingPluginIdx: number[],
    callingPath: Path[],
    pathQueryPlugins: BasePathQueryPlugin[],
    swapTags: SwapTag[],
    commonAmountIn: string,
    specificPathAmountIn: string,
    specificPathIdx: number,
    batchSize: number,
): Promise<{commonResult: PathQueryResult, specificResult: PathQueryResult, specificRawResult: string}> {

    let commonResult = undefined as unknown as PathQueryResult
    let specificResult = undefined as unknown as PathQueryResult
    let specificRawResult = undefined as unknown as string

    for (let i = 0; i < callings.length; i += batchSize) {
        const end = Math.min(i + batchSize, callings.length)
        const len = end - i
        const batchCallings = callings.slice(i, end)
        const data = batchCallings.map((e)=>e.calling)
        const contracts = batchCallings.map((e)=>e.targetAddress)
        const {successes, results} = await multiQuery(multicall, data, contracts)
        for (let j = 0; j < len; j ++) {
            if (!successes[j]) {
                continue
            }
            const idx = i + j
            const plugin = pathQueryPlugins[callingPluginIdx[idx]]
            const path = callingPath[idx]
            if (idx !== specificPathIdx) {
                const pathQueryResult = plugin.parseCallingResponse(path, SwapDirection.ExactIn, commonAmountIn, results[j])
                pathQueryResult.swapTag = swapTags[callingPluginIdx[idx]]
                if (ABetterThanB(pathQueryResult, commonResult)) {
                    commonResult = pathQueryResult
                }
            } else {
                const pathQueryResult = plugin.parseCallingResponse(path, SwapDirection.ExactIn, specificPathAmountIn, results[j])
                pathQueryResult.swapTag = swapTags[callingPluginIdx[idx]]
                specificResult = pathQueryResult
                specificRawResult = results[j]
            }
        }
    }

    return { commonResult, specificResult, specificRawResult }
}

async function doPathQueryWithSpecific (
    swapTags: SwapTag[],
    pathQueryPlugins: BasePathQueryPlugin[],
    quoterAddress: string,
    quoterContract: Contract,
    multicall: Contract,
    tokenIn: TokenInfoFormatted,
    tokenOut: TokenInfoFormatted,
    feeContractNumber: number,
    commonAmountIn: string,
    specificPathAmountIn: string,
    longBatchSize: number = 20,
    shortBatchSize: number = 20
): Promise<{
    commonResult: PathQueryResult,
    specificResult: PathQueryResult,
    specificPathPoint: number
}> {

    const longCallingPluginIdx = [] as number[]
    const shortCallingPluginIdx = [] as number[]

    const longCalling = [] as PathQueryCalling[]
    const shortCalling = [] as PathQueryCalling[]

    const longCallingPath = [] as Path[]
    const shortCallingPath = [] as Path[]

    let longCallingSpecificIdx = -1;
    let shortCallingSpecificIdx = -1; 

    let commonResult = undefined as unknown as PathQueryResult
    let specificResult = undefined as unknown as PathQueryResult
    let specificPathPoint = 0

    if (commonAmountIn !== '0') {
        for (let i = 0; i < swapTags.length; i ++) {
            const plugin = pathQueryPlugins[i]
            const pathQueryList = plugin.getPathQuery(tokenIn, tokenOut, SwapDirection.ExactIn, commonAmountIn)
            for (const pathQuery of pathQueryList) {
                if (pathQuery.path.tokenChain[0].symbol !== tokenIn.symbol) {
                    continue
                }
                if (pathQuery.path.tokenChain[pathQuery.path.tokenChain.length - 1].symbol !== tokenOut.symbol) {
                    continue
                }
                const isOriginPool = swapTags[i] === SwapTag.iZiSwap && pathQuery.path.tokenChain.length === 2 && pathQuery.path.feeContractNumber[0] === feeContractNumber
                if (isOriginPool) {
                    continue
                }
                if (!pathQuery.pathQueryResult) {
                    const pathQueryCalling = pathQuery.pathQueryCalling as PathQueryCalling
                    if (pathQueryCalling.callingProperty === CallingProperty.Short) {
                        shortCalling.push(pathQueryCalling)
                        shortCallingPluginIdx.push(i)
                        shortCallingPath.push(pathQuery.path)
                    } else if (pathQueryCalling.callingProperty === CallingProperty.Long) {
                        longCalling.push(pathQueryCalling)
                        longCallingPluginIdx.push(i)
                        longCallingPath.push(pathQuery.path)
                    }
                } else {
                    pathQuery.pathQueryResult.swapTag = swapTags[i]
                    if (ABetterThanB(pathQuery.pathQueryResult, commonResult)) {
                        commonResult = pathQuery.pathQueryResult
                    }
                }
            }
        }
    }

    let iZiSwapIdx = -1

    for (let i = 0; i < swapTags.length; i ++) {
        if (swapTags[i] === SwapTag.iZiSwap) {
            iZiSwapIdx = i;
            break;
        }
    }

    if (specificPathAmountIn !== '0' && iZiSwapIdx >= 0) {
        const pathChain = getTokenChainPath([tokenIn, tokenOut], [feeContractNumber])
        const calling = quoterContract.methods.swapAmount(specificPathAmountIn, pathChain).encodeABI()
        const targetAddress = quoterAddress
        const path = {
            tokenChain: [tokenIn, tokenOut],
            feeContractNumber: [feeContractNumber],
            feeRate: [feeContractNumber / 1e6]
        } as Path
        const pathQueryCalling = {
            calling, targetAddress
        } as PathQueryCalling
        if (feeContractNumber === 100) {
            shortCallingSpecificIdx = shortCalling.length
            shortCalling.push(pathQueryCalling)
            shortCallingPluginIdx.push(iZiSwapIdx)
            shortCallingPath.push(path)
        } else {
            longCallingSpecificIdx = longCalling.length
            longCalling.push(pathQueryCalling)
            longCallingPluginIdx.push(iZiSwapIdx)
            longCallingPath.push(path)
        }
    }

    const shortResult = await _doPathQueryWithSpecific(
        multicall, shortCalling, shortCallingPluginIdx, 
        shortCallingPath, pathQueryPlugins, swapTags, commonAmountIn, specificPathAmountIn, shortCallingSpecificIdx, shortBatchSize
    )

    const longResult = await _doPathQueryWithSpecific(
        multicall, longCalling, longCallingPluginIdx, 
        longCallingPath, pathQueryPlugins, swapTags, commonAmountIn, specificPathAmountIn, longCallingSpecificIdx, longBatchSize
    )

    if (ABetterThanB(shortResult.commonResult, commonResult)) {
        commonResult = shortResult.commonResult
    }

    if (ABetterThanB(longResult.commonResult, commonResult)) {
        commonResult = longResult.commonResult
    }

    if (shortResult.specificResult) {
        specificResult = shortResult.specificResult
        const swapAmountRes: SwapAmountResponse = decodeMethodResult(quoterContract as unknown as Contract, 'swapAmount', shortResult.specificRawResult)
        specificPathPoint = Number(swapAmountRes.pointAfterList[0])
    }

    if (longResult.specificResult) {
        specificResult = longResult.specificResult
        const swapAmountRes: SwapAmountResponse = decodeMethodResult(quoterContract as unknown as Contract, 'swapAmount', longResult.specificRawResult)
        specificPathPoint = Number(swapAmountRes.pointAfterList[0])
    }

    return {commonResult, specificResult, specificPathPoint}
}

export const getLiquidityForTap = (
    tokenA: TokenInfoFormatted,
    tokenB: TokenInfoFormatted,
    amountA: BigNumber,
    amountB: BigNumber,
    leftPoint: number,
    rightPoint: number,
    currentPoint: number
): TapResult => {
    const tokenAAddress = getSwapTokenAddress(tokenA).toLowerCase()
    const tokenBAddress = getSwapTokenAddress(tokenB).toLowerCase()
    const xRange = getLiquidityXRange(leftPoint, rightPoint, currentPoint)
    const yRange = getLiquidityYRange(leftPoint, rightPoint, currentPoint)
    const hasX = xRange.leftPoint < xRange.rightPoint
    const hasY = yRange.leftPoint < yRange.rightPoint
    const aIsX = tokenAAddress < tokenBAddress
    const sqrtRate = Math.sqrt(1.0001)
    let liquidityA = '0'
    let liquidityB = '0'
    let liquidity = '0'
    let hasA = false
    let hasB = false
    if (aIsX) {
        hasA = hasX
        hasB = hasY
        const aRange = xRange
        const bRange = yRange
        if (hasA) {
            const sqrtPriceR = Math.sqrt(1.0001 ** aRange.rightPoint)
            liquidityA = amountA.div(getAmountXNoRound(new BigNumber(1), aRange.leftPoint, aRange.rightPoint, sqrtPriceR, sqrtRate)).toFixed(0, 2)
        }
        if (hasB) {
            const sqrtPriceL = Math.sqrt(1.0001 ** bRange.leftPoint)
            const sqrtPriceR = Math.sqrt(1.0001 ** bRange.rightPoint)
            liquidityB = amountB.div(getAmountYNoRound(new BigNumber(1), sqrtPriceL, sqrtPriceR, sqrtRate)).toFixed(0, 2)
        }
    } else {
        hasA = hasY
        hasB = hasX
        const aRange = yRange
        const bRange = xRange
        if (hasA) {
            const sqrtPriceL = Math.sqrt(1.0001 ** aRange.leftPoint)
            const sqrtPriceR = Math.sqrt(1.0001 ** aRange.rightPoint)
            liquidityA = amountA.div(getAmountYNoRound(new BigNumber(1), sqrtPriceL, sqrtPriceR, sqrtRate)).toFixed(0, 2)
        }
        if (hasB) {
            const sqrtPriceR = Math.sqrt(1.0001 ** bRange.rightPoint)
            liquidityB = amountB.div(getAmountXNoRound(new BigNumber(1), bRange.leftPoint, bRange.rightPoint, sqrtPriceR, sqrtRate)).toFixed(0, 2)
        }
    }
    if (!hasA) {
        liquidity = liquidityB
    } else if (!hasB) {
        liquidity = liquidityA
    } else if (new BigNumber(liquidityA).gt(liquidityB)) {
        liquidity = liquidityB
    } else {
        liquidity = liquidityA
    }
    const {amountA: requiredAmountA, amountB: requiredAmountB} = getLiquidityAmountForDeposit(tokenA, tokenB, new BigNumber(liquidity), leftPoint, rightPoint, currentPoint)
    return {
        liquidityA,
        liquidityB,
        liquidity,
        amountA: BigNumber.min(requiredAmountA, amountA).toFixed(0),
        amountB: BigNumber.min(requiredAmountB, amountB).toFixed(0),
        leftPoint,
        rightPoint,
        newPoint: currentPoint,
        hasA,
        hasB
    } as TapResult
}

export const calcA2B = async(
    swapTags: SwapTag[],
    pathQueryPlugins: BasePathQueryPlugin[],
    quoterAddress: string,
    quoter: Contract,
    multicall: Contract,
    tokenA: TokenInfoFormatted,
    tokenB: TokenInfoFormatted,
    amountA: BigNumber,
    amountB: BigNumber,
    feeContractNumber: number,
    leftPoint: number,
    rightPoint: number,
    currentPoint: number,
    commonDeltaAmountA: BigNumber,
    specificDeltaAmountA: BigNumber,
    longBatchSize: number = 20,
    shortBatchSize: number = 20
): Promise<{commonTapResult: TapResult, specificTapResult: TapResult}> => {
    let pathQueryResult = undefined
    const commonDeltaAmountAStr = commonDeltaAmountA.toFixed(0)
    const specificDeltaAmountAStr = specificDeltaAmountA.toFixed(0)
    if (!commonDeltaAmountA.eq(0) || specificDeltaAmountA.eq(0)) {
        pathQueryResult = await doPathQueryWithSpecific(
            swapTags,
            pathQueryPlugins,
            quoterAddress,
            quoter,
            multicall,
            tokenA,
            tokenB,
            feeContractNumber,
            commonDeltaAmountAStr,
            specificDeltaAmountAStr,
            longBatchSize,
            shortBatchSize
        )
    }

    let commonTapResult = undefined
    
    if (commonDeltaAmountAStr === '0') {
        commonTapResult = getLiquidityForTap(tokenA, tokenB, amountA, amountB, leftPoint, rightPoint, currentPoint)
        commonTapResult.swapAmountIn = '0'
        commonTapResult.swapAmountOut = '0'
        commonTapResult.originAmountA = BigNumber.min(amountA, commonTapResult.amountA).toFixed(0)
        commonTapResult.originAmountB = BigNumber.min(amountB, commonTapResult.amountB).toFixed(0)
    } else {
        const newAmountA = amountA.minus(commonDeltaAmountA)
        const deltaAmountB = pathQueryResult?.commonResult?.amount ?? '0'
        const newAmountB = amountB.plus(deltaAmountB)
        commonTapResult = getLiquidityForTap(tokenA, tokenB, newAmountA, newAmountB, leftPoint, rightPoint, currentPoint)
        commonTapResult.pathQueryResult = pathQueryResult?.commonResult
        commonTapResult.swapAmountIn = commonDeltaAmountAStr
        commonTapResult.swapAmountOut = deltaAmountB

        commonTapResult.originAmountA = commonDeltaAmountA.plus(commonTapResult.amountA).toFixed(0)
        if (commonTapResult.hasB) {
            const originAmountB = BigNumber.max(new BigNumber(commonTapResult.amountB).minus(deltaAmountB), new BigNumber(0))
            commonTapResult.originAmountB = originAmountB.toFixed(0)
        } else {
            commonTapResult.originAmountB = '0'
        }
    }

    let specificTapResult = undefined

    if (specificDeltaAmountAStr === '0') {
        specificTapResult = getLiquidityForTap(tokenA, tokenB, amountA, amountB, leftPoint, rightPoint, currentPoint)
        specificTapResult.swapAmountIn = '0'
        specificTapResult.swapAmountOut = '0'
        specificTapResult.originAmountA = BigNumber.min(amountA, specificTapResult.amountA).toFixed(0)
        specificTapResult.originAmountB = BigNumber.min(amountB, specificTapResult.amountB).toFixed(0)
    } else {
        const newAmountA = new BigNumber(amountA).minus(specificDeltaAmountA)
        const deltaAmountB = pathQueryResult?.specificResult?.amount ?? '0'
        const newPoint = pathQueryResult?.specificPathPoint ?? currentPoint
        const newAmountB = new BigNumber(amountB).plus(deltaAmountB)
        specificTapResult = getLiquidityForTap(tokenA, tokenB, newAmountA, newAmountB, leftPoint, rightPoint, newPoint)
        specificTapResult.pathQueryResult = pathQueryResult?.specificResult
        specificTapResult.swapAmountIn = specificDeltaAmountAStr
        specificTapResult.swapAmountOut = deltaAmountB

        specificTapResult.originAmountA = specificDeltaAmountA.plus(specificTapResult.amountA).toFixed(0)
        if (specificTapResult.hasB) {
            const originAmountB = BigNumber.max(new BigNumber(specificTapResult.amountB).minus(deltaAmountB), new BigNumber(0))
            specificTapResult.originAmountB = originAmountB.toFixed(0)
        } else {
            specificTapResult.originAmountB = '0'
        }

    }
    return {commonTapResult, specificTapResult}
}

export const searchA2B = async (
    queryConfig: QueryConfig,
    chainId: ChainId,
    web3: Web3,
    tokenA: TokenInfoFormatted,
    tokenB: TokenInfoFormatted,
    amountA: BigNumber,
    amountB: BigNumber,
    feeContractNumber: number,
    leftPoint: number,
    rightPoint: number,
    currentPoint: number,
): Promise<TapResult> => {
    const pathQueryPlugins = getPathQueryPlugins(queryConfig.swapTags, queryConfig.preQueryResult, queryConfig.pathQueryPluginConfig, chainId, web3)
    const quoterAddress = QUOTER_ADDRESS_LIMIT[chainId]
    const quoterContract = getQuoterContract(chainId, web3, true) as QuoterContract as unknown as Contract
    const multicallAddress = MULTICALL_ADDRESS[chainId]
    const multicallContract = getMulticallContract(multicallAddress, web3)
    const longBatchSize = queryConfig.longBatchSize ?? 20
    const shortBatchSize = queryConfig.shortBatchSize ?? 20

    const maxRate = Math.min(queryConfig.maxRate ?? 0.5, 1)

    const originResult = getLiquidityForTap(tokenA, tokenB, amountA, amountB, leftPoint, rightPoint, currentPoint)
    originResult.swapAmountIn = '0'
    originResult.swapAmountOut = '0'
    originResult.originAmountA = originResult.amountA
    originResult.originAmountB = originResult.amountB
    originResult.inputOriginAmountB = originResult.amountB
    if (!originResult.hasB) {
        // no range space for tokenB and no need to tap some A to B
        return originResult
    }

    let result = originResult

    let commonL = new BigNumber(0)
    let commonR = new BigNumber(amountA).times(0.95)
    let specificL = new BigNumber(0)
    let specificR = new BigNumber(amountA).times(0.95)

    const commonMindelta = commonR.div(1000).toFixed(0, 1)
    let commonNeedSearch = commonL.plus(commonMindelta).lt(commonR)
    let specificNeedSearch = specificL.plus(commonMindelta).lt(specificR)

    let commonMid = new BigNumber('0')
    let specificMid = new BigNumber('0')

    while (commonNeedSearch || specificNeedSearch) {
        if (commonNeedSearch) {
            commonMid = new BigNumber(commonL.plus(commonR).div(2).toFixed(0, 2))
        }
        if (specificNeedSearch) {
            specificMid = new BigNumber(specificL.plus(specificR).div(2).toFixed(0, 2))
        }
        const tapResult = await calcA2B(
            queryConfig.swapTags,
            pathQueryPlugins,
            quoterAddress,
            quoterContract,
            multicallContract,
            tokenA,
            tokenB,
            amountA,
            amountB,
            feeContractNumber,
            leftPoint,
            rightPoint,
            currentPoint,
            commonMid,
            specificMid,
            longBatchSize,
            shortBatchSize
        )
        if (commonNeedSearch) {
            const commonTapResult = tapResult.commonTapResult
            if (!commonTapResult.pathQueryResult || commonTapResult.pathQueryResult.noSufficientLiquidity) {
                // no enough liquidity
                commonR = commonMid
            } else {
                if (new BigNumber(commonTapResult.liquidityA).gt(commonTapResult.liquidityB) || !commonTapResult.hasA) {
                    commonL = commonMid
                } else {
                    commonR = commonMid
                }
                if (new BigNumber(commonTapResult.liquidity).gt(result.liquidity)) {
                    result = commonTapResult
                }
            }
            commonNeedSearch = commonL.plus(commonMindelta).lt(commonR)
        }
        if (specificNeedSearch) {
            const specificTapResult = tapResult.specificTapResult
            if (!specificTapResult.pathQueryResult || specificTapResult.pathQueryResult.noSufficientLiquidity || !specificTapResult.hasB) {
                // no enough liquidity
                specificR = specificMid
            }
            else {
                if (new BigNumber(specificTapResult.liquidityA).gt(specificTapResult.liquidityB) || !specificTapResult.hasA) {
                    specificL = specificMid
                } else {
                    specificR = specificMid
                }
                if (new BigNumber(specificTapResult.liquidity).gt(result.liquidity)) {
                    result = specificTapResult
                }
            }
            specificNeedSearch = specificL.plus(commonMindelta).lt(specificR) 
        }
    }
    if (result.swapAmountIn === '0' || result.swapAmountOut === '0') {
        result = originResult
    }
    result.inputOriginAmountB = amountB.toFixed(0)
    
    return result
}


function getPancakeSwapRouterContract(chainId: ChainId, web3: Web3): Contract {
    const address = PANCAKE_SWAP_ROUTER_ADDRESS[chainId]
    return getContract<Contract>(pancakeSwapRouterABI, address, web3)
}

function getUniswapV3SwapRouterContract(chainId: ChainId, web3: Web3): Contract {
    const address = UNIV3_SWAP_ROUTER[chainId]
    return getContract<Contract>(uniswapV3SwapRouterABI, address, web3)
}

export const getTapMintTransaction = (
    chainId: ChainId, 
    web3: Web3, 
    account: string,
    tokenA: TokenInfoFormatted,
    tokenB: TokenInfoFormatted,
    feeContractNumber: number,
    tapResult: TapResult,
    swapRate: number,
    mintRate: number,
    maxDelay: number,
): {calling: any, options: any} => {
    const pathQueryResult = tapResult.pathQueryResult as PathQueryResult
    const tapSwapTag = pathQueryResult.swapTag

    const tapAmountIn = new BigNumber(tapResult.swapAmountIn)
    const tapAmountOut = new BigNumber(tapResult.swapAmountOut)
    const minAcquired = tapAmountOut.times(swapRate).toFixed(0, 2)

    const deadline = String(Math.floor((new Date()).valueOf() / 1000) + maxDelay * 60)

    let tapSwapContract = undefined as unknown as string
    let tapSwapData = undefined as unknown as string

    const tapProxyAddress = TAP_PROXY_ADDRESS[chainId]
    const tapProxyContract = getContract<Contract>(tapProxyABI, tapProxyAddress, web3) as Contract

    if (tapSwapTag === SwapTag.iZiSwap) {
        // iZiSwap
        tapSwapContract = SWAP_ADDRESS[chainId]
        const iZiSwapRouter = getSwapContract(chainId, web3)
        const swapPathHexString = getTokenChainPath(pathQueryResult.path.tokenChain, pathQueryResult.path.feeContractNumber)

        const swapParams = {
            recipient: tapProxyAddress,
            amount: tapAmountIn.toFixed(0),
            path: swapPathHexString,
            minAcquired: minAcquired,
            deadline
        };
        tapSwapData = iZiSwapRouter?.methods.swapAmount(swapParams).encodeABI() as string
    } else if (tapSwapTag === SwapTag.pancake) {
        // pancake
        tapSwapContract = PANCAKE_SWAP_ROUTER_ADDRESS[chainId]
        const pancakeSwapRouter = getPancakeSwapRouterContract(chainId, web3)
        const addressList = pathQueryResult.path.tokenChain.map((token)=>token.address)
        tapSwapData = pancakeSwapRouter.methods.swapExactTokensForTokens(
            tapAmountIn.toFixed(0), minAcquired, addressList, tapProxyAddress, deadline
        ).encodeABI() as string
    } else {
        // uniswap
        tapSwapContract = UNIV3_SWAP_ROUTER[chainId]
        const uniswapSwapRouter = getUniswapV3SwapRouterContract(chainId, web3)
        const swapPathHexString = getTokenChainPath(pathQueryResult.path.tokenChain, pathQueryResult.path.feeContractNumber)
        const swapParams = {
            path: swapPathHexString,
            recipient: tapProxyAddress,
            deadline,
            amountIn: tapAmountIn.toFixed(0),
            amountOutMinimum: minAcquired
        } as ExactInputRequest
        tapSwapData = uniswapSwapRouter.methods.exactInput(swapParams).encodeABI() as string
    }

    let mintAmountA = tapResult.amountA
    const deltaB = BigNumber.min(
        BigNumber.max(0, new BigNumber(tapResult.amountB).minus(minAcquired).minus(tapResult.originAmountB)),
        new BigNumber(tapResult.inputOriginAmountB).minus(tapResult.originAmountB)
    )
    const mintAmountB = BigNumber.min(tapResult.amountB, new BigNumber(tapResult.originAmountB).plus(minAcquired).plus(deltaB)).toFixed(0, 2)
    if (tapResult.amountB !== '0') {
        mintAmountA = new BigNumber(mintAmountA).div(tapResult.amountB).times(mintAmountB).toFixed(0, 2)
    }

    const finalOriginAmountB = deltaB.plus(tapResult.originAmountB).toFixed(0, 2)

    const mintAmountAMin = new BigNumber(mintAmountA).times(mintRate).toFixed(0, 2)
    const mintAmountBMin = new BigNumber(mintAmountB).times(mintRate).toFixed(0, 2)

    const tokenAAddress = getSwapTokenAddress(tokenA)
    const tokenBAddress = getSwapTokenAddress(tokenB)
    const tapInputIsX = tokenAAddress.toLowerCase() < tokenBAddress.toLowerCase()
    const mintParam = {
        miner: account,
        deadline,
        fee: feeContractNumber,
        pl: tapResult.leftPoint,
        pr: tapResult.rightPoint
    } as MintRequest
    mintParam.miner = account
    if (tapInputIsX) {
        mintParam.tokenX = tokenAAddress
        mintParam.tokenY = tokenBAddress
        mintParam.xLim = mintAmountA
        mintParam.amountXMin = mintAmountAMin
        mintParam.yLim = mintAmountB
        mintParam.amountYMin = mintAmountBMin
    } else {
        mintParam.tokenX = tokenBAddress
        mintParam.tokenY = tokenAAddress
        mintParam.xLim = mintAmountB
        mintParam.amountXMin = mintAmountBMin
        mintParam.yLim = mintAmountA
        mintParam.amountYMin = mintAmountAMin
    }

    const tokenX = tapInputIsX ? tokenAAddress : tokenBAddress
    const tokenY = tapInputIsX ? tokenBAddress : tokenAAddress

    const amountX = tapInputIsX ? tapResult.originAmountA : finalOriginAmountB
    const amountY = tapInputIsX ? finalOriginAmountB : tapResult.originAmountA

    const calling = tapProxyContract.methods.tapAndMint(
        tokenX, amountX, tokenY, amountY, 
        tapSwapContract, tapSwapData,
        tapInputIsX, mintParam
    )

    const chainToken = getChain(chainId)?.token
    const chainTokenAddress = chainToken?.address?.toLowerCase() as string ?? ''
    let options = {}
    
    if (tokenX.toLowerCase() === chainTokenAddress) {
        options = {value: amountX}
    } else if (tokenY.toLowerCase() === chainTokenAddress) {
        options = {value: amountY}
    }

    return {calling, options}

}


export const getTapAddTransaction = (
    chainId: ChainId, 
    web3: Web3, 
    tokenId: string,
    tokenA: TokenInfoFormatted,
    tokenB: TokenInfoFormatted,
    tapResult: TapResult,
    swapRate: number,
    mintRate: number,
    maxDelay: number,
): {calling: any, options: any} => {
    const pathQueryResult = tapResult.pathQueryResult as PathQueryResult
    const tapSwapTag = pathQueryResult.swapTag

    const tapAmountIn = new BigNumber(tapResult.swapAmountIn)
    const tapAmountOut = new BigNumber(tapResult.swapAmountOut)
    const minAcquired = tapAmountOut.times(swapRate).toFixed(0, 2)

    const deadline = String(Math.floor((new Date()).valueOf() / 1000) + maxDelay * 60)

    let tapSwapContract = undefined as unknown as string
    let tapSwapData = undefined as unknown as string

    const tapProxyAddress = TAP_PROXY_ADDRESS[chainId]
    const tapProxyContract = getContract<Contract>(tapProxyABI, tapProxyAddress, web3) as Contract
    if (tapSwapTag === SwapTag.iZiSwap) {
        // iZiSwap
        tapSwapContract = SWAP_ADDRESS[chainId]
        const iZiSwapRouter = getSwapContract(chainId, web3)
        const swapPathHexString = getTokenChainPath(pathQueryResult.path.tokenChain, pathQueryResult.path.feeContractNumber)

        const swapParams = {
            recipient: tapProxyAddress,
            amount: tapAmountIn.toFixed(0),
            path: swapPathHexString,
            minAcquired: minAcquired,
            deadline
        };
        tapSwapData = iZiSwapRouter?.methods.swapAmount(swapParams).encodeABI() as string
    } else if (tapSwapTag === SwapTag.pancake) {
        // pancake
        tapSwapContract = PANCAKE_SWAP_ROUTER_ADDRESS[chainId]
        const pancakeSwapRouter = getPancakeSwapRouterContract(chainId, web3)
        const addressList = pathQueryResult.path.tokenChain.map((token)=>token.address)
        tapSwapData = pancakeSwapRouter.methods.swapExactTokensForTokens(
            tapAmountIn.toFixed(0), minAcquired, addressList, tapProxyAddress, deadline
        ).encodeABI() as string
    } else {
        // uniswap
        tapSwapContract = UNIV3_SWAP_ROUTER[chainId]
        const uniswapSwapRouter = getUniswapV3SwapRouterContract(chainId, web3)
        const swapPathHexString = getTokenChainPath(pathQueryResult.path.tokenChain, pathQueryResult.path.feeContractNumber)
        const swapParams = {
            path: swapPathHexString,
            recipient: tapProxyAddress,
            deadline,
            amountIn: tapAmountIn.toFixed(0),
            amountOutMinimum: minAcquired
        } as ExactInputRequest
        tapSwapData = uniswapSwapRouter.methods.exactInput(swapParams).encodeABI() as string
    }
    let mintAmountA = tapResult.amountA
    const deltaB = BigNumber.min(
        BigNumber.max(0, new BigNumber(tapResult.amountB).minus(minAcquired)),
        new BigNumber(tapResult.inputOriginAmountB).minus(tapResult.originAmountB)
    )
    const mintAmountB = BigNumber.min(tapResult.amountB, new BigNumber(tapResult.originAmountB).plus(minAcquired).plus(deltaB)).toFixed(0, 2)
    if (tapResult.amountB !== '0') {
        mintAmountA = new BigNumber(mintAmountA).div(tapResult.amountB).times(mintAmountB).toFixed(0, 2)
    }

    const finalOriginAmountB = deltaB.plus(tapResult.originAmountB).toFixed(0, 2)

    const mintAmountAMin = new BigNumber(mintAmountA).times(mintRate).toFixed(0, 2)
    const mintAmountBMin = new BigNumber(mintAmountB).times(mintRate).toFixed(0, 2)

    const tokenAAddress = getSwapTokenAddress(tokenA)
    const tokenBAddress = getSwapTokenAddress(tokenB)
    const tapInputIsX = tokenAAddress.toLowerCase() < tokenBAddress.toLowerCase()
    const addLiquidityParam = {
        lid: tokenId,
        deadline,
    } as AddLiquidityRequest
    if (tapInputIsX) {
        addLiquidityParam.xLim = mintAmountA
        addLiquidityParam.amountXMin = mintAmountAMin
        addLiquidityParam.yLim = mintAmountB
        addLiquidityParam.amountYMin = mintAmountBMin
    } else {
        addLiquidityParam.xLim = mintAmountB
        addLiquidityParam.amountXMin = mintAmountBMin
        addLiquidityParam.yLim = mintAmountA
        addLiquidityParam.amountYMin = mintAmountAMin
    }

    const tokenX = tapInputIsX ? tokenAAddress : tokenBAddress
    const tokenY = tapInputIsX ? tokenBAddress : tokenAAddress

    const amountX = tapInputIsX ? tapResult.originAmountA : finalOriginAmountB
    const amountY = tapInputIsX ? finalOriginAmountB : tapResult.originAmountA

    const calling = tapProxyContract.methods.tapAndInc(
        tokenX, amountX, tokenY, amountY, 
        tapSwapContract, tapSwapData,
        tapInputIsX, addLiquidityParam
    )

    const chainToken = getChain(chainId)?.token
    const chainTokenAddress = chainToken?.address?.toLowerCase() as string ?? ''
    let options = {}
    
    if (tokenX.toLowerCase() === chainTokenAddress) {
        options = {value: amountX}
    } else if (tokenY.toLowerCase() === chainTokenAddress) {
        options = {value: amountY}
    }

    return {calling, options}

}
import { links } from '../links';

export const config = {
    pages: [
        {
            name: 'Swap',
            img: 'Trade.png',
            address: '/trade/swap',
            marker: 'new',
            children: [],
        },
        {
            name: 'Pro',
            img: 'Farm.svg',
            address: '/trade/pro',
            children: [],
        },
        {
            name: 'Liquidity ',
            img: 'VeiZi.svg',
            address: '/trade/liquidity',
            children: [],
        },
        {
            name: 'Analytics',
            img: 'More.svg',
            address: links.analyticLink,
            children: [],
        },
    ],
} as { pages: PageConfig[] };

import { useWeb3React } from '@web3-react/core';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Web3 from 'web3';
import { provider } from 'web3-core';
import chains, { BaseChain, getChain } from '../config/chains';
import { RootState } from '../state/store';
import { ChainId } from '../types/mod';
import { HttpProviderOptions } from 'web3-core-helpers';

import { particle } from '../utils/particle';
import { ParticleProvider } from '@particle-network/provider';

export interface Web3WithOfflineChainData {
    web3: Web3;
    account?: string | null;
    chainId: ChainId;
    walletChainId?: ChainId;
    chainName: string;
    chainIcon: string;
    provider: provider;
}

const ONLINE_CONTEXT = {} as { web3?: Web3; provider?: provider };

const OFFLINE_CONTEXT: { [index: number]: Web3 } = {};

const getWeb3FromContext = (library: provider, offlineChainId: ChainId): Web3 => {
    if (library) {
        if (ONLINE_CONTEXT.web3 !== undefined && ONLINE_CONTEXT.provider === library) {
            return ONLINE_CONTEXT.web3;
        }
        console.log('new web3 by provider: ', library);
        ONLINE_CONTEXT.web3 = new Web3(library);
        ONLINE_CONTEXT.provider = library;
        // print revert reason
        ONLINE_CONTEXT.web3.eth.handleRevert = false;
        return ONLINE_CONTEXT.web3;
    } else {
        if (!(offlineChainId in OFFLINE_CONTEXT)) {
            console.log('new offline provider for chain:', offlineChainId);
            const httpProvider = new Web3.providers.HttpProvider(getChain(offlineChainId)!.rpcUrl, {
                timeout: 10000,
            } as HttpProviderOptions);
            OFFLINE_CONTEXT[offlineChainId] = new Web3(httpProvider);
        }
        return OFFLINE_CONTEXT[offlineChainId];
    }
};

const PARTICLE_CONTEXT: { [index: number]: ParticleProvider } = {};
const PARTICLE_WEB3_CONTEXT: { [index: number]: Web3 } = {};

const getPraticleWeb3 = (chainId: any): [ParticleProvider, Web3] => {
    if (!PARTICLE_WEB3_CONTEXT[chainId]) {
        const provider = new ParticleProvider(particle.auth);
        PARTICLE_CONTEXT[chainId] = provider;
        PARTICLE_WEB3_CONTEXT[chainId] = new Web3(provider as any);
    }
    return [PARTICLE_CONTEXT[chainId], PARTICLE_WEB3_CONTEXT[chainId]];
};

export const useWeb3WithDefault = (): Web3WithOfflineChainData => {
    const { chainId: walletChainId, account, library } = useWeb3React();
    const { account: accountModel } = useSelector((state: RootState) => state);
    const chainId = walletChainId ?? accountModel.offlineChainId;

    const refEth = useRef(library);
    const [web3, setWeb3] = useState(getWeb3FromContext(library, chainId));
    const [address, setAddress] = useState('');
    const [particleChainId, setParticleChainId] = useState(56);

    useEffect(() => {
        if (library !== refEth.current || !library) {
            setWeb3(getWeb3FromContext(library, chainId));
            refEth.current = library;
        }
    }, [library, chainId]);

    if (particle.auth.isLogin()) {
        let particleProvider: ParticleProvider;
        let particleWeb3: Web3;

        [particleProvider, particleWeb3] = getPraticleWeb3(particleChainId);

        if (particleWeb3) {
            particleWeb3.eth.getAccounts((error, accounts) => {
                if (error) throw error;
                setAddress(accounts[0]);
            });
            particleWeb3.eth.getChainId((error, chainId) => {
                if (error) throw error;
                setParticleChainId(chainId);
            });
        }
        const chain = chains.all.find((e) => {
            return e.id === particleChainId;
        }) as BaseChain;

        // const provider = particleProvider;

        return {
            web3: particleWeb3,
            account: address,
            chainId: particleChainId,
            walletChainId: particleChainId,
            chainName: chain.name,
            chainIcon: chain.icon,
            provider: particleProvider as any,
        };
    }

    // TODO use map
    const chain = chains.all.find((e) => {
        return e.id === chainId;
    }) as BaseChain;

    return {
        web3,
        account: account,
        chainId: chainId,
        walletChainId: walletChainId,
        chainName: chain.name,
        chainIcon: chain.icon,
        provider: library,
    };
};

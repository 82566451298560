import { HStack, useColorMode, Image, Text, BoxProps } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/store';
import { noto_t1_bold, noto_t3 } from '../../../../../style';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { formatNumber } from '../../../../../utils/tokenMath';
import { trades } from '../OrderBook';

type CurrentPriceBlockProps = {
    trades: trades[];
    realPrice: number | false | undefined;
} & BoxProps;

const CurrentPriceBlock: React.FC<CurrentPriceBlockProps> = (props) => {
    const { trades, realPrice, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { token } = useSelector((state: RootState) => state);
    const { pro } = useSelector((state: RootState) => state);

    return (
        <HStack w="100%" h="40px" bg={colorTheme('#EEEEEE', '#332D43')} borderRadius="2px" px="10px" {...rest}>
            <HStack spacing="4px">
                <Image
                    boxSize="14px"
                    src={
                        trades.length > 0 && trades[0].actionType === 'buy'
                            ? colorTheme(
                                  process.env.PUBLIC_URL + '/assets/pro/orderBook/greenArrowUp.svg',
                                  process.env.PUBLIC_URL + '/assets/pro/orderBook/darkGreenArrowUp.svg'
                              )
                            : process.env.PUBLIC_URL + '/assets/pro/orderBook/redArrowDown.svg'
                    }
                    fallbackSrc={
                        trades.length > 0 && trades[0].actionType === 'buy'
                            ? colorTheme(
                                  process.env.PUBLIC_URL + '/assets/pro/orderBook/greenArrowUp.svg',
                                  process.env.PUBLIC_URL + '/assets/pro/orderBook/darkGreenArrowUp.svg'
                              )
                            : process.env.PUBLIC_URL + '/assets/pro/orderBook/redArrowDown.svg'
                    }
                ></Image>
                <Text
                    className={noto_t1_bold}
                    color={
                        trades.length > 0 && trades[0].actionType === 'buy'
                            ? colorTheme('#12931F', '#47C87A')
                            : colorTheme('#CB1414', '#EB3537')
                    }
                >
                    {realPrice && formatNumber(realPrice, 2, 2, true)}
                </Text>
            </HStack>
            <HStack ml="auto !important">
                <Text className={noto_t3} color={colorTheme('#332D43', 'linear-gradient(90deg, #F1F1F1 -6.17%, #F2F2F2 102.06%)')}>
                    ≈{realPrice && formatNumber(token.tokenPriceMap[pro.tokenY.symbol] * realPrice, 2, 2, true)} USD
                </Text>
            </HStack>
        </HStack>
    );
};

export default CurrentPriceBlock;

import React from 'react';
import { Text, useColorMode, BoxProps, Flex, Center, Image } from '@chakra-ui/react';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { i_text_copy, i_text_copy_bold } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { formatNumber } from '../../../../utils/tokenMath';
import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { useTranslation } from 'react-i18next';

type FeeGainsProps = {
    data: {
        remainTokenX: string | number | null;
        remainTokenY: string | number | null;
        tokenX: TokenInfoFormatted;
        tokenY: TokenInfoFormatted;
    };
} & {
    onCollect: () => void;
    nftNeedApproveForBox: boolean;
    onApproveBox: () => void;
} & BoxProps;

export const FeeGains: React.FC<FeeGainsProps> = ({
    data: { remainTokenX, remainTokenY, tokenX, tokenY },
    onCollect,
    nftNeedApproveForBox,
    onApproveBox,
    ...rest
}) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();

    return (
        <Flex w={{ base: 'unset', sm: '280px' }} h="68px" bg={colorTheme('#F9F9FB', 'tertiary.700')} direction="row" {...rest}>
            <Flex flex={30}>
                <Text pl="12px" pt="14px" className={i_text_copy}>
                    {t('Fee Gains')}
                </Text>
            </Flex>

            <Flex flex={35}>
                <Flex pt="12px" direction="column" alignItems="end" flex={40} mt="-1px !important">
                    <Image w="16px" h="16px" borderRadius="4px" src={tokenX.icon} />

                    <Image w="16px" h="16px" borderRadius="4px" src={tokenY.icon} mt="9px !important" />
                </Flex>

                <Flex pt="12px" direction="column" alignItems="start" flex={60}>
                    <Text w="100%" className={i_text_copy_bold} textAlign="left" ml="3px">
                        {formatNumber(remainTokenX ? Number(remainTokenX) : 0, 2, 4)}
                    </Text>

                    <Text w="100%" className={i_text_copy_bold} textAlign="left" mt="10px" ml="3px">
                        {formatNumber(remainTokenY ? Number(remainTokenY) : 0, 2, 4)}
                    </Text>
                </Flex>
            </Flex>

            <Center flex={35}>
                {!nftNeedApproveForBox && (
                    <CustomButton
                        disabled={!(Number(remainTokenX) || Number(remainTokenY))}
                        onClick={() => {
                            onCollect();
                        }}
                        variant="tertiary"
                        text={t('Collect')}
                        h="30px"
                        w="70px"
                        fontClass={i_text_copy_bold}
                    />
                )}
                {nftNeedApproveForBox && (
                    <CustomButton
                        disabled={!(Number(remainTokenX) || Number(remainTokenY))}
                        onClick={() => {
                            onApproveBox();
                        }}
                        variant="orange"
                        text={t('Approve')}
                        h="30px"
                        w="70px"
                        fontClass={i_text_copy_bold}
                    />
                )}
            </Center>
        </Flex>
    );
};

import { Box } from '@chakra-ui/layout';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { MdAddChart } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Button, useColorMode, Icon, Text, HStack, Center } from '@chakra-ui/react';

import JumpButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/JumpButton/JumpButton';
import { Heading } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';

import SwapForm from './SwapForm';
import { i_h3 } from '../../../style';
import Tabs from '../components/Tabs';
import AdvanceSwap from './AdvanceSwap';
import SwapSetting from './components/SwapSetting';
import useIsMobile from '../../../hooks/useIsMobile';
import PageLayout from '../../../components/layout/PageLayout';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { RootDispatch, RootState } from '../../../state/store';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import ChainNotValidBlockWrapper from '../../components/ChainNotValidBlock';
import { getColorThemeSelector } from '../../../utils/funcs';
import NetworkSelect from '../../../components/Select/NetworkSelect/NetworkSelect';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { i_text_copy } from '../../../iZUMi-UI-toolkit/src/style';
import useLayoutType from '../../../hooks/useLayoutType';
import { LayoutStateEnum } from '../../../state/models/pageLayout/pageLayout';

const Swap: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { tradeMode, pro } = useSelector((state: RootState) => state);

    const isSimpleMode = tradeMode.isSimpleMode;
    const setIsSimpleMode = useCallback(
        (e: boolean) => {
            dispatch.tradeMode.setIsSimpleMode(e);
        },
        [dispatch]
    );

    const location = useLocation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const oneLineMode = useBreakpointValue({ base: false, xl: true })!;
    const layoutType = useLayoutType();

    useEffect(() => {
        const locationState = location.state as any;
        if (locationState) {
            setIsSimpleMode(false);
        }
        document.title = t('Exchange') + ' | ICPLAZA';
    }, [location.state, setIsSimpleMode, t]);

    const {
        tradeSwap: { swapForm },
    } = useSelector((state: RootState) => state);

    const tabValue = 'Swap';
    const handleChangeTab = (value: string) => {
        if (value === 'Limit Order') {
            history.push('/trade/limit');
        }
    };
    const isMobile = useIsMobile();

    return isMobile ? (
        <PageLayout
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Center pt="7px" w="100%">
                            <SwapForm type="mobile" />
                        </Center>
                    }
                    app="trade"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={
                        <HStack spacing="20px">
                            <Heading level="3" fontSize={oneLineMode ? '24px' : '20px'}>
                                {t('Exchange')}
                            </Heading>
                            <Box h="60%" w="3px" bg={colorTheme('tertiary.200', 'tertiary.200')} />
                            <Text
                                className={i_h3}
                                fontSize={oneLineMode ? '23px' : '19px'}
                                color={colorTheme('secondary.600', 'secondary.300')}
                            >
                                {t('Swap')}
                            </Text>
                            <CustomButton
                                w="115px"
                                h="25px"
                                className={i_text_copy}
                                fontWeight="600"
                                bg="linear-gradient(93.67deg, #5685FF -21.64%, #C549FF 138.94%);"
                                color="#FFFFFF"
                                borderRadius="3px"
                                onClick={() => {
                                    if (layoutType === LayoutStateEnum.sidebar) {
                                        dispatch.pageLayout.setIsInAnimation(true);
                                        setTimeout(() => {
                                            dispatch.pageLayout.setIsInAnimation(false);
                                        }, 500);
                                    }
                                    history.push('/trade/pro/');
                                }}
                                text="Pro Version"
                                leftIcon={'/assets/swap/extraLink.svg'}
                                ml={{ base: '8px !important', lg: '14px !important' }}
                            ></CustomButton>
                        </HStack>
                    }
                    subtitle={t('Swap tokens in an instant.')}
                    element={
                        <HStack spacing="10px">
                            <SwapSetting
                                w="34px"
                                h="34px"
                                exclusiveLiquidity={swapForm.exclusiveLiquidity}
                                slippage={swapForm.slippagePercent}
                                maxDelay={swapForm.maxDelay}
                                quoterType={swapForm.quoterType}
                                handleSetSlippage={dispatch.tradeSwap.setSwapFormSlippagePercent}
                                handleSetMaxDelay={dispatch.tradeSwap.setSwapFormMaxDelay}
                                handleSetQuoterType={dispatch.tradeSwap.setSwapFormQuoterType}
                                handleSetExclusive={dispatch.tradeSwap.setSwapFormExclusiveLiquidity}
                            />
                            <Button
                                w="34px"
                                h="34px"
                                mr="10px"
                                borderRadius="3px"
                                bgColor={colorTheme('#EBEAEC', '#52466A')}
                                color={colorTheme('#52466A', '#EBEAEC')}
                                fontSize="14px"
                                onClick={() => setIsSimpleMode(!isSimpleMode)}
                                p="7px"
                            >
                                <Icon as={MdAddChart} boxSize="20px" />
                            </Button>
                            <JumpButton w="150px" h="40px" text={t('Manage Liquidity')} target="/trade/liquidity" mr="10px" />
                            <NetworkSelect />
                        </HStack>
                    }
                />
            }
            body={
                isSimpleMode ? (
                    <ChainNotValidBlockWrapper
                        content={
                            <Center pt="20px" w="100%">
                                <Tabs
                                    variant={'simple'}
                                    list={[
                                        {
                                            label: t('Swap'),
                                            value: 'Swap',
                                            component: <SwapForm />,
                                        },
                                        {
                                            label: t('Limit Order'),
                                            value: 'Limit Order',
                                            component: <Box>Limit Order Component </Box>,
                                        },
                                    ]}
                                    value={tabValue}
                                    handleClick={handleChangeTab}
                                />
                            </Center>
                        }
                        app="trade"
                    />
                ) : (
                    <ChainNotValidBlockWrapper content={<AdvanceSwap tabValue="Swap"></AdvanceSwap>} app="trade" />
                )
            }
        />
    );
};

export default Swap;

import { Button, Menu, MenuButton, MenuList, Image, Box, Flex, useDisclosure, ChakraProps, BoxProps, useColorMode } from '@chakra-ui/react';
import { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { a_display } from '../../../iZUMi-UI-toolkit/src/style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { config } from '../../../config/sidebar/config';

type DropDownButtonProps = {
    page: any;
    variant?: string;
    rightIcon?: any;
    buttonProps?: ChakraProps;
    menuListProps?: ChakraProps;
    index?: number;
    setBtHoverId?: any;
} & BoxProps;
export const DropDownButton: React.FC<DropDownButtonProps> = (props) => {
    const { page, rightIcon, buttonProps, menuListProps, index, setBtHoverId, ...rest } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const history = useHistory();
    const [hoverId, setHoverId] = useState(-1);
    const location = useLocation();
    const currentPath = location.pathname;
    const selected = page.address === currentPath ? true : false;

    return page.children.length === 0 ? (
        <CustomButton
            h={{ base: '62px', xl: '40px' }}
            text={page.name}
            bg="inherit"
            fontFamily="Montserrat-Medium"
            fontSize="13px"
            color={selected ? colorTheme('#4F20B3', '#7F4AFE') : '#9388AD'}
            px="16px"
            borderRadius="7px"
            justifyContent={{ base: 'left', xl: 'center' }}
            _hover={{ color: colorTheme('#4F20B3', '#7F4AFE') }}
            _active={{ color: colorTheme('#4F20B3', '#7F4AFE') }}
            onClick={() => {
                if (page.address && page.address.startsWith('http')) {
                    window.open(page.address);
                } else {
                    history.push(page.address);
                }
            }}
        ></CustomButton>
    ) : (
        <Menu isOpen={isOpen} {...rest}>
            <MenuButton
                as={Button}
                rightIcon={rightIcon}
                h="100%"
                bg="inherit"
                className={a_display}
                fontSize="18px !important"
                onMouseEnter={onOpen}
                onMouseLeave={() => {
                    setBtHoverId(-1);
                    onClose();
                }}
                onMouseOver={() => {
                    setBtHoverId(index);
                }}
                _hover={{ background: '#7D68E6' }}
                _active={{ background: '#7D68E6' }}
                pr={{ base: '10px', lg: '20px', xl: '44px' }}
                color={selected ? colorTheme('#4F20B3', '#7F4AFE') : '#9388AD'}
                {...buttonProps}
            >
                {page.name}
            </MenuButton>
            <MenuList
                minW={'0px'}
                bg="rgba(20, 2, 51);"
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                borderWidth="0px"
                transform="translate(0px, -8px) !important"
                {...menuListProps}
            >
                {page.children.map((child: any, index: number) => {
                    const tempImg = colorTheme('/assets/header/' + child.icon, '/assets/header/dark' + child.icon);
                    const hoverImg = colorTheme('/assets/header/Hover' + child.icon, '/assets/header/Hoverdark' + child.icon);
                    const img = hoverId === index ? hoverImg : tempImg;
                    return child.address?.startsWith('http') ? (
                        <Link key={child.name + child.address} target="_blank" to={{ pathname: child.address }}>
                            <Box
                                display="block"
                                fontFamily="Montserrat-Medium"
                                fontSize="13px"
                                pt="13px"
                                mr="34px"
                                pl="27px"
                                pb="11px"
                                target="_blank"
                                color="tertiary.50"
                                onMouseOver={() => {
                                    setHoverId(index);
                                }}
                                onMouseLeave={() => {
                                    setHoverId(-1);
                                }}
                                {...menuListProps}
                            >
                                <Flex>
                                    <Image boxSize={'20px'} mr="14px" src={img} fallbackSrc={img}></Image>

                                    {child.name}
                                </Flex>
                                {index !== page.children.length - 1 && (
                                    <Image
                                        w="100%"
                                        src={colorTheme('/assets/header/purpleLine.svg', '/assets/header/grayLine.svg')}
                                        pt="13px"
                                    ></Image>
                                )}
                            </Box>
                        </Link>
                    ) : (
                        <Link to={child.address as string} key={child.name + child.address}>
                            <Box
                                maxW="172px"
                                display="block"
                                fontSize="13px"
                                fontFamily="Montserrat-Medium"
                                pt="13px"
                                mr="34px"
                                pl="27px"
                                pb="11px"
                                target="_blank"
                                color="tertiary.50"
                                whiteSpace="nowrap"
                                onMouseOver={() => {
                                    setHoverId(index);
                                }}
                                onMouseLeave={() => {
                                    setHoverId(-1);
                                }}
                                {...menuListProps}
                            >
                                <Flex fontFamily={'Montserrat-Medium'} fontSize="13px" lineHeight="20px">
                                    <Image boxSize={'20px'} mr="14px" src={img} fallbackSrc={img}></Image>
                                    {child.name}
                                </Flex>
                                {index !== page.children.length - 1 && (
                                    <Image
                                        w="100%"
                                        src={colorTheme('/assets/header/purpleLine.svg', '/assets/header/grayLine.svg')}
                                        pt="13px"
                                    ></Image>
                                )}
                            </Box>
                        </Link>
                    );
                })}
            </MenuList>
        </Menu>
    );
};

import { Box, HStack, useColorMode } from '@chakra-ui/react';

import { getColorThemeSelector } from '../../../utils/funcs';
import WalletButton from '../../../components/Buttons/WalletButton/WalletButton';
import HomeJumpButton from '../../../components/Buttons/HomeJumpButton/HomeJumpButton';

export const MobileHeader: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <>
            <HStack w="100%" h="67px" direction="row" px="17px">
                <HomeJumpButton />

                <HStack ml="auto !important" spacing="10px">
                    <WalletButton />
                </HStack>
            </HStack>
            <Box
                w="100%"
                h="1px"
                bg={colorTheme(
                    '#F5EAFB',
                    'linear-gradient(270deg, rgba(60, 38, 81, 0.57) 3.17%, #453351 47.6%, rgba(65, 33, 85, 0.57) 101.54%)'
                )}
            ></Box>
        </>
    );
};

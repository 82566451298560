import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import MediaButton from '../../../iZUMi-UI-toolkit/src/components/Sidebar/MediaButton/MediaButton';
import TelegramIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/TelegramIcon/TelegramIcon';
import TwitterIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/TwitterIcon/TwitterIcon';
import DiscordIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/DiscordIcon/DiscordIcon';
import RedditIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/RedditIcon/RedditIcon';
import { links } from '../../../config/links';

const MediaList: React.FC = () => {
    return (
        <Flex w="184px" direction="row" p="0" mb="17px" alignSelf="center">
            <Box mr={{ base: '15px', sm: '8px' }}>
                <MediaButton Icon={TelegramIcon} link={links.telegram} />
            </Box>
            <Box mr={{ base: '15px', sm: '8px' }}>
                <MediaButton Icon={TwitterIcon} link={links.twitter} />
            </Box>
            <Box mr={{ base: '15px', sm: '8px' }}>
                <MediaButton Icon={DiscordIcon} link={links.discord} />
            </Box>
            <Box mr={{ base: '15px', sm: '8px' }}>
                <MediaButton Icon={RedditIcon} link={links.reddit} />
            </Box>
        </Flex>
    );
};

export default MediaList;

import { ChainId } from "../../../../types/mod";

export const TAP_PROXY_ADDRESS = {
    [ChainId.BSCTestnet]: '0x2e6195eD946064aC6aAD70847788891F29AB62C9',
    [ChainId.BSC]: '0xF7d38F63aBFD6d27b293733a6553303Cf3933778',
    [ChainId.Aurora]: '0x93E94ef7D2d735fF21C302c765d8A77C1955A311',
    [ChainId.Arbitrum]: '0xe317266a34B9C35285d4050ad784d5190467F489',
    [ChainId.Matic]: '0x7d2aCf0F34958597910Db1D057bCb4908aC37Db1',
    [ChainId.MantleTest]: '0x551197e6350936976DfFB66B2c3bb15DDB723250',
    [ChainId.ScrollTestL2]: '0xD67EAB117504baAF5c24873f7a4c37d9290253E8',
    [ChainId.ZkSyncAlphaTest]: '0x60e3aF76F8C91105549daDa1B0Cc0B5Be9464852',
    [ChainId.Icplaza]: '0xFb18f95bf1C52c8B734486f808864FC19E572641',
} as Record<ChainId, string>
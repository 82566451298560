import { Image, Flex, Text, BoxProps } from '@chakra-ui/react';
import { i_text_copy_bold } from '../../style';
import Card from '../../iZUMi-UI-toolkit/src/components/Card/Card';
import { getColorThemeSelector } from '../../utils/funcs';
import { useColorMode } from '@chakra-ui/color-mode';
import { TokenInfoFormatted } from '../../hooks/useTokenListFormatted';

type DirectionProps = {
    entry: {
        tokenX: TokenInfoFormatted;
        tokenY: TokenInfoFormatted;
        isSellTokenX: boolean;
    };
    variant?: string;
} & BoxProps;

export const Direction: React.FC<DirectionProps> = ({ entry, ...rest }) => {
    const { tokenX, tokenY, isSellTokenX } = entry;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <Card key="CoinIcons" position="relative" w="180px" h="36px" flexShrink={0} variant="light" {...rest}>
            <Flex direction="row" position="absolute" top="9px" left="10px" alignItems="center">
                <Image borderRadius="15px" h="15px" w="15px" flexShrink={0} src={(isSellTokenX ? tokenX : tokenY)?.icon} />
                <Text className={i_text_copy_bold} ml="6px">
                    {isSellTokenX ? tokenX.symbol : tokenY.symbol}
                </Text>
            </Flex>

            <Image
                w="27px"
                position="absolute"
                top="13px"
                left="78px"
                src={process.env.PUBLIC_URL + colorTheme('/assets/trade/lightArrow.svg', '/assets/trade/darkArrow.svg')}
            />

            <Flex direction="row" position="absolute" top="9px" left="115px" alignItems="center">
                <Image borderRadius="15px" h="15px" w="15px" flexShrink={0} src={(isSellTokenX ? tokenY : tokenX)?.icon} />
                <Text className={i_text_copy_bold} ml="6px">
                    {isSellTokenX ? tokenY.symbol : tokenX.symbol}
                </Text>
            </Flex>
        </Card>
    );
};

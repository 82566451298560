import { Models } from '@rematch/core';
import { block } from './block/block';
import { wallet } from './wallet/wallet';
import { farmFixRange } from './farm/UniswapV3/fixRange/farmFixRange';
import { farmFixRangeiZi } from './farm/iZiSwap/fixRange/farmFixRange';
import { farmFixRangeUniLiquidity } from './farm/UniswapV3/fixRange/addLiquidity/addLiquidity';
import { farmFixRangeiZiLiquidity } from './farm/iZiSwap/fixRange/addLiquidity/addLiquidity';
import { farmDynamicRange } from './farm/UniswapV3/dynamicRange/farmDynamicRange';
import { farmDynamicRangeiZi } from './farm/iZiSwap/dynamicRange/farmDynamicRange';
import { farmDynamicRangeiZiAddLiquidity, veiZiFarmDynamicRangeiZiAddLiquidity } from './farm/iZiSwap/dynamicRange/addLiquidity/addLiquidity';
import { farmDynamicRangeAddLiquidity, veiZiFarmDynamicRangeAddLiquidity } from './farm/UniswapV3/dynamicRange/addLiquidity/addLiquidity';
import { farmOneSide } from './farm/UniswapV3/oneSide/farmOneSide';
import { farmOneSideiZi } from './farm/iZiSwap/oneSide/farmOneSide';
import { farmOneSideAddLiquidity, veiZiFarmOneSideAddLiquidity } from './farm/UniswapV3/oneSide/addLiquidity/addLiquidity';
import { token } from './token/token';
import { account } from './account/account';
import { tradeSwap } from './trade/swap/tradeSwap';
import { tradeLimitOrder } from './trade/limitOrder/tradeLimitOrder';
import { announceBar } from './announceBar/announceBar';
import { veiZi } from './veiZi/veiZi';
import { veiZiFarmFixRangeStake } from './farm/UniswapV3/fixRange/stake/veiZiFixRangeStake';
import { tradeMode } from './trade/mode/tradeMode';
import { customTokens } from './trade/cusTokens/cusTokens';
import { historyOrder } from './orderHistory/tradeHistory';
import { prize } from './prize/prize';
import { farmGallery } from './farm/gallery/gallery';
import { farmOneSideiZiAddLiquidity, veiZiFarmOneSideiZiAddLiquidity } from './farm/iZiSwap/oneSide/addLiquidity/addLiquidity';
import { latestTokens } from './trade/latestTokens/latestTokens';
import { swapPreQueryModel } from './trade/aggregator/model';
import { pageLayout } from './pageLayout/pageLayout';
import { pro } from './pro/pro';
import { proOrderFormState } from './pro/proOrderFormState/proOrderFormState';
import { iZiSwapMintOrIncForm } from './trade/liquidity/models/mintModel';
import { iZiSwapLiquidityList } from './trade/liquidity/models/liquidityListModel';
import { proOrderList } from './pro/proOrderList/proOrderList';
import { favorite } from './pro/favorite/favorite';
import { proOrderBook } from './pro/proOrderBook/proOrderBook';
import { proControlState } from './pro/controls/controlState';

export interface RootModel extends Models<RootModel> {
    block: typeof block;
    token: typeof token;
    account: typeof account;
    wallet: typeof wallet;
    farmFixRange: typeof farmFixRange;
    farmFixRangeiZi: typeof farmFixRangeiZi
    farmFixRangeUniLiquidity: typeof farmFixRangeUniLiquidity;
    farmFixRangeiZiLiquidity: typeof farmFixRangeiZiLiquidity;
    veiZiFarmFixRangeStake: typeof veiZiFarmFixRangeStake;
    farmDynamicRange: typeof farmDynamicRange;
    farmDynamicRangeiZi: typeof farmDynamicRangeiZi;
    farmDynamicRangeAddLiquidity: typeof farmDynamicRangeAddLiquidity;
    farmDynamicRangeiZiAddLiquidity: typeof farmDynamicRangeiZiAddLiquidity;
    veiZiFarmDynamicRangeAddLiquidity: typeof veiZiFarmDynamicRangeAddLiquidity;
    veiZiFarmDynamicRangeiZiAddLiquidity: typeof veiZiFarmDynamicRangeiZiAddLiquidity;
    farmOneSide: typeof farmOneSide;
    farmOneSideiZi: typeof farmOneSideiZi;
    farmOneSideAddLiquidity: typeof farmOneSideAddLiquidity;
    farmOneSideiZiAddLiquidity: typeof farmOneSideiZiAddLiquidity;
    veiZiFarmOneSideAddLiquidity: typeof veiZiFarmOneSideAddLiquidity;
    veiZiFarmOneSideiZiAddLiquidity: typeof veiZiFarmOneSideiZiAddLiquidity;
    iZiSwapMintOrIncForm: typeof iZiSwapMintOrIncForm;
    iZiSwapLiquidityList: typeof iZiSwapLiquidityList;
    tradeSwap: typeof tradeSwap;
    tradeLimitOrder: typeof tradeLimitOrder;
    announceBar: typeof announceBar;
    veiZi: typeof veiZi;
    tradeMode: typeof tradeMode;
    customTokens: typeof customTokens;
    historyOrder:typeof historyOrder;
    prize:typeof prize;
    farmGallery:typeof farmGallery;
    latestTokens:typeof latestTokens;
    swapPreQueryModel: typeof swapPreQueryModel;
    pageLayout:typeof pageLayout;
    pro:typeof pro;
    proOrderFormState: typeof proOrderFormState;
    proOrderList: typeof proOrderList;
    proOrderBook: typeof proOrderBook;
    proControlState: typeof proControlState;
    favorite:typeof favorite;
}

export const models: RootModel = {
    block,
    farmFixRange,
    farmFixRangeiZi,
    farmFixRangeUniLiquidity,
    farmFixRangeiZiLiquidity,
    veiZiFarmFixRangeStake,
    farmDynamicRange,
    farmDynamicRangeiZi,
    farmDynamicRangeAddLiquidity,
    farmDynamicRangeiZiAddLiquidity,
    veiZiFarmDynamicRangeAddLiquidity,
    veiZiFarmDynamicRangeiZiAddLiquidity,
    token,
    account,
    wallet,
    iZiSwapMintOrIncForm,
    iZiSwapLiquidityList,
    tradeSwap,
    tradeLimitOrder,
    farmOneSide,
    farmOneSideiZi,
    farmOneSideAddLiquidity,
    farmOneSideiZiAddLiquidity,
    veiZiFarmOneSideAddLiquidity,
    veiZiFarmOneSideiZiAddLiquidity,
    announceBar,
    veiZi,
    tradeMode,
    customTokens,
    historyOrder,
    prize,
    farmGallery,
    latestTokens,
    swapPreQueryModel,
    pageLayout,
    pro,
    proOrderFormState,
    proOrderList,
    proOrderBook,
    proControlState,
    favorite,
};
import { HStack, Center, BoxProps, Text, Image } from '@chakra-ui/react';
import React from 'react';
import { i_text_copy_bold } from '../../../../../style';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { useColorMode } from '@chakra-ui/color-mode';

export type ButtonProps = {
    status: string;
} & BoxProps;

export const Status: React.FC<ButtonProps> = (props) => {
    const { status, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <Center
            w="110px"
            h="30px"
            transition="all 0.3s ease"
            alignItems="center"
            borderRadius="2px"
            bgColor={status === 'finished' ? colorTheme('#87AF73', '#314A44') : colorTheme('#A7A7A7', 'tertiary.500')}
            className={i_text_copy_bold}
            color="tertiary.50"
            {...rest}
        >
            <HStack spacing="4px">
                {status === 'finished' && <Image w="12px" h="12px" src={process.env.PUBLIC_URL + '/assets/trade/correct.svg'} />}
                <Text>{status === 'finished' ? 'Filled' : 'Canceled'}</Text>
            </HStack>
        </Center>
    );
};

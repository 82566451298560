import { BoxProps, useColorMode, Divider, HStack, Center, VStack, Text, Image, Stack } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy, i_text_d, i_h3, i_h5 } from '../../../../style';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Modal } from '../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { TokenIcons } from '../../components/TokenIcons';
import { FeeRate } from '../../components/FeeRate';
import { CustomSlider } from '../../components/CustomSlider';
import CurrentPriceBlock from '../../../components/CurrentPriceBlock';
import { useBoxContract, useLiquidityManagerContract } from '../../../../hooks/useContracts';
import { formatNumber } from '../../../../utils/tokenMath';
import { useCallback, useState } from 'react';
import BigNumber from 'bignumber.js';

import useTokenEntity from '../../../../state/models/hooks/useTokenEntity';
import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { isGasToken } from '../../../../config/tokens';
import { getLiquidityManagerContractAddress } from '../../../../utils/contractFactory';
import { BIG_MAX_UNIT128 } from '../../../../utils/bigNumber';
import { A_LONG_FUTURE_TIME, IZUMI_SWAP_CONFIG } from '../../../../config/bizConfig';
import { getWithdrawLiquidityValue } from '../../../../state/models/trade/liquidity/funcs';
import { RootDispatch, RootState } from '../../../../state/store';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import { useGasPrice } from '../../../../hooks/useGasPrice';
import { useCustomToast } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { getSwapTokenAddress } from '../../../../state/models/common/positionPoolHelper';
import { useTranslation } from 'react-i18next';
import { BOX_ADDRESS } from '../../../../config/trade/tradeContracts';
import { GetApprovedForParams, SetNFTApprovedForParams } from '../../../../state/models/trade/liquidity/models/liquidityListModel';
import { LiquidityDetail } from '../../../../state/models/trade/liquidity/types';

type RemoveLiquidityModalProps = {
    entry?: LiquidityDetail;
    isOpen: boolean | any;
    onClose: any;
    handleRefreshLiquidity: () => void;
} & BoxProps;

export const RemoveLiquidityModal: React.FC<RemoveLiquidityModalProps> = (props) => {
    const { entry, isOpen, onClose, handleRefreshLiquidity, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const { chainId, account } = useWeb3WithDefault();
    const liquidityManagerContract = useLiquidityManagerContract();
    const boxContract = useBoxContract();
    const liquidityManagerContractAddress = getLiquidityManagerContractAddress(chainId);

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { gasPrice } = useGasPrice();
    const toast = useCustomToast();
    const [togglePrice, setTogglePrice] = useState(false);

    const tokenXNotWrapToken = !entry?.tokenX.wrapTokenAddress;
    const tokenYNotWrapToken = !entry?.tokenY.wrapTokenAddress;

    const noWrapToken = tokenXNotWrapToken && tokenYNotWrapToken;

    const handleDecLiquidWithWrapToken = useCallback(
        (decPercent: number) => {
            if (!entry || !liquidityManagerContract || !boxContract || !account) {
                return;
            }
            const liquidDelta = new BigNumber(entry.liquidity).multipliedBy(decPercent / 100).toFixed(0, 3);

            if (liquidDelta === '0') {
                return;
            }

            const { amountX: withdrawX, amountY: withdrawY } = getWithdrawLiquidityValue(entry, new BigNumber(liquidDelta));

            const amountXMin = withdrawX.times(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR).toFixed(0, 3);

            const amountYMin = withdrawY.times(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR).toFixed(0, 3);

            const tokenXAddress = getSwapTokenAddress(entry.tokenX);
            const tokenYAddress = getSwapTokenAddress(entry.tokenY);
            const tokenXNotWrap = !entry.tokenX.wrapTokenAddress;
            const tokenYNotWrap = !entry.tokenY.wrapTokenAddress;
            return boxContract.methods
                .decreaseLiquidity(
                    account,
                    entry.tokenId,
                    liquidDelta,
                    amountXMin,
                    amountYMin,
                    String(A_LONG_FUTURE_TIME),
                    tokenXAddress,
                    tokenYAddress,
                    !tokenXNotWrap,
                    !tokenYNotWrap
                )
                .send({ from: account })
                .on('transactionHash', () => toast('info', 'OnGoing...'))

                .then(() => {
                    onClose();
                    toast('info', 'Remove successfully');
                    handleRefreshLiquidity();
                })
                .catch((e: any) => {
                    toast('error', 'Error: ' + e);
                });
        },
        [entry, liquidityManagerContract, chainId, boxContract, account, handleRefreshLiquidity]
    );

    const handleDecLiquidNoWrapToken = useCallback(
        (decPercent: number) => {
            if (!entry || !liquidityManagerContract || !account || !liquidityManagerContractAddress) {
                return;
            }
            const liquidDelta = new BigNumber(entry.liquidity).multipliedBy(decPercent / 100).toFixed(0, 3);

            if (liquidDelta === '0') {
                return;
            }

            const { amountX: withdrawX, amountY: withdrawY } = getWithdrawLiquidityValue(entry, new BigNumber(liquidDelta));

            const amountXMin = withdrawX.times(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR).toFixed(0, 3);

            const amountYMin = withdrawY.times(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR).toFixed(0, 3);

            const touchMultiCallData = liquidityManagerContract.methods
                .decLiquidity(entry.tokenId, liquidDelta, amountXMin, amountYMin, String(A_LONG_FUTURE_TIME))
                .encodeABI();

            // collect Fees
            const tokenXIsChainCoin = isGasToken(entry.tokenX, chainId);
            const tokenYIsChainCoin = isGasToken(entry.tokenY, chainId);
            const hasChainCoin = tokenXIsChainCoin || tokenYIsChainCoin;
            const recipient = hasChainCoin ? liquidityManagerContractAddress : account;

            const collectMultiCallData = liquidityManagerContract.methods
                .collect(recipient, entry.tokenId, BIG_MAX_UNIT128.toString(), BIG_MAX_UNIT128.toString())
                .encodeABI();

            const callList: string[] = [touchMultiCallData, collectMultiCallData];

            if (hasChainCoin) {
                callList.push(liquidityManagerContract.methods.unwrapWETH9('0', account).encodeABI());
                const tokenNotEth = tokenXIsChainCoin ? entry.tokenY.address : entry.tokenX.address;
                callList.push(liquidityManagerContract.methods.sweepToken(tokenNotEth, '0', account).encodeABI());
            }
            return liquidityManagerContract.methods
                .multicall(callList)
                .send({ from: account })
                .on('transactionHash', () => toast('info', 'OnGoing...'))
                .then(() => {
                    onClose();
                    toast('info', 'Remove successfully');

                    handleRefreshLiquidity();
                })
                .catch((e: any) => {
                    toast('error', 'Error: ' + e);
                });
        },

        [entry, liquidityManagerContract, chainId, liquidityManagerContractAddress, account, handleRefreshLiquidity]
    );
    const tokenXEntity = useTokenEntity(entry?.tokenX as unknown as any, undefined);
    const tokenYEntity = useTokenEntity(entry?.tokenY as unknown as any, undefined);

    const [decPercent, setDecPercent] = useState(50);

    const { iZiSwapLiquidityList } = useSelector((state: RootState) => state);
    if (!entry) {
        return null;
    }

    const liquidDelta = new BigNumber(new BigNumber(entry.liquidity).multipliedBy(decPercent / 100).toFixed(0, 3));
    const { amountXDecimal: withdrawXDecimal, amountYDecimal: withdrawYDecimal } = getWithdrawLiquidityValue(entry, liquidDelta);

    const estimateRemoveValue = tokenXEntity.tokenPrice() * withdrawXDecimal + tokenYEntity.tokenPrice() * withdrawYDecimal;

    const tokenBlock = (token: TokenInfoFormatted, amount: any) => (
        <HStack w="100%" justifyContent="space-between">
            <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.400', 'tertiary.300')}>
                {token.symbol}
            </Text>
            <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.700', 'tertiary.50')}>
                {amount}
            </Text>
        </HStack>
    );

    const tokenIconBlock = (token: TokenInfoFormatted, amount: any) => (
        <HStack w="100%" justifyContent="space-between">
            <HStack>
                <Image w="15px" h="15px" src={token.icon} />
                <Text className={i_text_copy} fontSize="16px" color={colorTheme('tertiary.400', 'tertiary.200')}>
                    {token.symbol}
                </Text>
            </HStack>
            <Text className={i_text_copy_bold} fontSize="16px" color={colorTheme('tertiary.700', 'tertiary.50')}>
                {amount}
            </Text>
        </HStack>
    );

    const nftNotNeedBox = !entry?.tokenX.wrapTokenAddress && !entry?.tokenY.wrapTokenAddress;
    const nftNeedApproveForBox = !nftNotNeedBox && !iZiSwapLiquidityList.isApprovedForBox;

    return (
        <Modal isOpen={isOpen} onClose={onClose} w={{ base: '338px', sm: '788px' }} h="635px" title={t('Remove Liquidity')} {...rest}>
            <Divider mt="-20px !important" />

            <Stack w="100%" spacing="16px" h={{ base: 'unset', sm: '392px' }} mt="15px" direction={{ base: 'column', sm: 'row' }}>
                <Card variant="deep" w={{ base: '100%', sm: '437px' }} h={{ base: 'unset', sm: '407px' }}>
                    <Stack
                        w={{ base: '100%', sm: '400px' }}
                        spacing="10px"
                        p={{ base: '20px 12px 20px 20px', sm: '40px 12px 14px 20px' }}
                        direction={{ base: 'column', sm: 'row' }}
                        alignItems="center"
                    >
                        <HStack w={{ base: 'unset', sm: '203px' }} spacing="10px">
                            <TokenIcons tokenA={entry.tokenX} tokenB={entry.tokenY} initialToggle={togglePrice} />
                            <FeeRate
                                tokenA={entry.tokenX}
                                tokenB={entry.tokenY}
                                feeTier={Number(entry.fee) as FeeTier}
                                initialToggle={togglePrice}
                            />
                        </HStack>
                        <CurrentPriceBlock
                            tokenA={entry.tokenX}
                            tokenB={entry.tokenY}
                            price={entry.currentPriceDecimal}
                            handleToggle={() => {
                                setTogglePrice(!togglePrice);
                            }}
                            toggle={togglePrice}
                            w="174px"
                        />
                    </Stack>

                    <VStack
                        w="100%"
                        h="282px"
                        p="20px 18px 10px 18px"
                        bg={colorTheme('linear-gradient(180deg, #FAFAFA 0%, rgba(250, 250, 250, 0) 100%)', 'undefined')}
                        spacing="20px"
                    >
                        <VStack w="100%" alignItems="center" alignSelf="center">
                            <Text className={i_text_d} fontSize="16px" color={colorTheme('tertiary.400', 'tertiary.300')}>
                                {t('Liquidity in Pool')}
                            </Text>

                            <Text className={i_h3} fontSize="32px" color={colorTheme('tertiary.700', 'tertiary.50')} fontWeight="500">
                                {formatNumber(entry.liquidityValue)}
                            </Text>
                        </VStack>

                        <Card variant="purpleLinear" w="100%" h="160px" p="25px 37px 20px 20px">
                            <VStack alignItems="start">
                                <Text className={i_text_copy_bold} color={colorTheme('tertiary.500', 'tertiary.50')}>
                                    {t('Remove Percentage')}
                                </Text>
                                <CustomSlider value={decPercent} handleChange={(value: number) => setDecPercent(value)} />
                                <HStack spacing="5px" w="60%" h="22px" justifyContent="space-between">
                                    {[25, 50, 75, 100].map((item, index) => (
                                        <CustomButton
                                            onClick={() => setDecPercent(Number(item))}
                                            key={index}
                                            w="46px"
                                            h="22px"
                                            borderRadius="3px"
                                            alignItems="center"
                                            justifyContent="center"
                                            variant="primary2"
                                            text={typeof item === 'number' ? item + '%' : item}
                                            fontClass={i_text_d}
                                            fontSize="12px"
                                        />
                                    ))}
                                </HStack>
                            </VStack>
                        </Card>
                    </VStack>
                </Card>

                <Card variant="deep" w={{ base: '100%', sm: '268px' }} h="407px">
                    <VStack w="100%" p="25px 46px 14px 46px" spacing="30px" alignItems="center">
                        <Text className={i_h5} fontSize="14px" color={colorTheme('tertiary.500', 'tertiary.50')}>
                            {t('SUMMARY')}
                        </Text>

                        <VStack w="100%" alignItems="start">
                            <Text className={i_text_copy_bold} fontSize="12px" color={colorTheme('tertiary.900', 'tertiary.100')} mb="4px">
                                {t('Liquidity')}
                            </Text>
                            {tokenBlock(entry.tokenX, formatNumber(entry.tokenXLiquidityDecimal))}
                            {tokenBlock(entry.tokenY, formatNumber(entry.tokenYLiquidityDecimal))}
                        </VStack>

                        <VStack w="100%" alignItems="start">
                            <Text className={i_text_copy_bold} fontSize="12px" color={colorTheme('tertiary.900', 'tertiary.100')} mb="4px">
                                {t('Fee Gains')}
                            </Text>
                            {tokenBlock(entry.tokenX, formatNumber(Number(entry.remainTokenX)))}
                            {tokenBlock(entry.tokenY, formatNumber(Number(entry.remainTokenY)))}
                        </VStack>
                    </VStack>

                    {colorTheme(<Divider mt="0 !important" />, <></>)}

                    <VStack
                        w="100%"
                        p="15px 46px 14px 46px"
                        spacing="30px"
                        alignItems="center"
                        bg={colorTheme('undefined', 'tertiary.800')}
                    >
                        <VStack w="100%" alignItems="start">
                            <Text className={i_text_copy_bold} fontSize="12px" color={colorTheme('tertiary.900', 'tertiary.100')} mb="4px">
                                {t('TOTAL')}
                            </Text>
                            {tokenIconBlock(entry.tokenX, formatNumber(withdrawXDecimal + Number(entry.remainTokenX)))}
                            {tokenIconBlock(entry.tokenY, formatNumber(withdrawYDecimal + Number(entry.remainTokenY)))}
                        </VStack>
                        <VStack spacing="0">
                            <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.600', 'tertiary.200')} mb="4px">
                                {t('Estimate Value')}
                            </Text>

                            <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.600', 'tertiary.200')} mb="4px">
                                {formatNumber(estimateRemoveValue)} USD
                            </Text>
                        </VStack>
                    </VStack>
                </Card>
            </Stack>

            {!nftNeedApproveForBox && (
                <Center w="100%" mt="30px">
                    <CustomButton
                        variant="purple"
                        text={t('Remove')}
                        fontClass={i_text_copy_bold}
                        mt="20px"
                        fontSize="18px"
                        w={{ base: '100%', sm: '435px' }}
                        h="62px"
                        onClick={() => {
                            if (noWrapToken) {
                                handleDecLiquidNoWrapToken(decPercent);
                            } else {
                                handleDecLiquidWithWrapToken(decPercent);
                            }
                        }}
                    />
                </Center>
            )}
            {nftNeedApproveForBox && (
                <Center w="100%">
                    <CustomButton
                        mt="24px !important"
                        variant="purple"
                        text={t('Approve Box')}
                        w={{ base: '100%', sm: '469px' }}
                        h="62px"
                        fontSize="14px"
                        onClick={() => {
                            dispatch.iZiSwapLiquidityList
                                .setApprovedFor({
                                    chainId,
                                    account,
                                    liquidityManagerContract,
                                    gasPrice,
                                    operatorAddress: BOX_ADDRESS[chainId],
                                    onGoingCallback: () => {
                                        toast('info', 'Ongoing ...');
                                    },
                                } as SetNFTApprovedForParams)
                                .then(() =>
                                    dispatch.iZiSwapLiquidityList.getApprovedForBox({
                                        chainId,
                                        account,
                                        liquidityManagerContract,
                                    } as GetApprovedForParams)
                                );
                        }}
                    />
                </Center>
            )}
        </Modal>
    );
};

import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import to from 'await-to-js';
import { Box, Flex, useColorMode } from '@chakra-ui/react';
import { RootDispatch } from '../state/store';
import { useRematchDispatch } from '../hooks/useRematchDispatch';
import { TOKEN_CONFIG } from '../config/bizConfig';
import { useInterval } from 'ahooks';
import { getColorThemeSelector } from '../utils/funcs';

type LoadUntilResolve = <T extends unknown>(promise: Promise<T>) => void;

interface LoadInfo {
    loadUntilResolve: LoadUntilResolve;
    isLoading: boolean;
}

const LoadContext = React.createContext<LoadInfo>(undefined!);

export const useLoadInfo = () => {
    return useContext(LoadContext);
};

interface Props {
    promiseList: Promise<any>[];
}

const LoadProvider: React.FC<Props> = (props) => {
    const { ready: transReady } = useTranslation();
    const [didInitialRender, setDidInitialRender] = useState(false);
    const [isInitialPromiseLoad, setIsInitialPromiseLoad] = useState(false);
    const [didRemoveInitialLoad, setDidRemoveInitialLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const tokenDispatch = useRematchDispatch((dispatch: RootDispatch) => dispatch.token);

    useInterval(() => tokenDispatch.refreshTokenPrice(''), TOKEN_CONFIG.AUTO_REFRESH_TOKEN_PRICE_INTERVAL, { immediate: true });

    useEffect(() => {
        const load = async () => {
            const promiseList = [...props.promiseList];
            await Promise.all(promiseList);

            const minimumLoadTime = 2500;
            setTimeout(() => {
                setIsInitialPromiseLoad(false);
            }, minimumLoadTime);
        };
        if (isInitialPromiseLoad) {
            load();
        }
    }, [isInitialPromiseLoad, props.promiseList]);

    const loadUntilResolve: LoadUntilResolve = useCallback(async (promise) => {
        setIsLoading(true);
        await to(promise);
        setIsLoading(false);
    }, []);

    const measuredRef = useCallback((node: HTMLElement) => {
        if (node !== null) {
            setDidInitialRender(true);
        }
    }, []);

    useEffect(() => {
        if (didInitialRender && !isInitialPromiseLoad) {
            const initLoadSymbol = document.getElementById('init-load');
            if (initLoadSymbol !== null) {
                initLoadSymbol.remove();
            }
            setDidRemoveInitialLoad(true);
        }
    }, [didInitialRender, isInitialPromiseLoad]);

    if (!transReady) {
        return null;
    }

    const initialLoad = !didInitialRender || isInitialPromiseLoad || !didRemoveInitialLoad;

    return (
        <LoadContext.Provider
            value={{
                loadUntilResolve: loadUntilResolve,
                isLoading: isLoading,
            }}
        >
            {isInitialPromiseLoad && (
                <Flex
                    //pos="absolute"
                    h="100vh"
                    w="100%"
                    zIndex={100}
                    pointerEvents="none"
                    align="center"
                    justify="center"
                    //display={isLoading && !initialLoad ? 'block' : 'none'}
                >
                    <img src={process.env.PUBLIC_URL + colorTheme('/assets/loading.gif', '/assets/loading-dark.gif')} alt="" />
                </Flex>
            )}

            <Box
                // @ts-ignore
                ref={measuredRef}
                display={isLoading || initialLoad ? 'none' : 'unset'}
            >
                {props.children}
            </Box>
        </LoadContext.Provider>
    );
};

export default LoadProvider;

import { SearchIcon } from '@chakra-ui/icons';
import {
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useColorMode,
    VStack,
    Image,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { SEARCH_TOKENS_PRO } from '../../../../../../config/bizConfig';
import useIsMobile from '../../../../../../hooks/useIsMobile';
import { useWeb3WithDefault } from '../../../../../../hooks/useWeb3WithDefault';
import { RootState } from '../../../../../../state/store';
import { i_text_copy_bold, noto_t3_bold } from '../../../../../../style';
import { getColorThemeSelector } from '../../../../../../utils/funcs';
import { ResponseKlineInfoMetaWithReverse } from '../../KlineInfo';

import FavoritesBlock from './FavoritesBlock';
import SearchTokenBlock from './SearchTokenBlock';
type SearchProps = {
    pools: ResponseKlineInfoMetaWithReverse[];
};
const Search: React.FC<SearchProps> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();
    const isMobile = useIsMobile();

    const { pools } = props;
    const {
        favorite: { favPool },
    } = useSelector((state: RootState) => state);

    const [searchKey, setSearchKey] = useState('');

    const tabStyle = {
        _selected: { color: '#7F4AFE', borderColor: '#7F4AFE', borderBottomWidth: '3px' },
        fontSize: '12px !important',
        px: '6px',
        mx: '5px',
        color: '#83789B',
    };

    const filterPools = pools.filter((item) => {
        return (
            item.tokenX.symbol?.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            item.tokenY.symbol?.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        );
    });

    const favPoolKeys = Object.keys(favPool);
    const addr_list = favPoolKeys.map((k) => favPool[k].address);

    const favoritePools = filterPools.filter((item) => {
        return addr_list.includes(item.pool.address);
    });

    function filterByTokenY(tokenSymbol: string) {
        return filterPools.filter((item) => {
            return item.tokenY.symbol === tokenSymbol;
        });
    }

    const filterMoreTokens = filterPools.filter((item) => {
        return !SEARCH_TOKENS_PRO[chainId].includes(item);
    });

    return isMobile ? (
        <Stack py="20px" h="100%">
            <Stack w="100%" ml="16px">
                <InputGroup w="330px" h="35px">
                    <InputLeftElement boxSize="15px" mt="10px" ml="10px">
                        <SearchIcon opacity="0.5" />
                    </InputLeftElement>
                    <Input
                        variant="filled"
                        h="100%"
                        placeholder={'Search Tokens or Pools'}
                        size="lg"
                        className={i_text_copy_bold}
                        fontSize="12px"
                        onChange={(e: any) => {
                            setSearchKey(e.target.value);
                        }}
                        color="#C7C6CA"
                        letterSpacing="0.02em"
                        fontFamily="Montserrat"
                        bg={colorTheme('#F8F8F9', '#0B071E')}
                        _focus={{ border: '1px solid #9264FF' }}
                    />
                </InputGroup>
            </Stack>
            <Tabs
                _focus={{ border: '' }}
                _active={{}}
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none !important',
                    },
                }}
                overflowY="auto"
                height="100%"
            >
                <TabList className={noto_t3_bold} bg={colorTheme('#ffffff', '#160F29')} position="sticky" top="0px" zIndex="1">
                    <Tab ml="18px !important" pl="0px !important" {...tabStyle}>
                        <Image boxSize="16px" src="/assets/pro/info/star.svg" mr="4px"></Image>
                        Favorites
                    </Tab>
                    {SEARCH_TOKENS_PRO[chainId].map((item: string, index: number) => {
                        return (
                            <Tab {...tabStyle} key={index}>
                                {item}
                            </Tab>
                        );
                    })}

                    <Tab {...tabStyle}>More</Tab>
                </TabList>

                <TabPanels px="5px">
                    <TabPanel>
                        <FavoritesBlock favoritePools={favoritePools}></FavoritesBlock>
                    </TabPanel>
                    {SEARCH_TOKENS_PRO[chainId].map((item: string, index: number) => {
                        return (
                            <TabPanel key={index}>
                                <SearchTokenBlock data={filterByTokenY(item)}></SearchTokenBlock>
                            </TabPanel>
                        );
                    })}

                    <TabPanel>
                        <SearchTokenBlock data={filterMoreTokens}></SearchTokenBlock>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Stack>
    ) : (
        <Stack py="20px" h="100%">
            <VStack w="100%">
                <InputGroup w="395px" h="35px">
                    <InputLeftElement boxSize="15px" mt="10px" ml="10px">
                        <SearchIcon opacity="0.5" />
                    </InputLeftElement>
                    <Input
                        variant="filled"
                        h="100%"
                        placeholder={'Search Tokens or Pools'}
                        size="lg"
                        className={i_text_copy_bold}
                        fontSize="12px"
                        onChange={(e: any) => {
                            setSearchKey(e.target.value);
                        }}
                        color="#C7C6CA"
                        letterSpacing="0.02em"
                        fontFamily="Montserrat"
                        bg={colorTheme('#F8F8F9', '#0B071E')}
                        _focus={{ border: '1px solid #9264FF' }}
                    />
                </InputGroup>
            </VStack>
            <Tabs
                _focus={{ border: '' }}
                _active={{}}
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none !important',
                    },
                }}
                overflowY="auto"
                height="100%"
            >
                <TabList className={noto_t3_bold} bg={colorTheme('#ffffff', '#160F29')} position="sticky" top="0px" zIndex="1">
                    <Tab ml="18px !important" pl="0px !important" {...tabStyle}>
                        <Image boxSize="16px" src="/assets/pro/info/star.svg" mr="4px"></Image>
                        Favorites
                    </Tab>
                    {SEARCH_TOKENS_PRO[chainId].map((item: string, index: number) => {
                        return (
                            <Tab {...tabStyle} key={index}>
                                {item}
                            </Tab>
                        );
                    })}

                    <Tab {...tabStyle}>More</Tab>
                </TabList>

                <TabPanels px="5px">
                    <TabPanel>
                        <FavoritesBlock favoritePools={favoritePools}></FavoritesBlock>
                    </TabPanel>
                    {SEARCH_TOKENS_PRO[chainId].map((item: string, index: number) => {
                        return (
                            <TabPanel key={index}>
                                <SearchTokenBlock data={filterByTokenY(item)}></SearchTokenBlock>
                            </TabPanel>
                        );
                    })}

                    <TabPanel>
                        <SearchTokenBlock data={filterMoreTokens}></SearchTokenBlock>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Stack>
    );
};
export default Search;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Center, Heading, HStack } from '@chakra-ui/react';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../state/store';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import PositionListEntry from './PositionListEntry';
import { useBoxContract, useLiquidityManagerContract } from '../../../../hooks/useContracts';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { A_LONG_FUTURE_TIME, IZUMI_SWAP_CONFIG } from '../../../../config/bizConfig';
import { useInterval } from 'ahooks';
import { AddLiquidityModal } from './AddLiquidityModal';
import { RemoveLiquidityModal } from './RemoveLiquidityModal';
import { BIG_MAX_UNIT128 } from '../../../../utils/bigNumber';
import { TransactionReceipt } from 'ethereum-abi-types-generator';
import { FeeRate } from '../../components/FeeRate';
import { TokenIcons } from '../../components/TokenIcons';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../../hooks/useTokenListFormatted';
import { getLiquidityManagerContractAddress } from '../../../../utils/contractFactory';
import { isGasToken } from '../../../../config/tokens';
import _ from 'lodash';
import { TokenSymbol } from '../../../../types/mod';
import { getChain } from '../../../../config/chains';
import { useGasPrice } from '../../../../hooks/useGasPrice';
import { BOX_ADDRESS } from '../../../../config/trade/tradeContracts';
import { buildSendingParams } from '../../../../utils/contractHelpers';
import { useTranslation } from 'react-i18next';
import { TapModeStatus } from '../../../../state/models/trade/tap/types';
import BigNumber from 'bignumber.js';
import { LiquidityDetail } from '../../../../state/models/trade/liquidity/types';
import { izumiFeeToTickSpacingMapping } from '../../../../utils/tickMath';
import { FetchLiquidityParams } from '../../../../state/models/trade/liquidity/models/liquidityListModel';

export const PositionList: React.FC<unknown> = () => {
    const { t } = useTranslation();
    const { chainId, web3, account } = useWeb3WithDefault();
    const { tokenList } = useTokenListFormatted();
    const {
        iZiSwapLiquidityList: { liquidityList: liquidityListRaw, isApprovedForBox, control },
    } = useSelector((state: RootState) => state);
    const [toggle] = useState(false);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const liquidityManagerContract = useLiquidityManagerContract();
    const boxContract = useBoxContract();
    const liquidityManagerContractAddress = getLiquidityManagerContractAddress(chainId);

    const [openAddLiquidityModal, setOpenAdd] = useState(false);
    const [openRemoveLiquidityModal, setOpenRemove] = useState(false);
    const [currentOperatedTokenId, setCurrentOperatedTokenId] = useState<string | undefined>(undefined);

    const handleAddLiquidity = (tokenId: string) => {
        const entry = liquidityList.find((l) => l.tokenId === tokenId) as LiquidityDetail;
        dispatch.iZiSwapMintOrIncForm.initIncForm({
            liquidity: entry,
            isTapMode: false,
            spacingMapping: izumiFeeToTickSpacingMapping,
        });
        setCurrentOperatedTokenId(entry.tokenId);
        setOpenAdd(true);
    };

    const handleRemoveLiquidity = (tokenId: string) => {
        setCurrentOperatedTokenId(tokenId);
        setOpenRemove(true);
    };

    const { gasPrice } = useGasPrice();

    const handleApproveBox = useCallback(() => {
        if (!account || !liquidityManagerContract || !chainId) {
            return new Promise<TransactionReceipt>((_, reject) => reject('Check CreatePoolParams fail'));
        }
        const boxAddress = BOX_ADDRESS[chainId];
        return liquidityManagerContract.methods
            .setApprovalForAll(boxAddress, true)
            .send(buildSendingParams(chainId, { from: account, maxFeePerGas: gasPrice }, gasPrice) as any);
    }, [chainId, account, liquidityManagerContract, gasPrice]);

    const handleCollectAll = useCallback(
        (tokenId: string, tokenX: TokenInfoFormatted, tokenY: TokenInfoFormatted): Promise<TransactionReceipt> => {
            if (!tokenId || !liquidityManagerContract || !liquidityManagerContractAddress || !account) {
                return new Promise<TransactionReceipt>((_, reject) => reject('missing param'));
            }

            if (!tokenX.wrapTokenAddress && !tokenY.wrapTokenAddress) {
                const touchMultiCallData = liquidityManagerContract.methods
                    .decLiquidity(tokenId, '0', '0', '0', String(A_LONG_FUTURE_TIME))
                    .encodeABI();

                const tokenXIsChainCoin = isGasToken(tokenX, chainId);
                const tokenYIsChainCoin = isGasToken(tokenY, chainId);
                const hasChainCoin = tokenXIsChainCoin || tokenYIsChainCoin;
                const recipient = hasChainCoin ? liquidityManagerContractAddress : account;

                const collectMultiCallData = liquidityManagerContract.methods
                    .collect(recipient, tokenId, BIG_MAX_UNIT128.toString(), BIG_MAX_UNIT128.toString())
                    .encodeABI();
                const callList: string[] = [touchMultiCallData, collectMultiCallData];
                if (hasChainCoin) {
                    callList.push(liquidityManagerContract.methods.unwrapWETH9('0', account).encodeABI());
                    const tokenNotEth = tokenXIsChainCoin ? tokenY.address : tokenX.address;
                    callList.push(liquidityManagerContract.methods.sweepToken(tokenNotEth, '0', account).encodeABI());
                }
                return liquidityManagerContract.methods.multicall(callList).send({ from: account as string });
            } else {
                if (!boxContract) {
                    return new Promise<TransactionReceipt>((_, reject) => reject('missing param'));
                }
                const recipient = account;
                const tokenXAddress = tokenX.wrapTokenAddress ?? tokenX.address;
                const tokenYAddress = tokenY.wrapTokenAddress ?? tokenY.address;
                const tokenXIsWrap = tokenXAddress.toLowerCase() != tokenX.address.toLowerCase();
                const tokenYIsWrap = tokenYAddress.toLowerCase() != tokenY.address.toLowerCase();
                return boxContract?.methods
                    .collect(
                        recipient,
                        tokenId,
                        BIG_MAX_UNIT128.toString(),
                        BIG_MAX_UNIT128.toString(),
                        tokenXAddress,
                        tokenYAddress,
                        tokenXIsWrap,
                        tokenYIsWrap
                    )
                    .send({ from: account as string });
            }
        },
        [liquidityManagerContract, account, liquidityManagerContractAddress, chainId]
    );

    const handleRefreshLiquidity = useCallback(() => {
        if (tokenList.length) {
            dispatch.iZiSwapLiquidityList
                .fetchLiquidities({
                    chainId,
                    web3,
                    liquidityManagerContract,
                    account,
                    tokenList,
                } as FetchLiquidityParams)
                .catch((e) => console.log('refreshLiquidity: ', e));
        }
    }, [chainId, account, web3, tokenList, liquidityManagerContract]);

    useEffect(() => {
        console.info('trigger refreshLiquidity', account);
        dispatch.iZiSwapLiquidityList.cleanPosition();
        handleRefreshLiquidity();
    }, [chainId, account, tokenList]);

    useInterval(() => {
        console.log('auto trigger refreshLiquidity', chainId, account);
        handleRefreshLiquidity();
    }, IZUMI_SWAP_CONFIG.AUTO_REFRESH_LIQUIDITY_DATA_INTERVAL);

    const liquidityList = useMemo(() => {
        const list = _.cloneDeep(liquidityListRaw);
        const chain = getChain(chainId);
        if (chain?.wrappedTokenSymbol && control.useWrappedGasToken) {
            list.forEach((e) => {
                if (isGasToken(e.tokenX, chainId)) {
                    e.tokenX.symbol = chain.wrappedTokenSymbol as TokenSymbol;
                }
                if (isGasToken(e.tokenY, chainId)) {
                    e.tokenY.symbol = chain.wrappedTokenSymbol as TokenSymbol;
                }
            });
        }
        return list;
    }, [control.useWrappedGasToken, liquidityListRaw]);

    let positionListFiltered = useMemo(() => {
        const plf = [...liquidityList];
        return plf;
    }, [liquidityList]);

    const currentOperatedEntry = useMemo(
        () => liquidityList.find((l) => l.tokenId === currentOperatedTokenId),
        [liquidityList, currentOperatedTokenId]
    );
    let groupedActiveList = useMemo(() => {
        return positionListFiltered.reduce((acc: any, e: LiquidityDetail) => {
            const key = e.tokenX.symbol + e.tokenY.symbol + String(e.fee);

            const r = acc.find((e: any) => e.key === key);
            r
                ? r.data.push(e)
                : acc.push({
                      key,
                      data: [e],
                      tokenX: e.tokenX,
                      tokenY: e.tokenY,
                      feeTier: e.fee,
                  });
            return acc;
        }, []);
    }, [positionListFiltered]);

    if (control.searchKey) {
        positionListFiltered = positionListFiltered.filter((pool: any) => {
            return pool.tokenX.symbol.includes(control.searchKey) || pool.tokenY.symbol.includes(control.searchKey);
        });
        groupedActiveList = groupedActiveList.filter((pool: any) => {
            return pool.tokenX.symbol.includes(control.searchKey) || pool.tokenY.symbol.includes(control.searchKey);
        });
    }

    const nftNeedBox = currentOperatedEntry?.tokenX.wrapTokenAddress || currentOperatedEntry?.tokenY.wrapTokenAddress;

    const nftNeedApproveForBox = !isApprovedForBox && nftNeedBox;

    return (
        <Accordion allowMultiple={true} allowToggle={true} pb="50px">
            {positionListFiltered.length > 0 ? (
                control.showByPair ? (
                    groupedActiveList.map(
                        (entry: {
                            key: React.Key | null | undefined;
                            tokenX: TokenInfoFormatted;
                            tokenY: TokenInfoFormatted;
                            feeTier: FeeTier;
                            data: LiquidityDetail[];
                        }) => (
                            <Card key={entry.key} my="8px">
                                <AccordionItem>
                                    <AccordionButton h={{ base: '61px', xl: '82px' }} p="0">
                                        <HStack py="8px" pl="40px" pr="30px" spacing="20px" minW={{ base: '100%', sm: '660px' }} w="100%">
                                            <HStack spacing="28px" flexShrink={0} w="100%">
                                                <TokenIcons tokenA={entry.tokenX} tokenB={entry.tokenY} initialToggle={toggle} />
                                                <FeeRate
                                                    tokenA={entry.tokenX}
                                                    tokenB={entry.tokenY}
                                                    feeTier={entry.feeTier}
                                                    initialToggle={toggle}
                                                />
                                                <AccordionIcon ml="auto !important" />
                                            </HStack>
                                        </HStack>
                                    </AccordionButton>
                                    <AccordionPanel py={3}>
                                        {entry.data.map((entry, i) => (
                                            <PositionListEntry
                                                key={i}
                                                entry={entry}
                                                handleRefreshLiquidity={handleRefreshLiquidity}
                                                handleAddLiquidity={handleAddLiquidity}
                                                handleRemoveLiquidity={handleRemoveLiquidity}
                                                handleCollectAll={handleCollectAll}
                                                handleApproveBox={handleApproveBox}
                                                isZip={control.showByPair}
                                                mb="15px"
                                            />
                                        ))}
                                    </AccordionPanel>
                                </AccordionItem>
                            </Card>
                        )
                    )
                ) : (
                    positionListFiltered.map((positionListEntry, i) => (
                        <PositionListEntry
                            key={i}
                            entry={positionListEntry}
                            handleRefreshLiquidity={handleRefreshLiquidity}
                            handleAddLiquidity={handleAddLiquidity}
                            handleRemoveLiquidity={handleRemoveLiquidity}
                            handleCollectAll={handleCollectAll}
                            handleApproveBox={handleApproveBox}
                            isZip={control.showByPair}
                            mb="15px"
                        />
                    ))
                )
            ) : (
                <Center>
                    <Heading size="lg" color="tertiary.100">
                        {t('No position yet')} .
                    </Heading>
                </Center>
            )}
            <AddLiquidityModal
                entry={currentOperatedEntry}
                isOpen={openAddLiquidityModal}
                onClose={() => {
                    setOpenAdd(false);
                }}
                handleRefreshLiquidity={handleRefreshLiquidity}
            />
            <RemoveLiquidityModal
                entry={currentOperatedEntry}
                isOpen={openRemoveLiquidityModal}
                onClose={() => {
                    setOpenRemove(false);
                }}
                handleRefreshLiquidity={handleRefreshLiquidity}
            />
        </Accordion>
    );
};

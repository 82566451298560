import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import { models, RootModel } from './models';
import { enableMapSet } from 'immer';
import persistPlugin from '@rematch/persist';
import storage from 'redux-persist/lib/storage';

enableMapSet();

const persistConfig = {
    whitelist: ['customTokens', 'latestTokens', 'pageLayout', 'favorite'],
    key: 'root',
    storage,
};

export const store = init({
    name: 'iZUMi models',
    models,
    plugins: [persistPlugin(persistConfig) as any],
});

export type Store = typeof store;
export type RootDispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;

import { ChainId } from '../../types/mod';
import chains from '../chains';

//TODO: polygon
export const MULTICALL_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    memo[chain.id] = '';
    if (chain.id === ChainId.BSCTestnet) {
        memo[chain.id] = '0x5712A9aeB4538104471dD85659Bd621Cdd7e07D8';
    }
    if (chain.id === ChainId.BSC) {
        memo[chain.id] = '0xA1189a420662105bef5Be444B8b1E0a7D8279672';
    }
    if (chain.id === ChainId.Aurora) {
        memo[chain.id] = '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F';
    }
    if (chain.id === ChainId.AuroraTestnet) {
        memo[chain.id] = '0xc616D0e903337d8712aFf35e2ccEbD7966EA922D';
    }
    if (chain.id === ChainId.Arbitrum) {
        memo[chain.id] = '0x844A47ad42187F255e5523D4d3Be33f6e94786f8';
    }
    if (chain.id === ChainId.Cronos) {
        memo[chain.id] = '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F';
    }
    if (chain.id === ChainId.Matic) {
        memo[chain.id] = '0x48dA26C7645e98f6764E8E1f4A87112a2BD10F19';
    }
    if (chain.id === ChainId.Rinkeby) {
        memo[chain.id] = '0x546a355d5527e82a0633B363c9f9458c7b959c68';
    }
    if (chain.id === ChainId.MantleTest) {
        memo[chain.id] = '0xEB122DE19FeA9eD039E2d087113db26017f5F91a';
    }
    if (chain.id === ChainId.ZkSyncAlphaTest) {
        memo[chain.id] = '0x54cB3bBA5dcee64292C8cB9F9e2Dfb8fF2aC369c';
    }
    if (chain.id === ChainId.ScrollTestL2) {
        memo[chain.id] = '0xEB122DE19FeA9eD039E2d087113db26017f5F91a';
    }
    if (chain.id === ChainId.Icplaza) {
        memo[chain.id] = '0x5B9A9E3C344Ae569F0Bb8CE45dd0E1C7919be7d8';
    }
    return memo;
}, {});

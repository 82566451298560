import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { MdAddChart } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Box, useColorMode, Button, Icon, HStack, Text, Center } from '@chakra-ui/react';

import { Heading } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import JumpButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/JumpButton/JumpButton';

import LimitForm from './LimitForm';
import Tabs from '../components/Tabs';
import { i_h3 } from '../../../style';
import AdvanceLimit from './AdvanceLimit';
import ChainNotValidBlockWrapper from '../../components/ChainNotValidBlock';
import PageHeader from '../../../components/PageHeader/PageHeader';
import PageLayout from '../../../components/layout/PageLayout';
import { RootDispatch, RootState } from '../../../state/store';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { getColorThemeSelector } from '../../../utils/funcs';
import NetworkSelect from '../../../components/Select/NetworkSelect/NetworkSelect';
import useIsMobile from '../../../hooks/useIsMobile';
import { useTranslation } from 'react-i18next';

const Limit: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { tradeMode } = useSelector((state: RootState) => state);

    const isSimpleMode = tradeMode.isSimpleMode;
    const oneLineMode = useBreakpointValue({ base: false, xl: true })!;
    const setIsSimpleMode = useCallback(
        (e: boolean) => {
            dispatch.tradeMode.setIsSimpleMode(e);
        },
        [tradeMode, dispatch]
    );
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const location = useLocation();
    useEffect(() => {
        const locationState = location.state as any;
        if (locationState) {
            setIsSimpleMode(false);
        }

        document.title = t('Exchange') + ' | ICPLAZA';
    }, [t]);
    const tabValue = 'Limit Order';
    const handleChangeTab = (value: string) => {
        if (value === 'Swap') {
            history.push('/trade/swap');
        }
    };
    const isMobile = useIsMobile();

    return isMobile ? (
        <PageLayout
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Center pt="7px" w="100%">
                            <LimitForm type="mobile" />
                        </Center>
                    }
                    app="trade"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={
                        <HStack spacing="20px">
                            <Heading level="3" fontSize={oneLineMode ? '24px' : '20px'}>
                                {t('Exchange')}
                            </Heading>
                            <Box h="60%" w="3px" bg={colorTheme('tertiary.200', 'tertiary.200')} />
                            <Text
                                className={i_h3}
                                fontSize={oneLineMode ? '23px' : '19px'}
                                color={colorTheme('secondary.600', 'secondary.300')}
                            >
                                {t('Limit Order')}
                            </Text>
                        </HStack>
                    }
                    subtitle={t('Swap tokens with target price') + '.'}
                    element={
                        <HStack spacing="10px">
                            <Button
                                w="34px"
                                h="34px"
                                mr="10px"
                                bgColor={colorTheme('#EBEAEC', '#52466A')}
                                color={colorTheme('#52466A', '#EBEAEC')}
                                borderRadius="3px"
                                fontSize="14px"
                                onClick={() => setIsSimpleMode(!isSimpleMode)}
                                p="7px"
                            >
                                <Icon as={MdAddChart} boxSize="20px" />
                            </Button>

                            <JumpButton w="150px" h="40px" text={t('Manage Liquidity')} target="/trade/liquidity" mr="10px" />
                            <NetworkSelect />
                        </HStack>
                    }
                />
            }
            body={
                isSimpleMode ? (
                    <ChainNotValidBlockWrapper
                        content={
                            <Center pt="20px" w="100%" pb="0px">
                                <Tabs
                                    variant={'simple'}
                                    list={[
                                        {
                                            label: t('Swap'),
                                            value: 'Swap',
                                            component: <></>,
                                        },
                                        {
                                            label: t('Limit Order'),
                                            value: 'Limit Order',
                                            component: <LimitForm />,
                                        },
                                    ]}
                                    value={tabValue}
                                    handleClick={handleChangeTab}
                                ></Tabs>
                            </Center>
                        }
                        app="trade"
                    />
                ) : (
                    <ChainNotValidBlockWrapper content={<AdvanceLimit tabValue="Limit Order"></AdvanceLimit>} app="trade" />
                )
            }
        />
    );
};

export default Limit;

import { Box, BoxProps, HStack, VStack, useColorMode } from '@chakra-ui/react';
import { Text } from '../../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { formatNumber } from '../../../../../utils/tokenMath';
import React, { useMemo } from 'react';
import { i_text_copy_bold } from '../../../../../style';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { isNumeric } from '../../../../../utils/valid';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';

type Props = {
    token: TokenInfoFormatted;
    amount: any;
    onClaim: () => void;
} & BoxProps;

export const Pending: React.FC<Props> = ({ token, amount, onClaim, ...rest }) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const hasPending = useMemo(() => {
        let flag = false;
        if (Number(amount) > 0) {
            flag = true;
        }
        return flag;
    }, [amount]);

    return (
        <HStack
            justifyContent="space-between"
            alignItems="center"
            bg={colorTheme('#F9F9FB', '#302543')}
            p="12px 15px 14px 10px"
            spacing="10px"
            flexShrink={0}
            w="150px"
            {...rest}
        >
            <VStack spacing="6px" flexShrink={0} alignItems="start" w="60px">
                <Text variant="caption" color="tertiary.500">
                    Pending
                </Text>
                <HStack spacing="6px" w="128px">
                    <Box w="16px" h="16px" borderRadius="8px" bgImage={token?.icon} bgSize="cover"></Box>
                    <Text variant="caption-bold" color={colorTheme('tertiary.700', 'tertiary.50')}>
                        {formatNumber(isNumeric(amount) ? amount : 0)}
                    </Text>
                </HStack>
            </VStack>
            <CustomButton
                onClick={onClaim}
                flexShrink={0}
                text="Claim"
                disabled={!hasPending ? true : false}
                variant="primary2"
                fontClass={i_text_copy_bold}
                w="60px"
                h="40px"
            ></CustomButton>
        </HStack>
    );
};

import { scaleLinear, axisBottom, select } from 'd3';
import React, { useMemo } from 'react';
import { BoxProps, Box, useColorMode } from '@chakra-ui/react';
import { i_text_d } from '../../../../style';
import { formatNumber } from '../../../../utils/tokenMath';
import { getColorThemeSelector } from '../../../../utils/funcs';

type ChartProps = {
    dim: {
        width: number;
        height: number;
    };
    zoom: number;
    currentPrice: number;
    minPrice: number;
    maxPrice: number;
} & BoxProps;

export const Chart: React.FC<ChartProps> = ({ dim, currentPrice, minPrice, maxPrice, zoom, ...rest }) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const zoomLevels = useMemo(() => {
        return {
            0: [0.5, 1.5],
            '-1': [0.35, 3],
        };
    }, []);
    console.log(currentPrice, minPrice, maxPrice);

    const { xScale, yScale } = useMemo(() => {
        const scales = {
            xScale: scaleLinear()
                .domain([minPrice * zoomLevels[0][0], maxPrice * zoomLevels[0][1]] as number[])
                .range([0, dim.width]),
            yScale: scaleLinear()
                .domain([0, 1000000] as number[])
                .range([dim.height, 0]),
        };

        if (zoom) {
            console.log(zoom);
        }
        return scales;
    }, [dim, currentPrice, zoomLevels, zoom]);

    return (
        <Box {...rest}>
            <svg
                width="100%"
                height="100%"
                viewBox={`0 0 ${dim.width} ${dim.height}`}
                style={{ overflow: 'visible' }}
                fill={colorTheme('#3A2F53', '#EBEAEC')}
            >
                <line x1={xScale(minPrice)} y1={50} x2={xScale(minPrice)} y2={dim.height} stroke="#52466A" opacity="0.9" />

                <>
                    <text x={xScale(minPrice) - 32} y={30} className={i_text_d}>
                        Min
                    </text>
                    <text x={xScale(minPrice) - 55} y={90} fontSize="12px">
                        {currentPrice - minPrice > 0 ? '-' : '+'}
                        {formatNumber((Math.abs(currentPrice - minPrice) / currentPrice) * 100)}%
                    </text>
                </>

                <>
                    <line x1={xScale(maxPrice)} y1={50} x2={xScale(maxPrice)} y2={dim.height} stroke="#52466A" opacity="0.9" />
                    <text x={xScale(maxPrice)} y={30} className={i_text_d}>
                        Max
                    </text>
                    <text x={xScale(maxPrice) + 10} y={90} fontSize="12px">
                        {-currentPrice + maxPrice > 0 ? '+' : '-'} {formatNumber((Math.abs(-currentPrice + maxPrice) / currentPrice) * 100)}
                        %
                    </text>
                </>

                <rect x={xScale(minPrice)} y={120} width={xScale(maxPrice) - xScale(minPrice)} height={100} fill="#5F9EFF" opacity="0.8" />

                <g //axises
                    ref={(axises) => {
                        axises && select(axises).call(axisBottom(xScale).ticks(6));
                    }}
                    transform={`translate(0, ${dim.height})`}
                />
            </svg>
        </Box>
    );
};

import { BsAlarm } from 'react-icons/bs';
import { ChainId, TokenSymbol } from '../types/mod';
import chains from './chains';
import { tokenSymbol2token } from './tokens';

export const DISABLE_CONSOLE = false;

export const TOKEN_CONFIG = {
    AUTO_REFRESH_TOKEN_PRICE_INTERVAL: 60 * 1000,
    INITIAL_FETCH_TOKEN_LIST: Object.values(TokenSymbol),
};

export const FARM_CONFIG = {
    AUTO_REFRESH_FARM_DATA_INTERVAL: 6 * 1000,
    REWARD_DISPLAY: 'day', // day or block/second
    APR_FEE_GAINS: {
        [ChainId.EthereumMainnet]: 2,
        [ChainId.Matic]: 6,
        [ChainId.Optimism]: 0,
        [ChainId.BSC]: 0,
        [ChainId.Gatechain]: 0,
        [ChainId.Arbitrum]: 3,
        [ChainId.Rinkeby]: 0,
        [ChainId.Aurora]: 0,
        [ChainId.ETC]: 0,
    } as Record<ChainId, number>,
    ALL_FACTOR: 0.99999,
};

export const UNISWAP_MINT_CONFIG = {
    DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR: 0.98,
    DEADLINE_OFFSET_MINUTES: 5,
};

export const IZISWAP_MINT_CONFIG = {
    DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR: 0.98,
    DEADLINE_OFFSET_MINUTES: 5,
};

export const IZUMI_SWAP_CONFIG = {
    AUTO_REFRESH_LIQUIDITY_DATA_INTERVAL: 6 * 1000,
    AUTO_REFRESH_LIMIT_ORDER_DATA_INTERVAL: 6 * 1000,

    ADD_LIQ_DEFAULT_PRICE_OFFSET_PERCENT: 2,

    SWAP_DEFAULT_SLIPPAGE_PERCENT: 0.5,
    TAP_SWAP_DESIRED_MIN_AMOUNT_FACTOR: 0.995,
    SWAP_DEFAULT_MAXIMUM_DELAY: 10,
    DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR: 0.985,

    DESIRED_AMOUNT_TO_MIN_AMOUNT_FEE_TOKEN_FACTOR: 0.85,
};

export const ACCOUNT_CONFIG = {
    AUTO_REFRESH_TOKEN_BALANCE_INTERVAL: 15 * 1000,
};

export const CHAIN_CONFIG = {
    AUTO_REFRESH_GAS_PRICE_INTERVAL: 10 * 1000,
    AUTO_REFRESH_BLOCK_NUMBER_INTERVAL: 5 * 1000,
};

export const PRO_CONFIG = {
    KLINE_AUTO_REFRESH_INTERVAL: 15 * 1000,
    ORDERBOOK_AUTO_REFRESH_PRICE_INTERVAL: 3 * 1000,
    POOL_STATE_REFRESH_INTERVAL: 15 * 1000,
};

export const PRO_DEFAULT_MARKET = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const markets = {
        [ChainId.BSC]: {
            tokenX: tokenSymbol2token(TokenSymbol.WBNB, ChainId.BSC),
            tokenY: tokenSymbol2token(TokenSymbol.USDT, ChainId.BSC),
            pool: '0xf964529721ecd0c9386d922a37cbbd2b67ea6e93',
            fee: 2000,
            market: 'WBNB_USDT_2000',
        },
        [ChainId.Icplaza]: {
            tokenX: tokenSymbol2token(TokenSymbol.WICT, ChainId.Icplaza),
            tokenY: tokenSymbol2token(TokenSymbol.USDT, ChainId.Icplaza),
            pool: '0x13dd42ded12f9f9531e80361b50c64c3c2362a85',
            fee: 2000,
            market: 'WICT_USDT_2000',
        },
        [ChainId.BSCTestnet]: {
            tokenX: tokenSymbol2token(TokenSymbol.WBNB, ChainId.BSCTestnet),
            tokenY: tokenSymbol2token(TokenSymbol.USDT, ChainId.BSCTestnet),
            pool: '0x16baebb51e257e9b9b3fe692b7122e80ed26df3b',
            fee: 2000,
            market: 'WBNB_USDT_2000',
        },
        [ChainId.ScrollTestL2]: {
            tokenX: tokenSymbol2token(TokenSymbol.IZI, ChainId.ScrollTestL2),
            tokenY: tokenSymbol2token(TokenSymbol.USDT, ChainId.ScrollTestL2),
            pool: '0x3f5883324f0a4460027eca85af700601c4efd6e7',
            fee: 400,
            market: 'IZI_USDT_400',
        },
    } as any;
    memo[chain.id] = markets[chain.id] ?? undefined;
    return memo;
}, {}) as any;

export const AUTO_LOGIN = true;

export const A_LONG_FUTURE_TIME = new Date('2026-01-01').getTime() / 1000;

export const FAKE_PRICE = {
    use: false,
    price: {
        USDC: 0.999999,
        ACY: 0.0107,
        KSW: 0.045107,
        REVO: 0.808,
        LAND: 0.01501,
        FROYO: 0.0248813,
        SLD: 0.08717,
        iUSD: 0.9999,
        BNB: 255.98,
        NAS: 0.0392,
        USDT: 1,
        iZi: 0.029,
        BUSD: 1,
    },
} as any;

export const HOT_TOKENS = {
    [ChainId.BSC]: ['BNB', 'iZi', 'iUSD'],
    [ChainId.Aurora]: ['iZi', 'iUSD', 'ARC', 'USN'],
    [ChainId.BSCTestnet]: ['iZi', 'BNB'],
} as any;

export const SEARCH_TOKENS_PRO = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const tokens = {
        [ChainId.BSC]: ['USDT', 'BUSD', 'iUSD', 'WBNB'],
        [ChainId.Aurora]: ['USDT', 'USDC'],
        [ChainId.Icplaza]: ['USDT'],
        [ChainId.BSCTestnet]: ['USDT', 'BUSD', 'WBNB'],
        [ChainId.ScrollTestL2]: ['USDT'],
        [ChainId.MantleTest]: ['USDT'],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? [];
    return memo;
}, {}) as any;

export const CUSTOM_TOKEN_BLACKLIST = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const tokens = {
        [ChainId.BSC]: [
            '0x76E4EFB2c78b08c3fdE3eDF98c6503dd9Df9C5E8', //ANT
            '0xa0D289c752FE23043f29BD81e30D8345180bD9BB', //WrappedANT,
            '0xe85afccdafbe7f2b096f268e31cce3da8da2990a', // aBNBc
        ],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? [];
    return memo;
}, {}) as any;

export const TRADE_TOKEN_BLACKLIST = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const tokens = {
        [ChainId.BSC]: [
            '0xe85afccdafbe7f2b096f268e31cce3da8da2990a', // aBNBc
        ],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? [];
    return memo;
}, {}) as any;

export const TRADE_DEFAULT_TO_TOKEN = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const tokens = {
        [ChainId.BSC]: 'BICT',
        [ChainId.Icplaza]: 'USDT',
    } as any;
    memo[chain.id] = tokens[chain.id] ?? undefined;
    return memo;
}, {}) as any;

import Web3 from "web3"
import { UNIV3_QUOTERV2, UNIV3_SWAP_ROUTER } from "../../../../../config/miscellaneous/uniswapContracts"
import { TokenInfoFormatted } from "../../../../../hooks/useTokenListFormatted"
import { UniswapV3QuoterContract } from "../../../../../types/abis/UniswapV3/UniswapV3Quoter"
import { ExactInputRequest, ExactOutputRequest, UniswapV3SwapRouterContract } from "../../../../../types/abis/UniswapV3/UniswapV3SwapRouter"
import { ChainId } from "../../../../../types/mod"
import { decodeMethodResult, getEVMContract } from "../../../../../utils/contractHelpers"
import { BasePathQueryPlugin, TokenSpenderInfo } from "../BaseDexPlugin"
import { CallingProperty, Path, PathQuery, PathQueryCalling, PathQueryResult, SwapDirection } from "../utils"
import { UniV3PreQueryResult } from "./types"
import quoterV2ABI from '../../../../../config/abi/UniswapV3/UniswapV3QuoterV2.json'
import swapRouterABI from '../../../../../config/abi/UniswapV3/UniswapV3SwapRouter.json'
import { Contract } from 'web3-eth-contract'
import { getChain } from "../../../../../config/chains"
import BigNumber from "bignumber.js"
import { priceUndecimal2PriceDecimal } from "../../../farm/iZiSwap/price"
import { QuoteExactInputResponse, QuoteExactOutputResponse } from "../../../../../types/abis/UniswapV3/UniswapV3QuoterV2"
import { amount2Decimal } from "../../../../../utils/tokenMath"
import { isGasToken } from "../../../../../config/tokens"
import { getSwapProxyContract } from "../funcs"
import { SWAP_PROXY_ADDRESS } from "../config"

function getQuoterContract(chainId: ChainId, web3: Web3): Contract | undefined {

    const address = UNIV3_QUOTERV2[chainId]

    try {
        return getEVMContract(quoterV2ABI, address, web3);
    } catch (err) {
        console.error('Failed to get pool contract', err);
        return undefined;
    }
}


function getSwapRouterContract(chainId: ChainId, web3: Web3): Contract | undefined {

    const address = UNIV3_SWAP_ROUTER[chainId]

    try {
        return getEVMContract(swapRouterABI, address, web3);
    } catch (err) {
        console.error('Failed to get pool contract', err);
        return undefined;
    }
}

export class UniV3PathQueryPlugin extends BasePathQueryPlugin {

    private chainId: ChainId
    private quoterContract: UniswapV3QuoterContract
    private quoterContractAddress: string

    private swapContract: UniswapV3SwapRouterContract
    private swapRouterAddress: string

    private amount: string = undefined as unknown as string
    private direction: SwapDirection = undefined as unknown as SwapDirection
    private tokenIn: TokenInfoFormatted = undefined as unknown as TokenInfoFormatted
    private tokenOut: TokenInfoFormatted = undefined as unknown as TokenInfoFormatted
    private swapProxy: Contract
    private swapProxyAddress: string

    public constructor(preQueryResult: UniV3PreQueryResult, config: any, chainId: ChainId, web3: Web3) {
        super(preQueryResult)
        this.chainId = chainId
        this.quoterContractAddress = UNIV3_QUOTERV2[chainId]
        this.quoterContract = getQuoterContract(chainId, web3) as unknown as UniswapV3QuoterContract
        this.swapContract = getSwapRouterContract(chainId, web3) as unknown as UniswapV3SwapRouterContract
        this.swapRouterAddress = UNIV3_SWAP_ROUTER[chainId]
        this.swapProxy = getSwapProxyContract(chainId, web3)
        this.swapProxyAddress = SWAP_PROXY_ADDRESS[this.chainId]
    }
    
    private num2Hex(n: number): string {
        if (n < 10) {
            return String(n);
        }
        const str = 'ABCDEF';
        return str[n - 10];
    }

    private fee2Hex(fee: number): string {
        const n0 = fee % 16;
        const n1 = Math.floor(fee / 16) % 16;
        const n2 = Math.floor(fee / 256) % 16;
        const n3 = Math.floor(fee / 4096) % 16;
        const n4 = 0;
        const n5 = 0;
        return '0x' + this.num2Hex(n5) + this.num2Hex(n4) + this.num2Hex(n3) + this.num2Hex(n2) + this.num2Hex(n1) + this.num2Hex(n0);
    }

    private appendHex(hexString: string, newHexString: string): string {
        return hexString + newHexString.slice(2);
    }

    private getTokenChainPath(tokenChain: TokenInfoFormatted[], feeChain: number[]): string {
        let hexString = tokenChain[0].wrapTokenAddress ?? tokenChain[0].address
        for (let i = 0; i < feeChain.length; i++) {
            hexString = this.appendHex(hexString, this.fee2Hex(feeChain[i]))
            hexString = this.appendHex(hexString, tokenChain[i + 1].wrapTokenAddress ?? tokenChain[i + 1].address)
        }
        return hexString
    }
    private getTokenChainPathReverse(tokenChain: TokenInfoFormatted[], feeChain: number[]): string {
        let hexString = tokenChain[tokenChain.length - 1].wrapTokenAddress ?? tokenChain[tokenChain.length - 1].address
        for (let i = feeChain.length - 1; i >= 0; i--) {
            hexString = this.appendHex(hexString, this.fee2Hex(feeChain[i]))
            hexString = this.appendHex(hexString, tokenChain[i].wrapTokenAddress ?? tokenChain[i].address)
        }
        return hexString
    }

    private reverse(path: Path): Path {
        return {
            ...path,
            tokenChain: path.tokenChain.slice().reverse(),
            feeRate: path.feeRate?.slice().reverse() ?? undefined,
            feeContractNumber: path.feeContractNumber.slice().reverse()
        }
    }

    override getPathQuery(tokenIn: TokenInfoFormatted, tokenOut: TokenInfoFormatted, direction: SwapDirection, amount: string): PathQuery[] {

        this.tokenIn = tokenIn
        this.tokenOut = tokenOut
        this.direction = direction
        this.amount = amount

        const preQueryResult = this.preQueryResult as UniV3PreQueryResult
        
        const pathQuery = [] as PathQuery[]
        for (const path of preQueryResult.pathWithOutFee100) {
            const p = (path.tokenChain[0].symbol === tokenIn.symbol) ? path : this.reverse(path)
            if (!p.feeRate) {
                p.feeRate = p.feeContractNumber.map((e)=>e/1e6)
            }
            const pathChain = (direction === SwapDirection.ExactIn) ? this.getTokenChainPath(p.tokenChain, p.feeContractNumber) : this.getTokenChainPathReverse(p.tokenChain, p.feeContractNumber)
            const calling = (direction === SwapDirection.ExactIn) ? this.quoterContract.methods.quoteExactInput(pathChain, amount) : this.quoterContract.methods.quoteExactOutput(pathChain, amount)
            pathQuery.push({
                path: p,
                pathQueryCalling: {
                    calling: calling.encodeABI(),
                    targetAddress: this.quoterContractAddress,
                    callingProperty: CallingProperty.Short
                } as PathQueryCalling
            } as PathQuery)
        }

        for (const path of preQueryResult.pathWithFee100) {
            const p = (path.tokenChain[0].symbol === tokenIn.symbol) ? path : this.reverse(path)
            if (!p.feeRate) {
                p.feeRate = p.feeContractNumber.map((e)=>e/1e6)
            }
            const pathChain = (direction === SwapDirection.ExactIn) ? this.getTokenChainPath(p.tokenChain, p.feeContractNumber) : this.getTokenChainPathReverse(p.tokenChain, p.feeContractNumber)
            const calling = (direction === SwapDirection.ExactIn) ? this.quoterContract.methods.quoteExactInput(pathChain, amount) : this.quoterContract.methods.quoteExactOutput(pathChain, amount)
            pathQuery.push({
                path: p,
                pathQueryCalling: {
                    calling: calling.encodeABI(),
                    targetAddress: this.quoterContractAddress,
                    callingProperty: CallingProperty.Long
                } as PathQueryCalling
            } as PathQuery)
        }
        
        return pathQuery
    }

    private noSufficientLiquidity(path: Path, sqrtPriceX96AfterList: string[]): boolean {
        for (let i = 0; i < path.feeContractNumber.length; i++) {
            const tokenA = path.tokenChain[i]
            const tokenB = path.tokenChain[i + 1]
            const sqrtPriceX96 = new BigNumber(sqrtPriceX96AfterList[i])
            if (tokenA.address.toLowerCase() < tokenB.address.toLowerCase()) {
                // x2y mode
                if (sqrtPriceX96.lte('4295128739')) {
                    return true
                }
            } else {
                // y2x mode
                if (sqrtPriceX96.gte('1461446703485210103287273052203988822378723970342')) {
                    return true
                }
            }
        }
        return false
    }

    private estimateFee(path: Path, inputAmount: number): number {
        let remainAmount = inputAmount
        for (const fee of path.feeContractNumber) {
            remainAmount = remainAmount - remainAmount * fee / 1e6
        }
        return inputAmount - remainAmount
    }
    private getSwapPoolKey(tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted, feeContractNumber: number) : string {
        const tokenASymbol = tokenA.symbol.toUpperCase()
        const tokenBSymbol = tokenB.symbol.toUpperCase()
        if (tokenASymbol < tokenBSymbol) {
            return tokenASymbol + '-' + tokenBSymbol + '-' + String(feeContractNumber)
        } else {
            return tokenBSymbol + '-' + tokenASymbol + '-' + String(feeContractNumber)
        }
    }
    private getOriginUndecimalPriceBackByFront(path: Path): BigNumber[] {
        const ret = [] as BigNumber[]
        const preQueryResult = this.preQueryResult as UniV3PreQueryResult
        for (let i = 0; i < path.feeContractNumber.length; i ++) {
            const tokenA = path.tokenChain[i]
            const tokenB = path.tokenChain[i + 1]
            const fee = path.feeContractNumber[i]
            const swapPoolKey = this.getSwapPoolKey(tokenA, tokenB, fee)
            const sqrtPriceX96 = preQueryResult.poolSqrtPriceX96.get(swapPoolKey) as string
            const undecimalPrice = new BigNumber(sqrtPriceX96).div(2 ** 96).pow(2)
            if (tokenA.address.toLowerCase() > tokenB.address.toLowerCase()) {
                ret.push(undecimalPrice)
            } else {
                ret.push(new BigNumber(1).div(undecimalPrice))
            }
        }
        return ret
    }

    private getUndecimalPriceBackByFrontBySqrtPriceX96(path: Path, sqrtPriceX96: string[]): BigNumber[] {
        const ret = [] as BigNumber[]
        for (let i = 0; i < path.feeContractNumber.length; i ++) {
            const tokenA = path.tokenChain[i]
            const tokenB = path.tokenChain[i + 1]
            const fee = path.feeContractNumber[i]
            const undecimalPrice = new BigNumber(sqrtPriceX96[i]).div(2 ** 96).pow(2)
            if (tokenA.address.toLowerCase() > tokenB.address.toLowerCase()) {
                ret.push(undecimalPrice)
            } else {
                ret.push(new BigNumber(1).div(undecimalPrice))
            }
        }
        return ret
    }
    
    private getPriceDecimalEndByStart(path: Path, undecimalPriceBackByFront: BigNumber[]): number {
        let decimalPriceEndByStart = 1
        for (let i = 0; i < path.feeContractNumber.length; i++) {
            const decimalPriceBackByFront = priceUndecimal2PriceDecimal(path.tokenChain[i + 1], path.tokenChain[i], undecimalPriceBackByFront[i])
            decimalPriceEndByStart *= decimalPriceBackByFront
        }
        return decimalPriceEndByStart
    }

    override parseCallingResponse(path: Path, direction: SwapDirection, amount: string, result: string): PathQueryResult {
        let responseAmount = '0'
        let sqrtPriceX96AfterList = [] as string[]
        if (direction === SwapDirection.ExactIn) {
            const quoteExactInRes: QuoteExactInputResponse = decodeMethodResult(this.quoterContract as unknown as Contract, 'quoteExactInput', result)
            responseAmount = quoteExactInRes.amountOut
            sqrtPriceX96AfterList = quoteExactInRes.sqrtPriceX96AfterList
        } else {
            const quoteExactOutRes: QuoteExactOutputResponse = decodeMethodResult(this.quoterContract as unknown as Contract, 'quoteExactOutput', result)
            responseAmount = quoteExactOutRes.amountIn
            sqrtPriceX96AfterList = quoteExactOutRes.sqrtPriceX96AfterList.slice().reverse()
        }
        const noSufficientLiquidity = this.noSufficientLiquidity(path, sqrtPriceX96AfterList)
        const inputAmount = (this.direction === SwapDirection.ExactIn) ? amount : responseAmount
        const inputAmountDecimal = Number(amount2Decimal(new BigNumber(inputAmount), this.tokenIn))
        const feesDecimal = this.estimateFee(path, inputAmountDecimal)
        const initUndecimalPriceBackByFront = this.getOriginUndecimalPriceBackByFront(path)
        const afterUndecimalPriceBackByFront = this.getUndecimalPriceBackByFrontBySqrtPriceX96(path, sqrtPriceX96AfterList)

        const initDecimalPriceEndByStart = this.getPriceDecimalEndByStart(path, initUndecimalPriceBackByFront)

        const afterDecimalPriceEndByStart = this.getPriceDecimalEndByStart(path, afterUndecimalPriceBackByFront)
        const impact = Math.abs((afterDecimalPriceEndByStart - initDecimalPriceEndByStart) / initDecimalPriceEndByStart)
        const priceImpact = impact
        return {
            amount: responseAmount,
            path,
            noSufficientLiquidity,
            initDecimalPriceEndByStart,
            priceImpact,
            feesDecimal
        } as PathQueryResult
    }

    override getTokenSpenderInfo(path: Path, direction: SwapDirection): TokenSpenderInfo {
        const tokenToPay = path.tokenChain[0]
        if (this.isChainToken(tokenToPay)) {
            return {tokenToPay}
        }
        return {tokenToPay, spenderAddress: this.swapProxyAddress}
    }

    private isChainToken(token: TokenInfoFormatted): boolean {
        const chainToken = getChain(this.chainId)?.token
        const chainTokenAddress = chainToken?.address?.toLowerCase() as string
        const tokenAddress = token.address.toLowerCase()
        return chainTokenAddress === tokenAddress
    }

    private swapExactETHForTokens(swapParams: ExactInputRequest) {
        const swap = this.swapContract.methods.exactInput(swapParams).encodeABI()
        const refund = this.swapContract.methods.refundETH().encodeABI()
        return this.swapContract.methods.multicall([swap, refund])
    }
    private swapExactTokensForETH(swapParams: ExactInputRequest) {
        const recipient = swapParams.recipient
        swapParams.recipient = this.swapRouterAddress
        const swap = this.swapContract.methods.exactInput(swapParams).encodeABI()
        const unwrap = this.swapContract.methods.unwrapWETH9('0', recipient).encodeABI()
        return this.swapContract.methods.multicall([swap, unwrap])
    }
    private swapExactTokensForTokens(swapParams: ExactInputRequest) {
        return this.swapContract.methods.exactInput(swapParams)
    }
    private swapETHForExactTokens(swapParams: ExactOutputRequest) {
        const swap = this.swapContract.methods.exactOutput(swapParams).encodeABI()
        const refund = this.swapContract.methods.refundETH().encodeABI()
        return this.swapContract.methods.multicall([swap, refund])
    }
    private swapTokensForExactETH(swapParams: ExactOutputRequest) {
        const recipient = swapParams.recipient
        swapParams.recipient = this.swapRouterAddress
        const swap = this.swapContract.methods.exactOutput(swapParams).encodeABI()
        const unwrap = this.swapContract.methods.unwrapWETH9('0', recipient).encodeABI()
        return this.swapContract.methods.multicall([swap, unwrap])
    }
    private swapTokensForExactTokens(swapParams: ExactOutputRequest) {
        return this.swapContract.methods.exactOutput(swapParams)
    }
    override getSwapTransaction(
        path: Path, 
        direction: SwapDirection, 
        amountIn: string, 
        amountOut: string, 
        account: string, 
        maxDelay: number,
        slippagePercent: number
    ): {calling: any, options: any} {
        
        const deadline = String(Math.floor((new Date()).valueOf() / 1000) + maxDelay * 60)
        const inputIsChainToken = this.isChainToken(path.tokenChain[0])
        const outputIsChainToken = this.isChainToken(path.tokenChain[path.tokenChain.length - 1])
        const cost = (direction === SwapDirection.ExactIn)? amountIn : (new BigNumber(amountIn)).times(100 + slippagePercent).div(100).toFixed(0)
        let acquire = (direction === SwapDirection.ExactOut)? amountOut: (new BigNumber(amountOut)).times(100 - slippagePercent).div(100).toFixed(0)

        const inputAddress = path.tokenChain[0].address

        if (direction === SwapDirection.ExactIn) {
            const swapParams = {
                path: this.getTokenChainPath(path.tokenChain, path.feeContractNumber),
                recipient: account,
                deadline,
                amountIn,
                amountOutMinimum: acquire
            } as ExactInputRequest
            if (inputIsChainToken) {
                const msgValue = cost

                const swapExactETHForTokens = this.swapExactETHForTokens(swapParams)

                const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapExactETHForTokens.encodeABI(), msgValue).encodeABI()
                const refund = this.swapProxy.methods.refundETH().encodeABI()
                return {
                    calling: this.swapProxy.methods.multicall([proxy, refund]),
                    options: {value: msgValue}
                }
            } else if (outputIsChainToken) {
                const deposit = this.swapProxy.methods.depositToken(inputAddress, cost).encodeABI()
                const approve = this.swapProxy.methods.approveToken(inputAddress, this.swapRouterAddress).encodeABI()
                const swapExactTokensForETH = this.swapExactTokensForETH(swapParams)
                const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapExactTokensForETH.encodeABI(), '0').encodeABI()
                const sweepToken = this.swapProxy.methods.sweepToken(inputAddress, account).encodeABI()
                return {
                    calling: this.swapProxy.methods.multicall([deposit, approve, proxy, sweepToken]),
                    options: {}
                }
            } else {
                const deposit = this.swapProxy.methods.depositToken(inputAddress, cost).encodeABI()
                const approve = this.swapProxy.methods.approveToken(inputAddress, this.swapRouterAddress).encodeABI()
                const swapExactTokensForTokens = this.swapExactTokensForTokens(swapParams)
                const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapExactTokensForTokens.encodeABI(), '0').encodeABI()
                const sweepToken = this.swapProxy.methods.sweepToken(inputAddress, account).encodeABI()
                return {
                    calling: this.swapProxy.methods.multicall([deposit, approve, proxy, sweepToken]),
                    options: {}
                }
            }
        } else {
            const swapParams = {
                path: this.getTokenChainPathReverse(path.tokenChain, path.feeContractNumber),
                recipient: account,
                deadline,
                amountOut,
                amountInMaximum: cost
            } as ExactOutputRequest
            if (inputIsChainToken) {
                const msgValue = cost
                const swapETHForExactTokens = this.swapETHForExactTokens(swapParams)
                const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapETHForExactTokens.encodeABI(), msgValue).encodeABI()
                const refund = this.swapProxy.methods.refundETH().encodeABI()
                return {
                    calling: this.swapProxy.methods.multicall([proxy, refund]),
                    options: {value: msgValue}
                }
            } else if (outputIsChainToken) {
                const deposit = this.swapProxy.methods.depositToken(inputAddress, cost).encodeABI()
                const approve = this.swapProxy.methods.approveToken(inputAddress, this.swapRouterAddress).encodeABI()
                const swapTokensForExactETH = this.swapTokensForExactETH(swapParams)
                const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapTokensForExactETH.encodeABI(), '0').encodeABI()
                const sweep = this.swapProxy.methods.sweepToken(inputAddress, account).encodeABI()
                return {
                    calling: this.swapProxy.methods.multicall([deposit, approve, proxy, sweep]),
                    options: {}
                }
            } else {
                const deposit = this.swapProxy.methods.depositToken(inputAddress, cost).encodeABI()
                const approve = this.swapProxy.methods.approveToken(inputAddress, this.swapRouterAddress).encodeABI()
                const swapTokensForExactTokens = this.swapTokensForExactTokens(swapParams)
                const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapTokensForExactTokens.encodeABI(), '0').encodeABI()
                const sweep = this.swapProxy.methods.sweepToken(inputAddress, account).encodeABI()
                return {
                    calling: this.swapProxy.methods.multicall([deposit, approve, proxy, sweep]),
                    options: {}
                }
            }
        }
    }

}
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../../state/store';
import { HistoryList } from './HistoryList';
import { ActiveList } from './ActiveList';
import { useLimitOrderManagerContract } from '../../../../../hooks/useContracts';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { FetchLimitOrderParams } from '../../../../../state/models/trade/limitOrder/tradeLimitOrder';
import { useInterval } from 'ahooks';
import { A_LONG_FUTURE_TIME, IZUMI_SWAP_CONFIG } from '../../../../../config/bizConfig';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { BIG_MAX_UNIT128 } from '../../../../../utils/bigNumber';
import { TransactionReceipt } from 'ethereum-abi-types-generator';
import { getLimitOrderManagerAddress } from '../../../../../utils/contractFactory';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../../../hooks/useTokenListFormatted';
import { isGasToken } from '../../../../../config/tokens';

export type LimitOrderHandler = {
    handleRefreshOrderList: () => void;
    handleCancelOrder: (orderId: string, tokenX: TokenInfoFormatted, tokenY: TokenInfoFormatted) => Promise<TransactionReceipt>;
    handleClaimOrder: (orderId: string, tokenX: TokenInfoFormatted, tokenY: TokenInfoFormatted) => Promise<TransactionReceipt>;
};

export const MyLimitOrdersContainer: React.FC<unknown> = () => {
    const { chainId, web3, account } = useWeb3WithDefault();
    const { tradeLimitOrder } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { tokenList } = useTokenListFormatted();

    const limitOrderManagerContract = useLimitOrderManagerContract();
    const limitOrderManagerAddress = getLimitOrderManagerAddress(chainId);

    const refreshLimitOrder = useCallback(
        () =>
            dispatch.tradeLimitOrder
                .fetchLimitOrder({
                    chainId,
                    web3,
                    account,
                    orderManagerContract: limitOrderManagerContract,
                    tokenList,
                } as FetchLimitOrderParams)
                .catch((e) => console.log('refreshLimitOrder: ', e)),
        [chainId, account, web3, tokenList]
    );

    const handleCancelOrder = useCallback(
        (orderId: string, tokenX: TokenInfoFormatted, tokenY: TokenInfoFormatted) => {
            if (!limitOrderManagerContract || !limitOrderManagerAddress || !account) {
                return new Promise<TransactionReceipt>((_, reject) => reject('invalid OrderManagerContract or Account'));
            }
            const tokenXIsChainCoin = isGasToken(tokenX, chainId);
            const tokenYIsChainCoin = isGasToken(tokenY, chainId);
            const hasChainCoin = tokenXIsChainCoin || tokenYIsChainCoin;
            const cancelCall = limitOrderManagerContract.methods
                .decLimOrder(orderId, BIG_MAX_UNIT128.toString(), String(A_LONG_FUTURE_TIME))
                .encodeABI();
            const recipient = hasChainCoin ? limitOrderManagerAddress : account;
            const collectCall = limitOrderManagerContract.methods
                .collectLimOrder(recipient!, orderId, BIG_MAX_UNIT128.toHexString(), BIG_MAX_UNIT128.toHexString())
                .encodeABI();
            const callList: string[] = [cancelCall, collectCall];
            if (hasChainCoin) {
                const unwrapWETH9 = limitOrderManagerContract.methods.unwrapWETH9('0', account!).encodeABI();
                callList.push(unwrapWETH9);
                const tokenNotEth = tokenXIsChainCoin ? tokenY.address : tokenX.address;
                const sweepToken = limitOrderManagerContract.methods.sweepToken(tokenNotEth, '0', account!).encodeABI();
                callList.push(sweepToken);
            }
            return limitOrderManagerContract.methods.multicall(callList).send({ from: account! });
        },
        [limitOrderManagerContract, limitOrderManagerAddress, account, chainId]
    );

    const handleClaimOrder = useCallback(
        (orderId: string, tokenX: TokenInfoFormatted, tokenY: TokenInfoFormatted) => {
            if (!limitOrderManagerContract || !limitOrderManagerAddress || !account) {
                return new Promise<TransactionReceipt>((_, reject) => reject('invalid OrderManagerContract or account'));
            }
            const tokenXIsChainCoin = isGasToken(tokenX, chainId);
            const tokenYIsChainCoin = isGasToken(tokenY, chainId);
            const tokenNotEth = tokenXIsChainCoin ? tokenY.address : tokenX.address;

            if (tokenYIsChainCoin || tokenXIsChainCoin) {
                const callList: string[] = [];
                callList.push(
                    limitOrderManagerContract.methods
                        .collectLimOrder(limitOrderManagerAddress, orderId, BIG_MAX_UNIT128.toHexString(), BIG_MAX_UNIT128.toHexString())
                        .encodeABI()
                );
                callList.push(limitOrderManagerContract.methods.unwrapWETH9('0', account!).encodeABI());

                callList.push(limitOrderManagerContract.methods.sweepToken(tokenNotEth, '0', account!).encodeABI());
                return limitOrderManagerContract.methods.multicall(callList).send({ from: account! });
            } else {
                return limitOrderManagerContract.methods
                    .collectLimOrder(account!, orderId, BIG_MAX_UNIT128.toHexString(), BIG_MAX_UNIT128.toHexString())
                    .send({ from: account! });
            }
        },
        [limitOrderManagerContract, limitOrderManagerAddress, account, chainId]
    );

    useEffect(() => {
        console.info('trigger refreshLimitOrder', account);
        refreshLimitOrder();
    }, [chainId, account, tokenList]);

    useInterval(() => {
        console.log('auto trigger refreshLimitOrder', chainId, account);
        refreshLimitOrder();
    }, IZUMI_SWAP_CONFIG.AUTO_REFRESH_LIMIT_ORDER_DATA_INTERVAL);

    return tradeLimitOrder.control.type === 'active' ? (
        <ActiveList
            handleCancelOrder={handleCancelOrder}
            handleClaimOrder={handleClaimOrder}
            handleRefreshOrderList={refreshLimitOrder}
            activeOrderList={tradeLimitOrder.activeList}
        />
    ) : (
        <HistoryList historyOrderList={tradeLimitOrder.historyList} />
    );
};

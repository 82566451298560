import { HStack, useColorMode, BoxProps, VStack } from '@chakra-ui/react';
import React from 'react';
import { Text } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { getColorThemeSelector } from '../../../utils/funcs';
import { i_text_d } from '../../../style';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { CustomNumberInput } from '../../../iZUMi-UI-toolkit/src/components/Inputs/NumberInput/NumberInput';
import { formatNumber } from '../../../utils/tokenMath';
import { isNumeric } from '../../../utils/valid';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import { useTranslation } from 'react-i18next';
import { AdaptationMode } from '../../../components/layout/PageLayout';

export type AmountInputProps = {
    token: TokenInfoFormatted;
    balance: number | string | null;
    price?: number | null;
    inputValue: any;
    handleSetValue?: (v: any) => void;
    errorInfo?: string;
    notShowError?: boolean;
    fontClass?: any;
    notShowShortcut?: boolean;
    isSimpleMode?: boolean;
    setIsInputFocus?: any;
    disabled?: boolean;
    type?: AdaptationMode;
} & BoxProps;

export const AmountInput: React.FC<AmountInputProps> = (props) => {
    const {
        //token,
        balance,
        price,
        handleSetValue,
        errorInfo,
        inputValue,
        notShowShortcut,
        isSimpleMode,
        setIsInputFocus,
        disabled,
        type,
        notShowError,
        ...rest
    } = props;

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();

    return type === 'mobile' ? (
        <VStack spacing="0" w="100%" h="100%" position="relative">
            <HStack w="100%" pl="4px" h="25px" justifyContent="space-between" my="auto">
                <CustomNumberInput
                    disabled={disabled ?? false}
                    onBlur={handleSetValue}
                    errorInfo={errorInfo}
                    inputValue={inputValue}
                    setIsInputFocus={setIsInputFocus}
                    type={'mobile'}
                    {...rest}
                />
            </HStack>

            <VStack spacing="8px" w="100%" px="4px" alignItems="flex-end">
                <Text
                    variant="caption"
                    color={colorTheme('tertiary.300', '#655079')}
                    fontSize="12px"
                    whiteSpace="nowrap"
                    textAlign="center"
                >
                    ≈ {isNumeric(inputValue) ? formatNumber(inputValue * (price || 1)) : 0} USD
                </Text>
                {!notShowShortcut && (
                    <HStack>
                        {[t('Clear'), t('MAX')].map((item, index) => (
                            <CustomButton
                                onClick={() => {
                                    if (item === t('Clear')) {
                                        item = '0';
                                    }
                                    if (item === t('MAX')) {
                                        item = '99.99999';
                                    }
                                    handleSetValue?.((Number(item) / 100) * Number(balance));
                                }}
                                key={index}
                                w="46px"
                                h="22px"
                                borderRadius="3px"
                                alignItems="center"
                                justifyContent="center"
                                variant={item === t('Clear') ? 'orange' : 'primary2'}
                                text={typeof item === 'number' ? item + '%' : item}
                                fontClass={i_text_d}
                                fontSize="12px"
                                bgColor={item === t('Clear') ? colorTheme('#F6EAEA', '#68466D') : colorTheme('#ECF1F7', '#5B5692')}
                                color={item === t('Clear') ? colorTheme('#816D6F', '#EDB2B8') : colorTheme('#636A7D', '#BBB0FF')}
                            />
                        ))}
                    </HStack>
                )}
            </VStack>
        </VStack>
    ) : (
        <VStack spacing="0" w="100%" h="100%" position="relative">
            <HStack w="100%" pl="4px" h="50px" justifyContent="space-between" my="auto">
                <CustomNumberInput
                    disabled={disabled ?? false}
                    onBlur={handleSetValue}
                    errorInfo={errorInfo}
                    inputValue={inputValue}
                    setIsInputFocus={setIsInputFocus}
                    notShowError={notShowError}
                    {...rest}
                />
                {(isSimpleMode || notShowShortcut) && (
                    <Text
                        variant="caption"
                        color={colorTheme('tertiary.600', 'tertiary.400')}
                        pr="6px !important"
                        fontSize="12px"
                        w="40%"
                        whiteSpace="nowrap"
                        textAlign="center"
                    >
                        ~ {isNumeric(inputValue) ? formatNumber(inputValue * (price || 1)) : 0} USD
                    </Text>
                )}
            </HStack>
            {!notShowShortcut && (
                <HStack spacing="5px" w="100%" h="22px" px="5px">
                    {!isSimpleMode && (
                        <Text
                            variant="caption"
                            color={colorTheme('tertiary.600', 'tertiary.400')}
                            pr="6px !important"
                            fontSize="12px"
                            w="100%"
                            whiteSpace="nowrap"
                            textAlign="center"
                        >
                            ~ {isNumeric(inputValue) ? formatNumber(inputValue * (price || 1)) : 0} USD
                        </Text>
                    )}
                    <HStack ml="auto !important">
                        {[t('Clear'), t('MAX')].map((item, index) => (
                            <CustomButton
                                onClick={() => {
                                    if (item === t('Clear')) {
                                        item = '0';
                                    }
                                    if (item === t('MAX')) {
                                        item = '99.99999';
                                    }
                                    handleSetValue?.((Number(item) / 100) * Number(balance));
                                }}
                                key={index}
                                w="46px"
                                h="22px"
                                borderRadius="3px"
                                alignItems="center"
                                justifyContent="center"
                                variant={item === t('Clear') ? 'orange' : 'primary2'}
                                text={typeof item === 'number' ? item + '%' : item}
                                fontClass={i_text_d}
                                fontSize="12px"
                            />
                        ))}
                    </HStack>
                </HStack>
            )}
        </VStack>
    );
};

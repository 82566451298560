import { useState, useEffect } from 'react';
import { useWeb3WithDefault } from './useWeb3WithDefault';
import useInterval from 'ahooks/lib/useInterval';
import { CHAIN_CONFIG } from '../config/bizConfig';
import { chainPriorityFee } from '../config/chains';

export const useGasPrice = () => {
    const [gasPrice, setGasPrice] = useState(0);
    const { web3, chainId } = useWeb3WithDefault();

    useInterval(() => {
        web3.eth.getGasPrice().then((v: any) => {
            // setGasPrice(v);
            // console.log('gas price: ', v);
            const priorityFee = chainPriorityFee[chainId] ?? 0;
            setGasPrice(Number(v) + priorityFee * 1e9);
        });
    }, CHAIN_CONFIG.AUTO_REFRESH_GAS_PRICE_INTERVAL);

    useEffect(() => {
        web3.eth.getGasPrice().then((v: any) => {
            // setGasPrice(v);
            const priorityFee = chainPriorityFee[chainId] ?? 0;
            setGasPrice(Number(v) + priorityFee * 1e9);
        });
    }, [web3, chainId]);

    return { gasPrice };
};

import { BigNumber } from "bignumber.js";
import { TransactionReceipt } from "ethereum-abi-types-generator";
import { isGasToken } from "../../../../config/tokens";
import { TokenInfoFormatted } from "../../../../hooks/useTokenListFormatted";
import { LimitOrderWithSwapManagerContract } from "../../../../types/abis/iZiSwap/LimitOrderWithSwapManager";
import { SwapAmountSingleRequest } from "../../../../types/abis/iZiSwap/SwapSingle";
import { ChainId } from "../../../../types/mod";
import { buildSendingParams } from "../../../../utils/contractHelpers";
import { toContractFeeNumber } from "../../../../utils/funcs";
import { getSwapTokenAddress } from "../../../../utils/tokenMath";

export const swapAmount = (
    chainId: ChainId,
    tokenIn: TokenInfoFormatted,
    tokenOut: TokenInfoFormatted,
    fee: FeeTier,
    amountIn: string, 
    minAcquired: string,
    account: string, 
    maxDelay: number,
    swapSingleContract: LimitOrderWithSwapManagerContract,
    swapSingleAddress: string,
): {calling: any, options: any} => {

    const tokenInAddress = getSwapTokenAddress(tokenIn)
    const tokenOutAddress = getSwapTokenAddress(tokenOut)

    const ifBuyETH = isGasToken(tokenOut, chainId)
    const costETH = isGasToken(tokenIn, chainId) ? amountIn : '0'
    const feeContractNumber = toContractFeeNumber(fee)

    const swapParams = {
        tokenIn: tokenInAddress,
        tokenOut: tokenOutAddress,
        fee: feeContractNumber,
        amount: amountIn,
        minAcquiredOrMaxPayed: minAcquired,
        recipient: account,
        deadline: String(Math.round(new Date().getTime() / 1000) + maxDelay * 60)
    } as SwapAmountSingleRequest

    if (ifBuyETH) {
        swapParams.recipient = swapSingleAddress
        const multicall: string[] = []
        multicall.push(swapSingleContract.methods.swapAmountSingle(swapParams).encodeABI())
        multicall.push(swapSingleContract.methods.unwrapWETH9('0', account).encodeABI())
        const calling = swapSingleContract.methods.multicall(multicall)
        const options = { from: account, value: costETH }
        return {calling, options}
    } else {
        if (costETH !== '0') {
            const multicall: string[] = [];
            multicall.push(swapSingleContract.methods.swapAmountSingle(swapParams).encodeABI());
            multicall.push(swapSingleContract.methods.refundETH().encodeABI());
            const calling = swapSingleContract.methods.multicall(multicall)
            const options = { from: account, value: costETH }
            return {calling, options}
        } else {
            const calling = swapSingleContract.methods.swapAmountSingle(swapParams)
            const options = { from: account }
            return {calling, options}
        }
    }
}

export const swapDesire = (
    chainId: ChainId,
    tokenIn: TokenInfoFormatted,
    tokenOut: TokenInfoFormatted,
    fee: FeeTier,
    amountOut: string,
    maxPayed: string,
    account: string, 
    maxDelay: number,
    swapSingleContract: LimitOrderWithSwapManagerContract,
    swapSingleAddress: string,
): {calling: any, options: any} => {

    const tokenInAddress = getSwapTokenAddress(tokenIn)
    const tokenOutAddress = getSwapTokenAddress(tokenOut)
    const feeContractNumber = toContractFeeNumber(fee)

    const swapParams = {
        tokenIn: tokenInAddress,
        tokenOut: tokenOutAddress,
        fee: feeContractNumber,
        amount: amountOut,
        minAcquiredOrMaxPayed: maxPayed,
        recipient: account,
        deadline: String(Math.round(new Date().getTime() / 1000) + maxDelay * 60)
    } as SwapAmountSingleRequest

    const ifBuyETH = isGasToken(tokenOut, chainId);
    if (ifBuyETH) {
        swapParams.recipient = swapSingleAddress
    }
    const costETH = isGasToken(tokenIn, chainId) ? swapParams.minAcquiredOrMaxPayed : '0';
    const multicall: any[] = [];
    multicall.push(swapSingleContract.methods.swapDesireSingle(swapParams));
    if (ifBuyETH) {
        multicall.push(swapSingleContract.methods.unwrapWETH9('0', account));
    }
    if (new BigNumber(costETH).gt('0')) {
        multicall.push(swapSingleContract.methods.refundETH());
    }
    const calling = (multicall.length > 1) ? swapSingleContract.methods.multicall(multicall.map((c)=>c.encodeABI())) : multicall[0]
    const options = { from: account, value: costETH }
    return {calling, options}
}

export const sellSingleAmount = (
    baseToken: TokenInfoFormatted, 
    quoteToken: TokenInfoFormatted,
    fee: FeeTier,
    sellAmount: string,
    minAcquired: string,
    chainId: ChainId,
    account: string, 
    maxDelay: number,
    swapSingleContract: LimitOrderWithSwapManagerContract,
    swapSingleAddress: string,
    gasPrice: number,
    onGoingCallback: ()=>void = undefined as any,
): Promise<TransactionReceipt> => {

    const {calling, options} = swapAmount(
        chainId,
        baseToken,
        quoteToken,
        fee,
        sellAmount,
        minAcquired,
        account,
        maxDelay,
        swapSingleContract,
        swapSingleAddress
    )

    return calling.send(buildSendingParams(chainId, options, gasPrice)).on(
        'transactionHash',
        () => {
            if (typeof onGoingCallback != 'undefined') {
                onGoingCallback();
            }
        }
    )
}


export const buySingleDesire = (
    baseToken: TokenInfoFormatted, 
    quoteToken: TokenInfoFormatted,
    fee: FeeTier,
    buyAmount: string,
    maxPayed: string,
    chainId: ChainId,
    account: string, 
    maxDelay: number,
    swapSingleContract: LimitOrderWithSwapManagerContract,
    swapSingleAddress: string,
    gasPrice: number,
    onGoingCallback: ()=>void = undefined as any,
): Promise<TransactionReceipt> => {

    const {calling, options} = swapDesire(
        chainId,
        quoteToken,
        baseToken,
        fee,
        buyAmount,
        maxPayed,
        account,
        maxDelay,
        swapSingleContract,
        swapSingleAddress
    )

    return calling.send(buildSendingParams(chainId, options, gasPrice)).on(
        'transactionHash',
        () => {
            if (typeof onGoingCallback != 'undefined') {
                onGoingCallback();
            }
        }
    )
}
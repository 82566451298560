import { tokenSymbol2token } from "../../../../../config/tokens"
import { ChainId, TokenSymbol } from "../../../../../types/mod"

export const SupportFeeTiers = {
    [ChainId.BSC]: [0.01, 0.04, 0.2, 1], // ${fee}%
    [ChainId.BSCTestnet]: [0.01, 0.04, 0.2, 1],
    [ChainId.Aurora]: [0.01, 0.04, 0.2, 1],
    [ChainId.AuroraTestnet]: [0.01, 0.04, 0.2, 1],
    [ChainId.ETC]: [0.01, 0.04, 0.2, 1],
    [ChainId.Arbitrum]: [0.01, 0.04, 0.2, 1],
    [ChainId.Matic]: [0.01, 0.04, 0.2, 1],
    [ChainId.Cronos]: [0.01, 0.04, 0.2, 1],
    [ChainId.MantleTest]: [0.01, 0.04, 0.2, 1],
    [ChainId.ZkSyncAlphaTest]: [0.01, 0.04, 0.2, 1],
    [ChainId.ScrollTestL2]: [0.01, 0.04, 0.2, 1],
    [ChainId.Icplaza]: [0.01, 0.04, 0.2, 1],
} as Record<ChainId, FeeTier[]>;

export const SUPPORTED_001_POOL = {
    [ChainId.BSC]: [
        {
            tokenA: tokenSymbol2token(TokenSymbol.IUSD, ChainId.BSC),
            tokenB: tokenSymbol2token(TokenSymbol.USDT, ChainId.BSC),
        },
        {
            tokenA: tokenSymbol2token(TokenSymbol.IUSD, ChainId.BSC),
            tokenB: tokenSymbol2token(TokenSymbol.BUSD, ChainId.BSC),
        },
    ],
    [ChainId.BSCTestnet]: [],
    [ChainId.Aurora]: [],
    [ChainId.AuroraTestnet]: [],
    [ChainId.ETC]: [],
    [ChainId.MantleTest]: [],
    [ChainId.ZkSyncAlphaTest]: [],
    [ChainId.ScrollTestL2]: [],
} as any;


export const MULTIHOP_MID_TOKEN_SYMBOL = {
    [ChainId.BSC]: [TokenSymbol.BUSD, TokenSymbol.BNB, TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.IUSD],
    [ChainId.BSCTestnet]: [TokenSymbol.BNB, TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.IUSD],
    [ChainId.Aurora]: [TokenSymbol.ETH, TokenSymbol.USDC, TokenSymbol.USDT, TokenSymbol.USN],
    [ChainId.AuroraTestnet]: [TokenSymbol.ETH, TokenSymbol.USDC, TokenSymbol.USDT, TokenSymbol.USN],
    [ChainId.ETC]: [TokenSymbol.ETC, TokenSymbol.IUSD],
    [ChainId.MantleTest]: [TokenSymbol.USDT],
} as Record<ChainId, TokenSymbol[]>;


export const STABLE_COIN_SYMBOL = {
    [ChainId.BSC]: [TokenSymbol.BUSD, TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.IUSD],
    [ChainId.BSCTestnet]: [TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.IUSD],
    [ChainId.Aurora]: [TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.USN],
    [ChainId.AuroraTestnet]: [TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.USN],
    [ChainId.ETC]: [TokenSymbol.IUSD],
    [ChainId.MantleTest]: [TokenSymbol.USDT],
} as Record<ChainId, TokenSymbol[]>;
import BigNumber from "bignumber.js";
import Web3 from "web3";
import { getChain } from "../../../../../config/chains";
import { TokenInfoFormatted } from "../../../../../hooks/useTokenListFormatted";
import { ChainId } from "../../../../../types/mod";
import { amount2Decimal } from "../../../../../utils/tokenMath";
import { BasePathQueryPlugin, TokenSpenderInfo } from "../BaseDexPlugin";
import { SWAP_PROXY_ADDRESS } from "../config";
import { Path, PathQuery, SwapDirection } from "../utils";
import { PANCAKE_FEE_RATE, PANCAKE_SWAP_ROUTER_ADDRESS } from "./config";
import { PancakePairState, PancakePreQueryResult } from "./types";
import { Contract } from 'web3-eth-contract'
import { getContract } from "../../../../../utils/contractFactory";

import swapRouterABI from '../../../../../config/abi/pancake/swapRouter.json'
import { getSwapProxyContract } from "../funcs";
import { IZUMI_SWAP_CONFIG } from "../../../../../config/bizConfig";


function priceUndecimal2PriceDecimal (
    tokenA: TokenInfoFormatted,
    tokenB: TokenInfoFormatted,
    priceUndecimalAByB: BigNumber): number {
    // priceUndecimalAByB * amountA = amountB
    // priceUndecimalAByB * amountADecimal * 10^decimalA = amountBDecimal * 10^decimalB
    // priceUndecimalAByB * 10^decimalA / 10^decimalB * amountA = amountB
    return Number(priceUndecimalAByB.times(10 ** tokenA.decimal).div(10 **tokenB.decimal))
}

function getSwapRouterContract(chainId: ChainId, web3: Web3): Contract {
    const address = PANCAKE_SWAP_ROUTER_ADDRESS[chainId]
    return getContract<Contract>(swapRouterABI, address, web3)
}

export class PancakePathQueryPlugin extends BasePathQueryPlugin {
    
    private chainId: ChainId
    private feeRate: number

    private swapRouter: Contract
    private swapRouterAddress: string
    private swapProxy: Contract

    public constructor(preQueryResult: PancakePreQueryResult, config: any, chainId: ChainId, web3: Web3) {
        super(preQueryResult)
        this.chainId = chainId
        this.feeRate = PANCAKE_FEE_RATE[chainId]
        this.swapRouterAddress = PANCAKE_SWAP_ROUTER_ADDRESS[chainId]
        this.swapRouter = getSwapRouterContract(chainId, web3)
        this.swapProxy = getSwapProxyContract(chainId, web3)
    }

    private reverse(path: Path): Path {
        return {
            ...path,
            tokenChain: path.tokenChain.slice().reverse(),
            feeRate: path.feeRate?.slice().reverse() ?? undefined,
            feeContractNumber: path.feeContractNumber?.slice().reverse() ?? undefined,
        }
    }

    private getAmountOut(amountIn: string, reserveIn: string, reserveOut: string): string {
        if (reserveIn === '0' || reserveOut === '0') {
            return undefined as unknown as string
        }
        const amountInWithFee = new BigNumber(amountIn).times(1 - this.feeRate)
        const numerator = amountInWithFee.times(reserveOut)
        const denominator = new BigNumber(reserveIn).plus(amountInWithFee)
        const amountOut = numerator.div(denominator).toFixed(0, 1)
        if (new BigNumber(amountOut).gte(reserveOut)) {
            return undefined as unknown as string
        }
        return amountOut
    }

    private getAmountIn(amountOut: string, reserveIn: string, reserveOut: string): string {
        if (reserveIn === '0' || reserveOut === '0') {
            return undefined as unknown as string
        }
        if (new BigNumber(amountOut).gte(reserveOut)) {
            return undefined as unknown as string
        }
        const numerator = new BigNumber(reserveIn).times(amountOut)
        const denominator = new BigNumber(reserveOut).minus(amountOut).times(1 - this.feeRate)
        const amountIn = numerator.div(denominator).plus(1).toFixed(0, 2)
        return amountIn
    }

    private getSwapPairKey(tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted) : string {
        const tokenASymbol = tokenA.symbol.toUpperCase()
        const tokenBSymbol = tokenB.symbol.toUpperCase()
        if (tokenASymbol < tokenBSymbol) {
            return tokenASymbol + '-' + tokenBSymbol
        } else {
            return tokenBSymbol + '-' + tokenASymbol
        }
    }

    private getAmountsOut(tokenChain: TokenInfoFormatted[], amountIn: string): string[] {
        const preQueryResult = this.preQueryResult as PancakePreQueryResult
        const amountsOut = [] as string[]
        amountsOut.push(amountIn)
        for (let i = 0; i < tokenChain.length - 1; i ++) {
            const tokenIn = tokenChain[i]
            const tokenOut = tokenChain[i + 1]

            const tokenInIsToken0 = tokenIn.address.toLowerCase() < tokenOut.address.toLowerCase()
            const swapPairKey = this.getSwapPairKey(tokenIn, tokenOut)
            const pairState = preQueryResult.pairState.get(swapPairKey) as PancakePairState

            if (!pairState) {
                return undefined as unknown as string[]
            }

            const reserveIn = tokenInIsToken0 ? pairState.reserve0 : pairState.reserve1
            const reserveOut = tokenInIsToken0 ? pairState.reserve1 : pairState.reserve0

            const amountOut = this.getAmountOut(amountsOut[i], reserveIn, reserveOut)
            if (!amountOut) {
                return undefined as unknown as string[]
            }
            amountsOut.push(amountOut)
        }
        return amountsOut
    }

    private getAmountsIn(tokenChain: TokenInfoFormatted[], amountOut: string): string[] {
        const preQueryResult = this.preQueryResult as PancakePreQueryResult
        const amountsIn = tokenChain.map((e)=>'0')
        amountsIn[tokenChain.length - 1] = amountOut
        for (let i = tokenChain.length - 2; i >= 0; i --) {
            const tokenIn = tokenChain[i]
            const tokenOut = tokenChain[i + 1]

            const tokenInIsToken0 = tokenIn.address.toLowerCase() < tokenOut.address.toLowerCase()
            const swapPairKey = this.getSwapPairKey(tokenIn, tokenOut)
            const pairState = preQueryResult.pairState.get(swapPairKey) as PancakePairState

            if (!pairState) {
                return undefined as unknown as string[]
            }

            const reserveIn = tokenInIsToken0 ? pairState.reserve0 : pairState.reserve1
            const reserveOut = tokenInIsToken0 ? pairState.reserve1 : pairState.reserve0

            const amountIn = this.getAmountIn(amountsIn[i + 1], reserveIn, reserveOut)
            if (!amountIn) {
                return undefined as unknown as string[]
            }
            amountsIn[i] = amountIn
        }
        return amountsIn
    }

    private getOriginUndecimalPriceEndByStart(tokenChain: TokenInfoFormatted[]): BigNumber {
        let priceEndByStart = new BigNumber(1)
        const preQueryResult = this.preQueryResult as PancakePreQueryResult
        for (let i = 0; i < tokenChain.length - 1; i ++) {

            const tokenIn = tokenChain[i]
            const tokenOut = tokenChain[i + 1]

            const tokenInIsToken0 = tokenIn.address.toLowerCase() < tokenOut.address.toLowerCase()
            const swapPairKey = this.getSwapPairKey(tokenIn, tokenOut)
            const pairState = preQueryResult.pairState.get(swapPairKey) as PancakePairState

            const reserveIn = tokenInIsToken0 ? pairState.reserve0 : pairState.reserve1
            const reserveOut = tokenInIsToken0 ? pairState.reserve1 : pairState.reserve0

            const priceOutByIn = new BigNumber(reserveIn).div(reserveOut)
            priceEndByStart = priceEndByStart.times(priceOutByIn)
        }
        return priceEndByStart
    }

    private getUndecimalPriceEndByStartAfterSwap(tokenChain: TokenInfoFormatted[], amounts: string[]): BigNumber {
        let priceEndByStart = new BigNumber(1)
        const preQueryResult = this.preQueryResult as PancakePreQueryResult

        for (let i = 0; i < tokenChain.length - 1; i ++) {

            const tokenIn = tokenChain[i]
            const tokenOut = tokenChain[i + 1]

            const tokenInIsToken0 = tokenIn.address.toLowerCase() < tokenOut.address.toLowerCase()
            const swapPairKey = this.getSwapPairKey(tokenIn, tokenOut)
            const pairState = preQueryResult.pairState.get(swapPairKey) as PancakePairState

            const originReserveIn = tokenInIsToken0 ? pairState.reserve0 : pairState.reserve1
            const originReserveOut = tokenInIsToken0 ? pairState.reserve1 : pairState.reserve0

            const amountIn = amounts[i]
            const amountOut = amounts[i + 1]

            const reserveIn = new BigNumber(amountIn).times(1 - this.feeRate).plus(originReserveIn)
            const reserveOut = new BigNumber(originReserveOut).minus(amountOut)

            const priceOutByIn = reserveIn.div(reserveOut)
            priceEndByStart = priceEndByStart.times(priceOutByIn)
        }
        return priceEndByStart
    }

    private estimateFee(path: Path, inputAmount: number): number {
        let remainAmount = inputAmount
        for (const fee of path.feeRate) {
            remainAmount = remainAmount - remainAmount * fee
        }
        return inputAmount - remainAmount
    }

    override getPathQuery(tokenIn: TokenInfoFormatted, tokenOut: TokenInfoFormatted, direction: SwapDirection, amount: string): PathQuery[] {

        const preQueryResult = this.preQueryResult as PancakePreQueryResult
        
        const pathQuery = [] as PathQuery[]
        let amounts = undefined as unknown as string[]
        let optPath = undefined as unknown as Path
        let optAmount = undefined as unknown as BigNumber
        for (const path of preQueryResult.path) {
            const p = (path.tokenChain[0].symbol === tokenIn.symbol) ? path : this.reverse(path)
            if (direction === SwapDirection.ExactIn) {
                const amountsOut = this.getAmountsOut(p.tokenChain, amount)
                if (!amountsOut) {
                    continue
                }
                const newAmount = new BigNumber(amountsOut[amountsOut.length - 1])
                if (!optPath || optAmount.lt(newAmount)) {
                    amounts = [...amountsOut]
                    optPath = {...p}
                    optAmount = newAmount
                }
            } else {
                const amountsIn = this.getAmountsIn(p.tokenChain, amount)
                if (!amountsIn) {
                    continue
                }
                const newAmount = new BigNumber(amountsIn[0])
                if (!optPath || optAmount.gt(newAmount)) {
                    amounts = [...amountsIn]
                    optPath = {...p}
                    optAmount = newAmount
                }
            }
        }

        if (!optPath) {
            return pathQuery
        }

        optPath.feeRate = []
        for (let i = 0; i < optPath.tokenChain.length - 1; i ++) {
            optPath.feeRate.push(this.feeRate)
        }

        const originUndecimalPriceEndByStart = this.getOriginUndecimalPriceEndByStart(optPath.tokenChain)
        const undecimalPriceEndByStartAfterSwap = this.getUndecimalPriceEndByStartAfterSwap(optPath.tokenChain, amounts)

        const priceImpact = Math.abs(Number(undecimalPriceEndByStartAfterSwap.minus(originUndecimalPriceEndByStart).div(originUndecimalPriceEndByStart)))
        const noSufficientLiquidity = false
        const inputAmount = amounts[0]
        const inputAmountDecimal = Number(amount2Decimal(new BigNumber(inputAmount), tokenIn))
        const feesDecimal = this.estimateFee(optPath, inputAmountDecimal)

        const pathQueryResult = {
            amount: (direction === SwapDirection.ExactIn)? amounts[amounts.length - 1] : inputAmount,
            path: optPath,
            noSufficientLiquidity,
            initDecimalPriceEndByStart: priceUndecimal2PriceDecimal(tokenOut, tokenIn, originUndecimalPriceEndByStart),
            priceImpact,
            feesDecimal
        }

        pathQuery.push({
            path: optPath,
            pathQueryResult
        } as PathQuery)
        
        return pathQuery
    }

    private isChainToken(token: TokenInfoFormatted): boolean {
        const chainToken = getChain(this.chainId)?.token
        const chainTokenAddress = chainToken?.address?.toLowerCase() as string
        const tokenAddress = token.address.toLowerCase()
        return chainTokenAddress === tokenAddress
    }

    override getTokenSpenderInfo(path: Path, direction: SwapDirection): TokenSpenderInfo {
        const tokenToPay = path.tokenChain[0]
        if (this.isChainToken(tokenToPay)) {
            return {tokenToPay}
        }
        return {
            tokenToPay,
            spenderAddress: SWAP_PROXY_ADDRESS[this.chainId],
        }
    }

    private hasTokenFee(tokenChain: TokenInfoFormatted[]):boolean {
        for (const token of tokenChain) {
            const noWrapToken = !token.wrapTokenAddress
            if (!noWrapToken) {
                return true
            }
        }
        return false
    }

    override getSwapTransaction(
        path: Path, 
        direction: SwapDirection, 
        amountIn: string, 
        amountOut: string, 
        account: string, 
        maxDelay: number,
        slippagePercent: number
    ): {calling: any, options: any} {
        const deadline = String(Math.floor((new Date()).valueOf() / 1000) + maxDelay * 60)
        const hasFee = this.hasTokenFee(path.tokenChain)
        const inputIsChainToken = this.isChainToken(path.tokenChain[0])
        const outputIsChainToken = this.isChainToken(path.tokenChain[path.tokenChain.length - 1])
        const cost = (direction === SwapDirection.ExactIn)? amountIn : (new BigNumber(amountIn)).times(100 + slippagePercent).div(100).toFixed(0)
        let acquire = (direction === SwapDirection.ExactOut)? amountOut: (new BigNumber(amountOut)).times(100 - slippagePercent).div(100).toFixed(0)
        const addressList = path.tokenChain.map((e)=>e.address)
        if (!hasFee) {
            if (direction === SwapDirection.ExactIn) {
                if (inputIsChainToken) {
                    const msgValue = cost
                    const swapExactETHForTokens = this.swapRouter.methods.swapExactETHForTokens(acquire, addressList, account, deadline)
                    const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapExactETHForTokens.encodeABI(), msgValue).encodeABI()
                    const refund = this.swapProxy.methods.refundETH().encodeABI()
                    return {
                        calling: this.swapProxy.methods.multicall([proxy, refund]),
                        options: {value: msgValue}
                    }
                } else if (outputIsChainToken) {
                    const deposit = this.swapProxy.methods.depositToken(addressList[0], cost).encodeABI()
                    const approve = this.swapProxy.methods.approveToken(addressList[0], this.swapRouterAddress).encodeABI()
                    const swapExactTokensForETH = this.swapRouter.methods.swapExactTokensForETH(cost, acquire, addressList, account, deadline)
                    const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapExactTokensForETH.encodeABI(), '0').encodeABI()
                    return {
                        calling: this.swapProxy.methods.multicall([deposit, approve, proxy]),
                        options: {}
                    }
                } else {
                    const deposit = this.swapProxy.methods.depositToken(addressList[0], cost).encodeABI()
                    const approve = this.swapProxy.methods.approveToken(addressList[0], this.swapRouterAddress).encodeABI()
                    const swapExactTokensForTokens = this.swapRouter.methods.swapExactTokensForTokens(cost, acquire, addressList, account, deadline)
                    const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapExactTokensForTokens.encodeABI(), '0').encodeABI()
                    return {
                        calling: this.swapProxy.methods.multicall([deposit, approve, proxy]),
                        options: {}
                    }
                }
            } else {
                if (inputIsChainToken) {
                    const msgValue = cost
                    const swapETHForExactTokens = this.swapRouter.methods.swapETHForExactTokens(acquire, addressList, account, deadline)
                    const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapETHForExactTokens.encodeABI(), msgValue).encodeABI()
                    const refund = this.swapProxy.methods.refundETH().encodeABI()
                    return {
                        calling: this.swapProxy.methods.multicall([proxy, refund]),
                        options: {value: msgValue}
                    }
                } else if (outputIsChainToken) {
                    const deposit = this.swapProxy.methods.depositToken(addressList[0], cost).encodeABI()
                    const approve = this.swapProxy.methods.approveToken(addressList[0], this.swapRouterAddress).encodeABI()
                    const swapTokensForExactETH = this.swapRouter.methods.swapTokensForExactETH(acquire, cost, addressList, account, deadline)
                    const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapTokensForExactETH.encodeABI(), '0').encodeABI()
                    const sweep = this.swapProxy.methods.sweepToken(addressList[0], account).encodeABI()
                    return {
                        calling: this.swapProxy.methods.multicall([deposit, approve, proxy, sweep]),
                        options: {}
                    }
                } else {
                    const deposit = this.swapProxy.methods.depositToken(addressList[0], cost).encodeABI()
                    const approve = this.swapProxy.methods.approveToken(addressList[0], this.swapRouterAddress).encodeABI()
                    const swapTokensForExactTokens = this.swapRouter.methods.swapTokensForExactTokens(acquire, cost, addressList, account, deadline)
                    const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapTokensForExactTokens.encodeABI(), '0').encodeABI()
                    const sweep = this.swapProxy.methods.sweepToken(addressList[0], account).encodeABI()
                    return {
                        calling: this.swapProxy.methods.multicall([deposit, approve, proxy, sweep]),
                        options: {}
                    }
                }
            }
        } else {
            acquire = new BigNumber(acquire).times(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FEE_TOKEN_FACTOR).toFixed(0)
            // direction must be SwapDirection.ExactIn
            if (inputIsChainToken) {
                const msgValue = cost
                const swapExactETHForTokens = this.swapRouter.methods.swapExactETHForTokens(acquire, addressList, account, deadline)
                const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapExactETHForTokens.encodeABI(), msgValue).encodeABI()
                const refund = this.swapProxy.methods.refundETH().encodeABI()
                return {
                    calling: this.swapProxy.methods.multicall([proxy, refund]),
                    options: {value: msgValue}
                }
            } else if (outputIsChainToken) {
                const deposit = this.swapProxy.methods.depositToken(addressList[0], cost).encodeABI()
                const approve = this.swapProxy.methods.approveToken(addressList[0], this.swapRouterAddress).encodeABI()
                const swapExactTokensForETH = this.swapRouter.methods.swapExactTokensForETH(cost, acquire, addressList, account, deadline)
                const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapExactTokensForETH.encodeABI(), '0').encodeABI()
                return {
                    calling: this.swapProxy.methods.multicall([deposit, approve, proxy]),
                    options: {}
                }
            } else {
                const deposit = this.swapProxy.methods.depositToken(addressList[0], cost).encodeABI()
                const approve = this.swapProxy.methods.approveToken(addressList[0], this.swapRouterAddress).encodeABI()
                const swapExactTokensForTokens = this.swapRouter.methods.swapExactTokensForTokens(cost, acquire, addressList, account, deadline)
                const proxy = this.swapProxy.methods.proxy(this.swapRouterAddress, swapExactTokensForTokens.encodeABI(), '0').encodeABI()
                return {
                    calling: this.swapProxy.methods.multicall([deposit, approve, proxy]),
                    options: {}
                }
            }
        }
    }

}
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { BoxProps, Text, VStack, Image, HStack, Box, Flex, useColorMode, Center, useInterval } from '@chakra-ui/react';

import { isNumeric } from '../../../utils/valid';
import { TokenSymbol } from '../../../types/mod';
import { isGasToken } from '../../../config/tokens';
import useIsMobile from '../../../hooks/useIsMobile';
import { formatNumber } from '../../../utils/tokenMath';
import { Erc20Contract } from '../../../types/abis/erc20';
import { i_h5 } from '../../../iZUMi-UI-toolkit/src/style';
import { i_text_copy, i_h4, i_text_d, i_text_copy_bold } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { RootDispatch, RootState } from '../../../state/store';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { Modal } from '../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { getErc20TokenContractByAddr } from '../../../utils/contractFactory';
import { CUSTOM_TOKEN_BLACKLIST, HOT_TOKENS } from '../../../config/bizConfig';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../hooks/useTokenListFormatted';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { SearchInput } from '../../../iZUMi-UI-toolkit/src/components/Inputs/SearchInput/SearchInput';
import { Loading } from '../../../iZUMi-UI-toolkit/src/components/Loading/Loading';

type TokenButtonProps = {
    token: TokenInfoFormatted;
    handleClick: any;
} & BoxProps;

type TokenSelectModalProps = {
    isOpen: boolean | any;
    onClose: any;
    handleSelect: any;
} & BoxProps;
interface TokenInfoFormattedWithBalance extends TokenInfoFormatted {
    balance: number;
}

const TokenButton: React.FC<TokenButtonProps> = (props) => {
    const { token, handleClick, ...rest } = props;
    return (
        <CustomButton
            variant="lightGray"
            onClick={handleClick}
            text={
                <Center w="100%">
                    <HStack spacing="10px">
                        <Image borderRadius="2px" w="22px" h="22px" src={process.env.PUBLIC_URL + token.icon} />
                        <Text className={i_h5} fontSize={token.symbol.length > 4 ? '13px' : 'unset'}>
                            {token.symbol}
                        </Text>
                    </HStack>
                </Center>
            }
            {...rest}
        />
    );
};

export const TokenSelectModal: React.FC<TokenSelectModalProps> = (props) => {
    const { isOpen, onClose, handleSelect, ...rest } = props;
    const { chainId, web3, account } = useWeb3WithDefault();
    const { t } = useTranslation();
    const hotTokens = HOT_TOKENS[chainId] ?? [TokenSymbol.IZI, TokenSymbol.IUSD];
    const { latestTokens, account: accountModel } = useSelector((state: RootState) => state);
    const { loading, tokenList } = useTokenListFormatted();
    const [searchKey, setSearchKey] = useState('');
    const [manageToken, setManageToken] = useState(false);
    const [tokenListWithBalanceSorted, setTokenListWithBalanceSorted] = useState([] as TokenInfoFormattedWithBalance[]);
    const [showNewToken, setShowNewToken] = useState(false);
    const [newTokenInfo, setNewTokenInfo] = useState(undefined as unknown as TokenInfoFormatted);
    const dispatch = useRematchDispatch((dispatch: RootDispatch) => dispatch);
    const isMobile = useIsMobile();

    const tokenListWithBalance = useMemo(() => {
        if (!account || !web3 || !chainId) {
            return tokenList as TokenInfoFormattedWithBalance[];
        }
        const t = _.cloneDeep(tokenList) as TokenInfoFormattedWithBalance[];
        t.forEach((item) => (item.balance = 0));
        //TODO: multicall to batch update
        t.forEach((item, index) => {
            if (!isGasToken(item as TokenInfoFormatted, chainId) && item.address) {
                const contract = getErc20TokenContractByAddr(item.address, chainId as unknown as any, web3);
                contract.methods
                    .decimals()
                    .call()
                    .then((decimal) => {
                        contract.methods
                            .balanceOf(account)
                            .call()
                            .then((res) => {
                                try {
                                    item.balance = Number(res) / 10 ** Number(decimal);
                                } catch (e) {
                                    item.balance = 0;
                                }
                            });
                    });
            } else {
                t[index].balance = accountModel.ethBalance ?? 0;
            }
        });
        return t;
    }, [tokenList, chainId, web3, account]);

    useInterval(() => {
        if (showNewToken) return;
        let t = _.cloneDeep(tokenListWithBalance);
        t = t.sort((a, b) => {
            if (!isNumeric(a.balance)) return 0;
            if (!isNumeric(b.balance)) return 1;
            return b.balance - a.balance;
        });
        setTokenListWithBalanceSorted(t);
    }, 3 * 1000);

    const tokenListFiltered = useMemo(
        () =>
            tokenListWithBalanceSorted.filter(
                (e) =>
                    e.name.toLowerCase().includes(searchKey.toLowerCase()) ||
                    e.symbol.toLowerCase().includes(searchKey.toLowerCase()) ||
                    e.address === searchKey
            ),
        [searchKey, tokenListWithBalanceSorted]
    );

    const RecentTokensFiltered = useMemo(() => {
        if (!latestTokens.latestTokens) {
            return [];
        }
        const findItemIndex = latestTokens.latestTokens.findIndex((i) => i.chainId === chainId);
        if (findItemIndex === -1) {
            return [];
        }
        return latestTokens.latestTokens[findItemIndex].tokens.filter(
            (e) =>
                e.name.toLowerCase().includes(searchKey.toLowerCase()) ||
                e.symbol.toLowerCase().includes(searchKey.toLowerCase()) ||
                e.address === searchKey
        );
    }, [latestTokens.latestTokens, chainId, searchKey]);

    const HotListFiltered = useMemo(() => {
        return tokenList.filter(
            (e) =>
                (e.name.toLowerCase().includes(searchKey.toLowerCase()) ||
                    e.symbol.toLowerCase().includes(searchKey.toLowerCase()) ||
                    e.address === searchKey) &&
                hotTokens.includes(e.symbol)
        );
    }, [searchKey, tokenList]);

    useEffect(() => {
        const fetchERC20Info = async (contract: Erc20Contract) => {
            const name = await contract.methods.name().call();
            const decimal = await contract.methods.decimals().call();
            const symbol = await contract.methods.symbol().call();
            setNewTokenInfo({
                name,
                symbol,
                decimal: Number(decimal),
                chainId,
                address: searchKey,
                icon: '/assets/tokens/default.svg',
                custom: true,
            });
            setShowNewToken(true);
        };

        setShowNewToken(false);
        if (tokenListFiltered.length === 0) {
            if (
                web3.utils.isAddress(searchKey) &&
                !CUSTOM_TOKEN_BLACKLIST[chainId].find((e: string) => e.toLowerCase() === searchKey.toLowerCase())
            ) {
                const contract = getErc20TokenContractByAddr(searchKey, chainId as unknown as any, web3);
                if (contract) {
                    console.log(contract);
                    fetchERC20Info(contract);
                }
            }
        }
    }, [searchKey, tokenListFiltered, chainId, web3]);

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const TokenInfoBlock = (token: TokenInfoFormatted) => {
        return (
            <>
                <Image borderRadius="4px" w="30px" h="30px" src={process.env.PUBLIC_URL + token.icon} />
                <VStack spacing="0" alignItems="start">
                    <Text className={i_h4}>{token.symbol}</Text>
                    <Text
                        className={i_text_copy}
                        color={colorTheme('tertiary.800', 'tertiary.400')}
                        w={isMobile ? '130px' : '200px'}
                        isTruncated={true}
                    >
                        {!isMobile && token.custom && t('added by user') + ' . '} {token.name}
                    </Text>
                </VStack>
            </>
        );
    };

    return loading ? (
        <VStack>
            <Text>{t('Loading')}...</Text>
        </VStack>
    ) : (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w={{ base: '90%', md: '534px' }}
            h="782px"
            title={t('Select a Token')}
            {...rest}
            ml="0px !important"
        >
            <VStack alignItems="start" spacing="8px" mt={isMobile ? 'unset' : '-17px'}>
                <SearchInput
                    placeholder={t('Enter name or paste address')}
                    onSearchKeyChange={(e: any) => {
                        console.log('search key: ', e.target.value);
                        setSearchKey(e.target.value);
                    }}
                    value={searchKey}
                    w={{ base: '100%', lg: '434px' }}
                    h="42px"
                />
                {RecentTokensFiltered.length > 0 && (
                    <>
                        <Text className={i_text_copy} pt="10px" pl="4px">
                            Recent Choices
                        </Text>

                        <Flex direction="row" w="100%" flexWrap="wrap" alignItems="start" spacing="0" mt="0px !important">
                            {RecentTokensFiltered.map((token: TokenInfoFormatted, index: any) => (
                                <Flex flexBasis="20$" flexShrink={0} mt="10px" mr={(index + 1) % 4 === 0 ? '0px' : '10px'} key={index}>
                                    <TokenButton
                                        w={{ base: '135px', sm: '101px' }}
                                        h="32px"
                                        handleClick={() => {
                                            dispatch.latestTokens.addToken(chainId, token);
                                            handleSelect(token);
                                            onClose();
                                        }}
                                        token={token}
                                    />
                                </Flex>
                            ))}
                        </Flex>
                    </>
                )}
                <Text className={i_text_copy} pl="4px" pt={RecentTokensFiltered.length === 0 ? '15px' : '3px'}>
                    Hot Tokens
                </Text>

                <Flex direction="row" w="100%" flexWrap="wrap" alignItems="start" spacing="0" mt="0px !important">
                    {HotListFiltered.map((token: TokenInfoFormatted, index: any) => (
                        <Flex flexBasis="20$" flexShrink={0} mt="10px" mr={(index + 1) % 4 === 0 ? '0px' : '10px'} key={index}>
                            <TokenButton
                                w={{ base: '135px', sm: '101px' }}
                                h="32px"
                                handleClick={() => {
                                    dispatch.latestTokens.addToken(chainId, token);
                                    handleSelect(token);
                                    onClose();
                                }}
                                token={token}
                            />
                        </Flex>
                    ))}
                </Flex>

                <Box w="100%" h="1px" mt="22px !important" bg={colorTheme('#E2D4FF', '#574A73')} overflow="hidden"></Box>

                <Box h="10px" />

                {showNewToken && (
                    <Card w="100%" h="100px" variant="grey" p="20px">
                        <HStack justifyContent="space-between" w="100%" mb="20px" alignItems="center" spacing="20px" cursor="pointer">
                            <HStack spacing="20px">
                                <Image borderRadius="4px" w="30px" h="30px" src={process.env.PUBLIC_URL + newTokenInfo.icon} />
                                <VStack spacing="0" alignItems="start">
                                    <Text className={i_h4}>{newTokenInfo.symbol}</Text>
                                    <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.400')}>
                                        {newTokenInfo.name}
                                    </Text>
                                </VStack>
                            </HStack>
                            <CustomButton
                                variant="purple"
                                fontClass={i_text_d}
                                fontSize="12px"
                                text="import"
                                w="56px"
                                h="30px"
                                onClick={() => {
                                    dispatch.latestTokens.addToken(chainId, newTokenInfo);
                                    dispatch.customTokens.modToken({
                                        tokenInfo: {
                                            ...newTokenInfo,
                                            addTime: new Date(),
                                        },
                                        isAdd: true,
                                    });
                                    setShowNewToken(false);
                                }}
                            />
                        </HStack>
                    </Card>
                )}

                {!manageToken && !showNewToken && (
                    <Flex
                        direction="column"
                        alignItems="start"
                        spacing="10px"
                        w="100%"
                        h={RecentTokensFiltered.length ? '320px' : '400px'}
                        overflow="auto"
                    >
                        {tokenListWithBalanceSorted.length ? (
                            tokenListFiltered.map((item: any, index: any) => (
                                <HStack
                                    justifyContent="space-between"
                                    w="100%"
                                    px="25px"
                                    key={index}
                                    mb="20px"
                                    alignItems="center"
                                    spacing="20px"
                                    cursor="pointer"
                                >
                                    <HStack
                                        spacing="20px"
                                        onClick={() => {
                                            dispatch.latestTokens.addToken(chainId, item);
                                            handleSelect(item);
                                            onClose();
                                        }}
                                    >
                                        {TokenInfoBlock(item)}
                                        <Text
                                            className={i_text_copy_bold}
                                            color={colorTheme('tertiary.800', 'tertiary.200')}
                                            w={isMobile ? '110px' : '120px'}
                                            fontSize="14px"
                                            mt="0px !important"
                                            isTruncated={true}
                                        >
                                            {isNumeric(item.balance) && item.balance > 0 && formatNumber(item.balance, 2, 2, true)}
                                        </Text>
                                    </HStack>
                                </HStack>
                            ))
                        ) : (
                            <Center w="100%">
                                <Loading />
                            </Center>
                        )}
                    </Flex>
                )}

                {manageToken && !showNewToken && (
                    <Flex
                        direction="column"
                        alignItems="start"
                        spacing="10px"
                        w="100%"
                        h={RecentTokensFiltered.length ? '320px' : '400px'}
                        overflow="auto"
                    >
                        {tokenListFiltered.map(
                            (item: any, index: any) =>
                                item.custom && (
                                    <HStack
                                        justifyContent="space-between"
                                        w="100%"
                                        px="25px"
                                        key={index}
                                        mb="20px"
                                        alignItems="center"
                                        spacing="20px"
                                        cursor="pointer"
                                    >
                                        <HStack spacing="20px">{TokenInfoBlock(item)}</HStack>

                                        <CustomButton
                                            variant="purple"
                                            text={t('remove')}
                                            fontClass={i_text_d}
                                            fontSize="12px"
                                            w="60px"
                                            h="30px"
                                            onClick={() => {
                                                dispatch.latestTokens.addToken(chainId, item);
                                                dispatch.customTokens.modToken({
                                                    tokenInfo: item,
                                                    isAdd: false,
                                                });
                                            }}
                                        />
                                    </HStack>
                                )
                        )}
                    </Flex>
                )}

                {!manageToken && (
                    <CustomButton
                        variant="lightPurple"
                        text={t('Manage Custom Token')}
                        fontClass={i_text_d}
                        fontSize="13px"
                        mt="20px !important"
                        mx="auto !important"
                        w="200px"
                        h="30px"
                        onClick={() => {
                            setManageToken(true);
                        }}
                    />
                )}

                {manageToken && (
                    <CustomButton
                        variant="lightPurple"
                        text={t('Token List')}
                        fontClass={i_text_d}
                        fontSize="13px"
                        mt="20px !important"
                        mx="auto !important"
                        w="200px"
                        h="30px"
                        onClick={() => {
                            setManageToken(false);
                        }}
                    />
                )}
            </VStack>
        </Modal>
    );
};

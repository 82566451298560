import React, { useState } from 'react';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { i_text_d, i_h4 } from '../../../style';
import { BoxProps, useColorMode, HStack, Box, Image, VStack, Text } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../../utils/funcs';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import { formatNumber } from '../../../utils/tokenMath';
import { useTranslation } from 'react-i18next';
import { links } from '../../../config/links';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';

type CardProps = {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    price: number | string | undefined;
    handleToggle: () => void;
    hideToggle?: boolean;
    onlyTogglePrice?: boolean;
} & BoxProps;

const CurrentPriceCard: React.FC<CardProps> = (props) => {
    const { children, tokenA, tokenB, price, handleToggle, hideToggle, onlyTogglePrice, ...rest } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [togglePrice, setTogglePrice] = useState(false);
    const { chainId } = useWeb3WithDefault();

    return (
        <Card w="100%" maxH="250px" p="10px" variant="deep" pl="66px" pr="30px" {...rest}>
            <HStack w="100%" justifyContent="space-between" alignItems="center">
                <HStack alignItems="center" spacing="30px">
                    <Box h="60px" w="60px" position="relative">
                        <Image h="44px" w="44px" borderRadius="5px" position="absolute" top="0" left="0" src={tokenA.icon} />
                        <Image h="44px" w="44px" borderRadius="5px" position="absolute" right="0" bottom="0" src={tokenB.icon} />
                    </Box>

                    <VStack spacing="-6px" alignItems="start">
                        <Text className={i_text_d} color={colorTheme('primary.600', 'primary.400')} fontSize="12px !important">
                            {t('Current Price')}
                        </Text>
                        <Text className={i_h4} fontSize="14px !important" color={colorTheme('tertiary.900', 'tertiary.200')}>
                            {tokenA.symbol}/{tokenB.symbol}
                        </Text>
                    </VStack>
                </HStack>

                <HStack spacing="10px">
                    <HStack alignItems="center" spacing="30px" hidden={price === undefined}>
                        <VStack spacing="-6px" alignItems="start">
                            <Text className={i_text_d} color={colorTheme('primary.600', 'primary.400')} fontSize="12px !important">
                                1 {onlyTogglePrice ? (togglePrice ? tokenB.symbol : tokenA.symbol) : tokenA.symbol}
                            </Text>
                            <Text className={i_h4} fontSize="14px !important" ml="-10px !important">
                                ={' '}
                                {formatNumber(
                                    (onlyTogglePrice ? (togglePrice ? 1 / (Number(price) ?? 1) : Number(price)) : Number(price)) ?? 0,
                                    2,
                                    2,
                                    true
                                )}{' '}
                                {onlyTogglePrice ? (togglePrice ? tokenA.symbol : tokenB.symbol) : tokenB.symbol}
                            </Text>
                        </VStack>
                        <HStack spacing="10px">
                            {!hideToggle && (
                                <Image
                                    w="24px"
                                    h="20px"
                                    src={colorTheme('/assets/trade/limitOrderChange.svg', '/assets/trade/darklimitOrderChange.svg')}
                                    fallbackSrc="/assets/trade/limitOrderChange.svg"
                                    cursor="pointer"
                                    onClick={() => {
                                        onlyTogglePrice ? setTogglePrice(!togglePrice) : handleToggle();
                                    }}
                                ></Image>
                            )}
                        </HStack>
                    </HStack>
                    <Image
                        w="24px"
                        h="20px"
                        src={colorTheme('/assets/trade/limitOrderDashboard.svg', '/assets/trade/darklimitOrderDashboard.svg')}
                        fallbackSrc="/assets/trade/limitOrderDashboard.svg"
                        cursor="pointer"
                        onClick={() => {
                            window.open(links.analyticLink + '/token?chainId=' + chainId + '&tokenAddress=' + tokenB.address);
                        }}
                    ></Image>
                </HStack>
            </HStack>
            {children}
        </Card>
    );
};

export default CurrentPriceCard;

import React from 'react';
import { TokenBalanceBlock } from './TokenBalanceBlock';
import { Box, BoxProps, Divider, HStack } from '@chakra-ui/react';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { i_h2 } from '../../../style';
import { AmountInput, AmountInputProps } from './AmountInput';
import { AdaptationMode } from '../../../components/layout/PageLayout';

export type TokenAmountInputProps = {
    titleProps?: BoxProps;
    setIsInputFocus?: any;
    type?: AdaptationMode;
} & AmountInputProps;

const TokenAmountInput: React.FC<TokenAmountInputProps> = (props) => {
    const { handleSetValue, token, children, price, balance, errorInfo, inputValue, titleProps, setIsInputFocus, type, ...rest } = props;

    return type === 'mobile' ? (
        <Card variant="lightGary" w="100%" p="10px" {...rest}>
            <Box w="100%">
                <AmountInput
                    type="mobile"
                    handleSetValue={handleSetValue}
                    token={token}
                    price={price}
                    balance={balance}
                    errorInfo={errorInfo}
                    inputValue={inputValue}
                    w="100%"
                    fontClass={i_h2}
                    setIsInputFocus={setIsInputFocus}
                />
            </Box>
        </Card>
    ) : (
        <Card variant="lightGary" h="172px" w="100%" p="10px" {...rest}>
            <TokenBalanceBlock token={token} balance={balance} p="10px" {...titleProps} />
            <Divider m="0 !important" />
            <HStack w="100%" px="20px" mt="2px" justifyContent="space-between">
                {children}
            </HStack>

            <Box w="100%" px="10px">
                <AmountInput
                    handleSetValue={handleSetValue}
                    token={token}
                    price={price}
                    balance={balance}
                    errorInfo={errorInfo}
                    inputValue={inputValue}
                    w="100%"
                    fontClass={i_h2}
                    setIsInputFocus={setIsInputFocus}
                />
            </Box>
        </Card>
    );
};

export default TokenAmountInput;

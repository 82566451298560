import { useState } from 'react';
import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useInterval } from '@chakra-ui/react';

import { PRO_CONFIG } from '../../../../config/bizConfig';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { RootDispatch } from '../../../../state/store';
import { noto_t3_bold } from '../../../../style';

import Assets from './components/Assets';
import Controls from './components/Controls';
import Limit from './components/Limit';
import Market from './components/Market';
import NoConnected from './components/NoConnected';
import useIsMobile from '../../../../hooks/useIsMobile';

type OrderFormProps = {
    themeColor: any;
    actionType: string;
    setActionType: any;
};

const OrderForm: React.FC<OrderFormProps> = (props) => {
    const { themeColor, actionType, setActionType } = props;
    const { account } = useWeb3WithDefault();
    const [currentType, setCurrentType] = useState('Limit');
    const { chainId, web3 } = useWeb3WithDefault();
    const isMobile = useIsMobile();

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    useInterval(() => {
        dispatch.proOrderFormState.refreshPoolState({ chainId, web3 });
    }, PRO_CONFIG.POOL_STATE_REFRESH_INTERVAL);

    return (
        <Stack w={{ base: '100%', sm: '270px' }}>
            {account ? (
                <Tabs variant="unstyled" bg={themeColor} borderRadius="6px" px="10px" pt="15px" pb="20px">
                    <TabList justifyContent="5px">
                        <Tab
                            w="33px"
                            color="#A3A0A9"
                            px="2px"
                            _selected={{
                                color: actionType === 'buy' ? '#12931F' : '#D34D4D',
                                borderWidth: '0px',
                                borderColor: '#ffffff',
                            }}
                            _hover={{ color: currentType === 'Limit' ? '' : actionType === 'buy' ? '#87AF73' : '#92686B' }}
                            onClick={() => {
                                dispatch.proOrderFormState.refreshForm(actionType === 'buy');
                                setCurrentType('Limit');
                            }}
                        >
                            <Text className={noto_t3_bold}>Limit</Text>
                        </Tab>
                        <Tab
                            w="46px"
                            px="2px"
                            color="#A3A0A9"
                            ml="12px"
                            _selected={{
                                color: actionType === 'buy' ? '#12931F' : '#D34D4D',
                                border: '',
                            }}
                            _hover={{ color: currentType === 'Market' ? '' : actionType === 'buy' ? '#87AF73' : '#92686B' }}
                            onClick={() => {
                                setCurrentType('Market');
                            }}
                        >
                            <Text className={noto_t3_bold}>Market</Text>
                        </Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel p="0px" mt="10px">
                            <Limit actionType={actionType} setActionType={setActionType}></Limit>
                        </TabPanel>
                        <TabPanel p="0px" mt="10px">
                            <Market actionType={actionType} setActionType={setActionType}></Market>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            ) : (
                <NoConnected />
            )}
            <Controls
                themeColor={themeColor}
                setFlashMode={(v: boolean) => {
                    if (currentType === 'Limit') {
                        dispatch.proOrderFormState.refreshForm(actionType === 'buy');
                    }
                }}
                mt={{ base: '0px !important', sm: '8px !important' }}
                mb={{ base: '4px !important', sm: '0px !important' }}
            />
            {!isMobile && <Assets themeColor={themeColor} currentType={currentType}></Assets>}
        </Stack>
    );
};

export default OrderForm;

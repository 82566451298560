import React from 'react';
import { HStack, Text, useColorMode, VStack, Image, BoxProps } from '@chakra-ui/react';
import { i_text_copy, i_h4, i_text_d } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { formatNumber } from '../../../utils/tokenMath';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import { useTranslation } from 'react-i18next';

export type TokenBalanceBlockProps = {
    token?: TokenInfoFormatted | null;
    balance: number | string | null;
} & BoxProps;

export const TokenBalanceBlock: React.FC<TokenBalanceBlockProps> = (props) => {
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { token, balance, ...rest } = props;
    const { tradeMode } = useSelector((state: RootState) => state);
    const isSimpleMode = tradeMode.isSimpleMode;

    return (
        <HStack w="100%" justifyContent="space-between" display={isSimpleMode ? 'flex' : 'none'} {...rest}>
            <HStack>
                <Image w="24px" h="24px" borderRadius="5px" src={token?.icon} />
                <Text className={i_h4} fontSize="18px" color={colorTheme('tertiary.900', 'tertiary.50')}>
                    {token?.symbol}
                </Text>
            </HStack>

            <VStack spacing="0">
                <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.500', 'tertiary.300')}>
                    {t('Balance')}
                </Text>
                <Text className={i_text_d} fontSize="16px" color={colorTheme('tertiary.700', 'tertiary.300')}>
                    {formatNumber(Number(balance))}
                </Text>
            </VStack>
        </HStack>
    );
};

import chains from '../config/chains';
import { particle } from './particle';
import { ChainId, ChainName, supportChains } from '@particle-network/common';

export const switchNetwork = async (chainId: number) => {
    if (particle.auth.isLogin()) {
        try {
            const chain = Object.keys(supportChains).find((chain) => {
                if (Object.keys((supportChains as any)[chain].chainIds).find((e) => e == String(chainId))) {
                    return chain;
                }
            });
            await particle.auth.switchChain(
                {
                    name: chain as ChainName,
                    id: Number(chainId) as ChainId,
                },
                !!particle.auth.userInfo()?.jwt_id
            );
            return;
        } catch (error) {
            console.log(error);
        }
    }
    const ethereum = (window as WindowChain).ethereum;
    const chain = chains.all.find((e) => {
        return e.id === chainId;
    });

    if (ethereum && ethereum.request) {
        // handle net switch fall to chain 1 by the event networkChanged, https://github.com/Uniswap/web3-react/issues/257
        (window as any).ethereum.removeAllListeners(['networkChanged']);
        try {
            await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x' + chainId.toString(16) }],
            });
            return 0;
        } catch (switchError) {
            if (switchError) {
                return new Error('Failed to switch networks from the interface, you must change the network in your wallet');
            }
            // This error code indicates that the chain has not been added to MetaMask.
            if ((switchError as any).code === 4902) {
                try {
                    await ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: '0x' + chainId.toString(16),
                                rpcUrls: [chain?.rpcUrl],
                                chainName: chain?.name,
                                nativeCurrency: {
                                    name: chain?.tokenSymbol,
                                    symbol: chain?.tokenSymbol,
                                    decimals: 18,
                                },
                                blockExplorerUrls: [chain?.scanUrl],
                            },
                        ],
                    });
                } catch (addError) {
                    // handle "add" error
                }
            }
            // handle other "switch" errors
        }
    }
};

export const addToken = async (tokenAddress: string, tokenSymbol: string, tokenDecimals: number, tokenImage: string) => {
    const ethereum = (window as WindowChain).ethereum;

    if (ethereum && ethereum.request) {
        try {
            await ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: tokenAddress, // The address that the token is at.
                        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: tokenDecimals, // The number of decimals in the token
                        image: tokenImage, // A string url of the token logo
                    },
                },
            });
            return true;
        } catch (addError) {
            if (addError) {
                return new Error('Failed . ' + addError);
            }
        }
    }
};
